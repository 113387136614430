//
// mtsoft ui alerts
//
//@import "compass/css3";

// alerts related data (icons, ...)
//@import "alerts-icons"; 



// -----------------------------------------------------------------------------
//
// variables
//

// common
$alert-css-prefix:          'mta-' !default;    // use to ommit conflicts with other modules
$alert-padding-h:           rem-calc(15px) !default;
$alert-padding-v:           rem-calc(8px) !default;
$alert-border-width-px:     1px !default;
$alert-border-style:        solid !default;
$alert-margin:              rem-calc(2.5px) !default;
$alert-radius:              4px !default;
//$alert-text-shadow:         0px 1px 0 rgba(255, 255, 255, 0.5) !default;
$alert-text-shadow:         none !default;
$alert-box-shadow:          2px 2px 4px rgba(0, 0, 0, 0.15) !default;

// icons
//$alert-icons-set:               aicons !default;
//$alert-icon-font-size:          27px !default;
//$alert-icon-inside-font-size:   $alert-icon-font-size !default;

// close button
//$alert-close-btn-icon:      aico-remove !default;
//$alert-close-btn-icon:      ico-close !default;

// action buttons
$alert-btn-hover-transition: background .25s ease-out;

// flash mode bottom/top margin
$alert-flash-border:        15px !default;

// alert types

// success
$alert-success-color:           #468847 !default;
$alert-success-bg:              #DFF0D8 !default;
$alert-success-border-color:    #D6E9C6 !default;
//$alert-success-icon:            aico-ok-sign !default;
//$alert-success-icon:            ico-success !default;

// failed
$alert-failed-color:           #B94A48 !default;
$alert-failed-bg:              #F2DEDE !default;
$alert-failed-border-color:    #EED3D7 !default;
//$alert-failed-icon:            aico-exclamation-sign !default;  //aico-remove-sign
//$alert-failed-icon:            ico-failed !default;

// caution
$alert-caution-color:           #C09853 !default;
$alert-caution-bg:              #FCF8E3 !default;
$alert-caution-border-color:    #FBEED5 !default;
//$alert-caution-icon:            aico-warning !default;
//$alert-caution-icon:            ico-caution !default;
$alert-status-caution-bg:       #FA6900;    // status caution bg color

// info
$alert-info-color:              #3A87AD !default;
$alert-info-bg:                 #D9EDF7 !default;
$alert-info-border-color:       #BCE8F1 !default;
//$alert-info-icon:               aico-info !default;
//$alert-info-icon:               ico-info !default;

// help
$alert-help-color:              #3A87AD !default;
$alert-help-bg:                 #D9EDF7 !default;
$alert-help-border-color:       #BCE8F1 !default;
//$alert-help-icon:               aico-help !default;
//$alert-help-icon:               ico-help !default;

// wait
$alert-wait-color:              #fff !default;
$alert-wait-bg:                 #bbb !default;
$alert-wait-border-color:       #bbb !default;
//$alert-wait-icon:               ico-wait !default;
$alert-wait-icon-color:         #bbb !default;   // icon wait color
// wait-meter color
$alert-waitmeter-color:         #FA6900 !default;
$alert-waitmeter-height:        4px !default;

// status messages
$alert-arrow-size:              3px !default;

// status description (on forma only)
$alert-desc-color:              #999 !default;
$alert-desc-hover-color:        #555 !default;
$alert-desc-bg:                 #FFE669 !default; //#F9F9C4
$alert-desc-border-color:       darken(#FFE669, 15%) !default; // darken(#FCD116, 15%) !default;   //#EEED2A

// alert modal background
$alert-modal-bg:                rgba(255, 255, 255, 0.9) !default;

// notify type messages
$alert-notify-box-shadow:       -5px 7px 21px rgba(0,0,0,0.4);

// progress bar
$alert-progress-bar-darken:     15% !default;
$alert-progress-bar-size-px:    $alert-flash-border/5 !default; // 3px;

// badges
$badge-padding:     3px !default;
$badge-font:        rem-calc(10px) bold arial, sans-serif !default;
$badge-radius:      50%  !default;
$badge-min-width:   20px !default;  // to keep round badge for small numbers
$badge-color:       #fff !default;
$badge-bg:          #444 !default;
$badge-bg-alert:    #C1392B !default;
$badge-bg-info:     #2A80B9 !default;












// -----------------------------------------------------------------------------
//
// mixins
//

// alert type format mixin
@mixin alert-type($color, $bg-color, $border-color) {   //, $icon

                                                        //.#{$alert-css-prefix}ico{

    //@extend .#{$icon} !optional;        
    //background-color: $color;        
    //}
    i.ico{
        color: $color;
        fill: $color; 
    }
    &.mta-failed.mta-status > span,
    &.mta-failed.mta-status-small > span {
        color: $bg-color !important;
    }
    &.mta-desc.mta-status > span,
    &.mta-desc.mta-status-small > span {
        color: $color !important;
    }

    &, h4{
        color: $color;
        background-color: $bg-color;
        border-color: $border-color;
    }
    &:not(.mta-status) > span, &:not(.mta-status) > span i, &:not(.mta-status) > span b{
        color: $color;
    }
    
    .#{$alert-css-prefix}close{
        i, svg{
            fill: darken($border-color, 10%);
        }
    }

    // inside buttons panel
    .#{$alert-css-prefix}btns{

        // single button
        button{

            border-color: $border-color;
            background: lighten($color, 10%);

            // default, suggested action button
            &.#{$alert-css-prefix}default {
                border-color: $color;
                background: darken($color, 10%);

                &:hover{
                    background: lighten($color, 10%);
                }
            }

            &:hover{
                background: lighten($color, 20%);
            }
        }
    }

    // timeout progress indicator
    .#{$alert-css-prefix}progress {

        background: darken($border-color, $alert-progress-bar-darken);
    }
}

.#{$alert-css-prefix}alert{
    
    
   
    display: block;
    position: relative;      
    
    padding: {
        top: $alert-padding-v;
        right: $alert-padding-h*1.8;
        bottom: $alert-padding-v;
        left: $alert-padding-h*1.1;
    }
    //z-index: 1; // needed for modal mode 
    border: {
        width: $alert-border-width-px;
        style: $alert-border-style;
        radius: $alert-radius;
    }    
    // default stye
    border-left-width: 30px;

    text: {
        align: left; // justify
        shadow: $alert-text-shadow;
    }
    // make bottom distance
    line-height: 1.4em;
    vertical-align: middle;

    margin-bottom: $alert-margin;
    box-shadow: $alert-box-shadow;

    //
    // alert type icon (on lefy side) 
    //
    //.#{$alert-css-prefix}ico {
    & > i.ico{

        //@extend .#{$alert-icons-set} !optional;

        position: absolute;
        margin: 0;
        left: -29px;
        top: 4px;
        font-size: 0; 
        width: 28px;
        height: 28px;

        // default icon placement
        //left: -20px;
        //top: 4px;

        //width:32px; // make constant width to not cut icons
        /*background: {
            repeat: no-repeat;
            position: center center;
        }*/
        // defualt icons size (msg, msgFlash)
        /*font-size: 1.5em;
        left: -25px;
        top: 7px;
        */
        // if SVG used instead of font
        //width: 25px;
        //height: 25px;
        //background-size: 25px 25px;
    }

    //
    // main title
    //
    h4{
        margin: $alert-padding-v*0.5 0 $alert-padding-v 0;        
        font-size: 1.4em;
        font-weight: bold;
        text-align: center;

        @include larger-than(small) {
            //margin: -$alert-padding-v*0.8 0 $alert-padding-v 0;
            margin: 1rem 0 0.5rem 0;
            font-size: 2.2em;
        }
    }

    //
    // close button (right side) 
    //
    .#{$alert-css-prefix}close{

        //@extend .#{$alert-icons-set} !optional;
        //@extend .#{$alert-close-btn-icon};
        position: absolute;
        //width: $alert-close-btn-size-px;
        //height: $alert-close-btn-size-px;

        // corect position
        //top: 3px;
        //right: 7px;
        //height: 32px; 

        top: 0; //-4px;
        right: 0;
        //@include scale( .6 );

        //opacity: 0.8;

        width: 2rem;
        height: 2.5rem;
        svg{
            //float: right;
            width: 1.3rem;
            height: 1.3rem;                
        }

        &:active {
            margin: {
                top: 2px;
                left: 2px;
            }
        }
        // reset other properties to aviod conflicts with other modules
        box-shadow: none;
        border: 0;
        padding: 0; // make close area bigger than icon 
        margin: 0;  
        outline: none;
    }

    // buttons set
    .#{$alert-css-prefix}btns{

        // buttons position
        display: inline-block;
        width: 100%;
        text-align: center; // left | right
        margin: $alert-padding-v*2 0 $alert-padding-v 0;

        // button in button set
        button{

            color: white; // $alert-help-bg;
            @include box-bevel(.3, .2);

            height: auto;
            font-size: 0.88em;
            padding: rem-calc(7px) rem-calc(12px);                        
            transition: $alert-btn-hover-transition;

            border: {
                width: 0;
                radius: $alert-radius;
            }

            i{
                top: 3px;
            }

            // default, favorized button
            &.#{$alert-css-prefix}default {
                font-weight: bold;
                padding: rem-calc(10px) rem-calc(25px);

                @include larger-than(small) {

                    font-size: 1em;
                    padding: rem-calc(10px) rem-calc(30px);
                }
            }

            // active button    
            &:active{ 
                @include box-emboss(.3, .2);
                @include scale( .96 );
            }

            @include larger-than(small) {
                font-size: 1em;
                padding: rem-calc(8px) rem-calc(15px);
            }
            
        }

        // small buttons
        &.#{$alert-css-prefix}small {

            display: inline-block;
            margin: 0;
            button{
                font-size: 0.7em;
                padding: 1px 12px;
                margin-right: 0px;

                &.default {
                    padding: 1px 18px;
                }
            }
        }

        // align buttons on left or right
        &.#{$alert-css-prefix}left{
            text-align: left;
        }
        &.#{$alert-css-prefix}right{
            text-align: right;
        }

        @include larger-than(small) {
            margin: $alert-padding-v*4 0 $alert-padding-v 0;
        }
    }










    // -------------------------------------------------------------------------
    //
    // alert types
    //
    &.#{$alert-css-prefix}success{
        @include alert-type($alert-success-color, $alert-success-bg, $alert-success-border-color); //, $alert-success-icon
    }
    &.#{$alert-css-prefix}failed{
        @include alert-type($alert-failed-color, $alert-failed-bg, $alert-failed-border-color); // , $alert-failed-icon
    }
    &.#{$alert-css-prefix}caution{
        @include alert-type($alert-caution-color, $alert-caution-bg, $alert-caution-border-color); // , $alert-caution-icon
    }
    &.#{$alert-css-prefix}info{
        @include alert-type($alert-info-color, $alert-info-bg, $alert-info-border-color); // , $alert-info-icon
    }
    &.#{$alert-css-prefix}help{
        @include alert-type($alert-help-color, $alert-help-bg, $alert-help-border-color); // , $alert-help-icon
    }
    &.#{$alert-css-prefix}wait{
        @include alert-type($alert-wait-color, $alert-wait-bg, $alert-wait-border-color); // , $alert-wait-icon
    }

    
    
    
    
    
    

 
   
    
    
    

    // -------------------------------------------------------------------------
    //
    // alert fixed positions
    //
    &.#{$alert-css-prefix}fixed-center, &.#{$alert-css-prefix}fixed-top,
    &.#{$alert-css-prefix}fixed-bottom, &.#{$alert-css-prefix}fixed-top-left,
    &.#{$alert-css-prefix}fixed-top-right, &.#{$alert-css-prefix}fixed-bottom-left,
    &.#{$alert-css-prefix}fixed-bottom-right{
        position: fixed;
        z-index: 3000;
    }
    &.#{$alert-css-prefix}fixed-top, &.#{$alert-css-prefix}fixed-bottom{
        top: 0;
        bottom: auto;
        left: 0;
        right: 0;
        margin: 1px;
    }

    &.#{$alert-css-prefix}fixed-bottom{
        top: auto;
        bottom: 0;
    }

    &.#{$alert-css-prefix}fixed-top-left, &.#{$alert-css-prefix}fixed-top-right,
    &.#{$alert-css-prefix}fixed-bottom-left, &.#{$alert-css-prefix}fixed-bottom-right{

        margin: 2px;
        width: 316px; // constant width keeps alerts in right places while resizing screen
    }
    &.#{$alert-css-prefix}fixed-top-left, &.#{$alert-css-prefix}fixed-top-right{
        top: 0;
        bottom: auto;
        left: 0;
        right: auto;
    }
    &.#{$alert-css-prefix}fixed-bottom-left, &.#{$alert-css-prefix}fixed-bottom-right{
        top: auto;
        bottom: 0;
        left: 0;
        right: auto;
    }
    &.#{$alert-css-prefix}fixed-top-right, &.#{$alert-css-prefix}fixed-bottom-right{
        left: auto;
        right: 0;
    }

    &.#{$alert-css-prefix}fixed-center{
        top: 50%;
        left: 50%;

        min-width: 310px;
        max-width: 468px;

        box-shadow: $alert-box-shadow;

        @include translate(-50%, -50%);
    }
    &.#{$alert-css-prefix}panel,.#{$alert-css-prefix}fixed-center{
        padding-top: 6*$alert-padding-v;
    }

    // make alert transparent
    &.#{$alert-css-prefix}trans{
        @include opacity(0.8);
    }
    
 












    // -------------------------------------------------------------------------
    //
    // alert templates
    //







    //
    // status (small alerts template)
    //
    &.#{$alert-css-prefix}status, &.#{$alert-css-prefix}status-small{ // , &.#{$alert-css-prefix}status-wait

        color: #fff; // alert status icon & tex t color
        margin: 0 0 $alert-margin 0;
        padding-left: 32px;
        min-height: 28px;
        //max-width: 310px;   // maximum status message width (all except descrition in form)
        font-size: 0.72em;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);

        padding-right: $alert-padding-h*3;
        border: 0;
        border-radius: 3px;
        //@include box-bevel(); 

        & > i.ico{
            color: #fff;
            fill: #fff;
            width: 22px;
            height: 22px;
            left: 6px;
            top: 5px;
        }

        // types
        &.#{$alert-css-prefix}success{

            // icon 
            &.#{$alert-css-prefix}input-ico{
                &, & > i.ico {
                    fill: $alert-success-color;
                    color: $alert-success-color;
                }
            }

            background: $alert-success-color;
            &.#{$alert-css-prefix}arr-left { @include add-arrow($alert-success-color, 'left', $alert-arrow-size); }
            &.#{$alert-css-prefix}arr-top { @include add-arrow($alert-success-color, 'top', $alert-arrow-size);  }
            &.#{$alert-css-prefix}arr-right { @include add-arrow($alert-success-color, 'right', $alert-arrow-size); }
            &.#{$alert-css-prefix}arr-bottom { @include add-arrow($alert-success-color, 'bottom', $alert-arrow-size); }
            // success input in form
            &.#{$alert-css-prefix}arr-top-right { @include add-arrow($alert-success-color, 'top', $alert-arrow-size, 80%); }
        }       
        &.#{$alert-css-prefix}failed{

            // icon
            &.#{$alert-css-prefix}input-ico{
                &, & > i.ico {
                    fill: $alert-failed-color;
                    color: $alert-failed-color;
                }
            }

            background: $alert-failed-color;
            &.#{$alert-css-prefix}arr-left { @include add-arrow($alert-failed-color, 'left', $alert-arrow-size); }
            &.#{$alert-css-prefix}arr-top { @include add-arrow($alert-failed-color, 'top', $alert-arrow-size); }
            &.#{$alert-css-prefix}arr-right { @include add-arrow($alert-failed-color, 'right', $alert-arrow-size); }
            &.#{$alert-css-prefix}arr-bottom { @include add-arrow($alert-failed-color, 'bottom', $alert-arrow-size); }
            // invalid input in form
            &.#{$alert-css-prefix}arr-top-right { @include add-arrow($alert-failed-color, 'top', $alert-arrow-size, 80%); }
        }
        &.#{$alert-css-prefix}caution{

            // icon
            &.#{$alert-css-prefix}status.#{$alert-css-prefix}input-ico{ // correct vertical position
                                                                        margin-top: 2px;
            }

            &.#{$alert-css-prefix}input-ico{
                &, & > i.ico {
                    fill: $alert-status-caution-bg;
                    color: $alert-status-caution-bg;
                }
            }
            background: $alert-status-caution-bg;
            &.#{$alert-css-prefix}arr-left { @include add-arrow($alert-status-caution-bg, 'left', $alert-arrow-size); }
            &.#{$alert-css-prefix}arr-top { @include add-arrow($alert-status-caution-bg, 'top', $alert-arrow-size); }
            &.#{$alert-css-prefix}arr-right { @include add-arrow($alert-status-caution-bg, 'right', $alert-arrow-size); }
            &.#{$alert-css-prefix}arr-bottom { @include add-arrow($alert-status-caution-bg, 'bottom', $alert-arrow-size); }
            // caution for input in form
            &.#{$alert-css-prefix}arr-top-right { @include add-arrow($alert-status-caution-bg, 'top', $alert-arrow-size, 80%); }
        }
        &.#{$alert-css-prefix}info{

            // icon
            &.#{$alert-css-prefix}input-ico{
                &, & > i.ico {
                    fill: $alert-info-color;
                    color: $alert-info-color;
                }
            }
            background: $alert-info-color;
            &.#{$alert-css-prefix}arr-left { @include add-arrow($alert-info-color, 'left', $alert-arrow-size); }
            &.#{$alert-css-prefix}arr-top { @include add-arrow($alert-info-color, 'top', $alert-arrow-size); }
            &.#{$alert-css-prefix}arr-right { @include add-arrow($alert-info-color, 'right', $alert-arrow-size); }
            &.#{$alert-css-prefix}arr-bottom { @include add-arrow($alert-info-color, 'bottom', $alert-arrow-size); }
            // caution for input in form
            &.#{$alert-css-prefix}arr-top-right { @include add-arrow($alert-info-color, 'top', $alert-arrow-size, 80%); }
        }
        &.#{$alert-css-prefix}help{

            // icon
            &.#{$alert-css-prefix}input-ico{
                &, & > i.ico {
                    fill: $alert-help-color;
                    color: $alert-help-color;
                }
            }
            background: $alert-help-color;
            &.#{$alert-css-prefix}arr-left { @include add-arrow($alert-help-color, 'left', $alert-arrow-size);}
            &.#{$alert-css-prefix}arr-top { @include add-arrow($alert-help-color, 'top', $alert-arrow-size); }
            &.#{$alert-css-prefix}arr-right { @include add-arrow($alert-help-color, 'right', $alert-arrow-size); }
            &.#{$alert-css-prefix}arr-bottom { @include add-arrow($alert-help-color, 'bottom', $alert-arrow-size); }
        }
      
        
 
        // wait special type
        &.#{$alert-css-prefix}wait{

            background: $alert-wait-bg;
            &.#{$alert-css-prefix}arr-left { @include add-arrow($alert-wait-bg, 'left', $alert-arrow-size); }
            &.#{$alert-css-prefix}arr-top { @include add-arrow($alert-wait-bg, 'top', $alert-arrow-size); }
            // wait input in form
            &.#{$alert-css-prefix}arr-top-right { @include add-arrow($alert-wait-bg, 'top', $alert-arrow-size, 80%); }
            @extend .#{$alert-css-prefix}no-close;

            // animated wait icon
            //padding-left: 32px; // make space
            padding-left: 0.75rem;

            & > i.ico-wait {

                //@extend .#{$alert-wait-icon};
                background: none;
                fill: white; // wait icon color 

                width: 15px;
                height: 15px;
                margin: 0 .5rem 0 0;

                //font-size: 1em;
                position: relative;
                top: -1px;
                left: 0;
                //left: 8px;
                //top: 7px;
                //left: 8px;
                /*
                    color: #fff;
                    display: block;
                    position: absolute;
                    top: 6px;
                    left: 6px;
                    width: 16px;
                    height: 16px;
                    background-size: 16px 16px;
                    // wait animated icon GIF
                    @extend .#{$alert-css-prefix}icon-wait-999;
                */
                // wait as rotating font icon
                //@extend .icon-wait;
            }
            &.#{$alert-css-prefix}small{
                //@extend .#{$alert-css-prefix}icon-wait-999;

                // set background image
                background: {
                    repeat: no-repeat;
                    position: center center;
                }
            }
        }

        // small = only icon is shown
        &.#{$alert-css-prefix}small{

            float: left;
            padding: $alert-padding-v $alert-padding-h*1.16;
            &:before{ content: '\0000a0';  }

            //.#{$alert-css-prefix}ico {
            i.ico{
                //left: 6.5px;
            }

            &.#{$alert-css-prefix}round {
                border-radius: 50%;
                // to make it smaller uncomment below
                /*/padding: 1.6px 8.5px;*/
            }
            box-shadow: none;
            //@include scale(.75);
        }

        // make status alert pulse
        &.#{$alert-css-prefix}pulse {
            @extend .#{$alert-css-prefix}alert-anim;
            @include vendor-prefix(animation-name, pulse);
            @include vendor-prefix(animation-iteration-count, infinite);
        }

        // for absolute positioned status alerts
        &.#{$alert-css-prefix}abs {
            position: absolute;
            //white-space: nowrap;
            z-index: 91;
            margin:0;
        }


        //
        // form inputs alerts
        //

        // input description
        &.#{$alert-css-prefix}desc{
            float: left;
            color: $alert-desc-color;
            text-shadow: none;
            box-shadow: none;
            border: 1px solid transparent;
            padding: $alert-padding-v;
            width: auto;
            //max-width: auto;

            /*.#{$alert-css-prefix}ico{                
                position: relative;
                left: 1px;
                top: 2px;
                font-size: 1.2em;
                // distance from text 
                margin-right: 5px;
            }*/

            // info icon
            i.ico{
                position: relative;
                left: 0;
                top: -1px;
                margin-right: 3px;
                fill: $alert-desc-color;
                width: 14px;
                height: 14px;
            }

            // info text/description
            & > span{}

            // on hover
            &:hover, &.hover{
                
                &, i.ico {
                    color: $alert-desc-hover-color;
                    fill: $alert-desc-hover-color;
                }
            }

            // on active input
            &:active, &.active {
                
                &, i.ico {
                    color: $alert-desc-hover-color;
                    fill: $alert-desc-hover-color;
                }
                background: $alert-desc-bg;
                border: 1px solid $alert-desc-border-color;
                @include add-arrow($alert-desc-bg, 'top', 6px, 10%, 1px, $alert-desc-border-color);
            }

            @include vendor-prefix(transition, all .3s);
        }

        .#{$alert-css-prefix}close{
            //color: white;
            //background: none;
            //@include scale( .5 );
            margin: 4px;
            svg{

                width: 1rem;
                height: 1rem;
                fill: #fff;                
            }
        }


        // remove place for close button if don't exists
        &.#{$alert-css-prefix}no-close{
            padding-right: $alert-padding-v*1.5;
            .#{$alert-css-prefix}close{
                display:none;
            }
        }

        //
        // input only icon status near or inside input 
        //
        &.#{$alert-css-prefix}status.#{$alert-css-prefix}input-ico{

            background: none;

            // margin-right: 5px;
            //padding: 0 10px 0 0;
            width: 43px; // related to below i.ico width 
            height: 33px;

            // make icon bigger
            & > i.ico, & > i.ico-wait {

                position: absolute;
                margin-top: -5px; // correct position 
                width: 33px;
                height: 33px;
            }

            &.#{$alert-css-prefix}wait{ // icon wait

                .ico-wait {
                    margin: 3px 0 0 8px; 
                    width: 27px;
                    height: 27px;
                    background-color: $alert-wait-bg;
                    border-radius: 50%;

                    // corerct animated icon position to i container
                    svg {

                        position: relative;
                        top: 3px;
                        width: 21px;
                        height: 21px;
                    }
                }
            }

            @include larger-than(small) {

                // show icon outside input (on right side) 
                margin-right: -43px - 5px;

                // inside icon show always inside 
                &.#{$alert-css-prefix}inp-inside {

                    margin-right: 0;
                }
            }
        }       
        
    } // end of status
    









    //
    // large
    //
    &.#{$alert-css-prefix}large{

        border-left-width: $alert-border-width-px;
        border-top-width: 20px;
        padding: $alert-padding-h;

        //.#{$alert-css-prefix}ico {
        i.ico{
            top: -18px;
            left: 3%;
            font-size: 2.2em;
        }

        .#{$alert-css-prefix}close{
            //background: none;
            background-color: none;
            color:none;
            top: -21px;
            right: 0;
        }

        // media queries
        @include larger-than(small) {

            //.#{$alert-css-prefix}ico {
            i.ico {

                //font-size: 3.1em;
                top: -26px;
                //background-size: 48px 48px;
                //background-size: contain;
                @include scale(1.4);
                /* if SVG used instead of font
                    width: 48px;
                    height: 48px;
                    background-size: 48px 48px;
                */
            }
        }
    }









    //
    // panel (static, big areas or centered with buttons as model windows)
    //
    &.#{$alert-css-prefix}panel {

        box-shadow: none;
        border-left-width: $alert-flash-border;
        padding: $alert-padding-v $alert-padding-h $alert-padding-v $alert-flash-border*1.8;

        //.#{$alert-css-prefix}ico {
        & > i.ico{
            top: 4px;
            left: -10px;
            //font-size: 2.2em;
        }

        // media queries
        @include larger-than(small) {

            padding: $alert-padding-v*2.5 $alert-padding-h*2 $alert-padding-v*2.5 $alert-flash-border*2;
            border-left-width: $alert-flash-border*2;

            //.#{$alert-css-prefix}ico {
            & > i.ico{
                //font-size: 3.1em;
                left: -22px;
                top: 8px;
                width:46px;
                height: 46px;
                //@include scale(1.8);
                /* if SVG used instead of font
                    width: 48px;
                    height: 48px;
                    background-size: 48px 48px;
                */
            }
        }
    }










    //
    // flash
    //
    &.#{$alert-css-prefix}flash {

        display: none;
        left: 0;
        right: 0;
        margin: 0;
        //height: 2*$alert-padding-v + $alert-flash-border;
        padding: $alert-padding-v*1.5 $alert-padding-h;
        padding-left: 2.2rem;
        font-size: 0.88em;
        text-align: center;        
        border: {
            width: 0;
            radius: 0;
        }
        border-bottom-width: $alert-flash-border/5;
        z-index: 3000; // above everything (2000 is popup)
        //opacity: 0.9;

        & > i.ico{
            //font-size: 2em;
            left: 2px;
            top: 1px;
            bottom: auto;
            /* if SVG used instead of font
                width: 58px;
                height: 58px;
                background-size: 58px 58px;
            */
        }

        .#{$alert-css-prefix}close{
            right: .4rem;
            top: 0;
            //font-size: 1.8em;
        }

        &.#{$alert-css-prefix}fixed-bottom{

            border-bottom-width: 0;
            border-top-width: $alert-flash-border;


            & > i.ico{
                bottom: none;
                top: -12px;
            }
        }

        // timeout indicator
        .#{$alert-css-prefix}progress {
            position: absolute;
            width: 0;
            height: $alert-progress-bar-size-px;
            bottom: -$alert-flash-border/5;
            //left: -100%;
            left: 0;
        }

        // existing flash alert re-try
        &.#{$alert-css-prefix}alert-exists {

            @extend .#{$alert-css-prefix}alert-anim;
            @include vendor-prefix(animation-name, bounce);
        }

        // media queries
        @include larger-than(tiny) {

            font-size: 1.1em;
            padding: $alert-padding-v*0.86 $alert-padding-h;

            & > i.ico{
                left: 7px;
                top: 1px;
                margin: 0;
            }

            .#{$alert-css-prefix}close{
                //top: 0;
                //right: 0;
            }            
        }
        @include larger-than(small) {

            padding: $alert-padding-v $alert-padding-h;
            padding-left: 2.4em;
            font-size: 1.3em;
            border-bottom-width: $alert-flash-border;

            & > i.ico{

                left: 10px;
                width: 3rem;
                height: 3rem;
                //font-size: 2.8em;
                top: initial;
                bottom: -7px;
                //@include scale( 1.6 );
            }
            .#{$alert-css-prefix}close{
                //font-size: 1.0em;
                //top: 0;
                //right: 0;
            }

            .#{$alert-css-prefix}progress {

                bottom: -$alert-flash-border;                
            }
        }
    }

    
    










    //
    // notify
    //
    &.#{$alert-css-prefix}notify{

        display: none;
        //overflow: hidden;   // for timeout proegress bar
        //border: 1px solid red
        border-radius: 0;

        // ico
        & > i.ico{
            font-size: 1.2em;
            top: 5px;
            left: -23px;
            /* if SVG used instead of font
                width: 18px;
                height: 18px;
                background-size: 18px 18px;
            */
        }

        // top side progress bar
        .#{$alert-css-prefix}progress {
            position: absolute;
            width: 0; // 100%;
            height: $alert-progress-bar-size-px;
            top: 0;
            //left: -100%;
            left: 0; //-100%;
            z-index: 1;
        }

        box-shadow: $alert-notify-box-shadow;
    }










    //
    // permanent
    //
    &.permanent{

        border-radius: 0;
        padding-top: .4rem;
        padding-bottom: .4rem;
        box-shadow: none;
        font-size: 0.76rem;

        i.ico{
            top: -1px;
        }
        .#{$alert-css-prefix}close{
            top: 2px;
        }
    }








    // existing alert re-try
    &.#{$alert-css-prefix}alert-exists {

        //@extend .#{$alert-css-prefix}alert-anim;
        @include vendor-prefix(animation-duration, 0.25s);
        @include vendor-prefix(animation-name, shake-status);
    }

    // alert common media queries
    @include larger-than(small) {

        margin-bottom: $alert-margin*4;

        // to view alert text in columns for lager than smaller screens
        // outline contens with span
        span.#{$alert-css-prefix}mta-columns{

            @include column-count(2);
            @include column-gap($alert-padding-h*2);
            //@include column-rule(1px dotted #efefef);
        }
    } 

    
    
    
    
} // end of .alert
// -----------------------------------------------------------------------------
//
// additioanl styles
//







//
// modal (bg)
//
.#{$alert-css-prefix}alert-modal{
    position: fixed;
    top: 0; left:0; right: 0;
    height: 100%;
    background: $alert-modal-bg;
    z-index: 2001;
}








//
// wait meter / progress bar
//
.#{$alert-css-prefix}wait-meter{
    z-index: 3000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: $alert-waitmeter-height;


    & > span{
        width:100%;
        //border-bottom: 1px solid rgb(43,194,83);

        display: block;
        height: 100%;
        background-color: $alert-waitmeter-color;
        position: relative;
        overflow: hidden;

        &:after {
            content: "";
            position: absolute;
            overflow: hidden;
            top: 0; left: 0; bottom: 0; right: 0;
            z-index: 1;
            /*@include background(linear-gradient(-45deg,
            rgba(255, 255, 255, .2) 25%,
            transparent 25%,
            transparent 50%,
            rgba(255, 255, 255, .2) 50%,
            rgba(255, 255, 255, .2) 75%,
            transparent 75%,
            transparent));*/
            //@include vendor-prefix(background-size, 50px 50px);
            //@include vendor-prefix(animation, move-strips 1s linear infinite);
            //@include box_bevel();
            background: -webkit-linear-gradient(-45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent);
            background: -moz-linear-gradient(-45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent);
            background: -o-linear-gradient(-45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent);
            background: linear-gradient(-45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent);
            background-size: 50px 50px;
            -webkit-background-size: 50px 50px;
            -moz-background-size: 50px 50px;
            -ms-background-size: 50px 50px;
            -o-background-size: 50px 50px;
            animation: move-strips 1s linear infinite;
            -webkit-animation: move-strips 1s linear infinite;
            -moz-animation: move-strips 1s linear infinite;
            -ms-animation: move-strips 1s linear infinite;
            -o-animation: move-strips 1s linear infinite;
            box-shadow: inset rgba(0, 0, 0, 0.1) -1px -1px 0, rgba(255, 255, 255, 0.2) -1px -1px 0;
        }
    }
}
@include keyframes(move-strips) { 
    from { background-position: 0 0; } 
    to { background-position: 50px 50px;}
}
// wait meter label
.#{$alert-css-prefix}alert.#{$alert-css-prefix}status.#{$alert-css-prefix}wait-meter-label{

    z-index: 3000;
    position: fixed;
    top: $alert-waitmeter-height+2;
    right: $alert-waitmeter-height *5 +2;
    background: $alert-status-caution-bg;
    text-shadow: none;
    box-shadow: none;
    font-weight: bold;

    //.#{$alert-css-prefix}ico {
    //@extend .#{$alert-css-prefix}icon-wait-FA6900;
        //}

    @include larger-than(small) {
        top: $alert-waitmeter-height *2;
    }
}









//
// badges
//
.#{$alert-css-prefix}badge{

    display:inline-block;
    text-align: center;
    padding: $badge-padding;
    font: $badge-font;
    border-radius: $badge-radius;
    background: $badge-bg;
    min-width: $badge-min-width;
    //opacity: 0.9;

    &.alert{
        background: $badge-bg-alert;
    }
    &.info{
        background: $badge-bg-info;
    }

}


// -----------------------------------------------------------------------------
//
// helpers
//

// wait icons 
/*.#{$alert-css-prefix}icon-wait-999{
    background-image: url("data:image/gif;base64,R0lGODlhEAAQAPQAAJmZmf///5ubm+fn58nJyfv7++7u7qioqLu7u/X19c/Pz9XV1aOjo8HBwa+vr+Hh4dvb2wAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH+GkNyZWF0ZWQgd2l0aCBhamF4bG9hZC5pbmZvACH5BAAKAAAAIf8LTkVUU0NBUEUyLjADAQAAACwAAAAAEAAQAAAFUCAgjmRpnqUwFGwhKoRgqq2YFMaRGjWA8AbZiIBbjQQ8AmmFUJEQhQGJhaKOrCksgEla+KIkYvC6SJKQOISoNSYdeIk1ayA8ExTyeR3F749CACH5BAAKAAEALAAAAAAQABAAAAVoICCKR9KMaCoaxeCoqEAkRX3AwMHWxQIIjJSAZWgUEgzBwCBAEQpMwIDwY1FHgwJCtOW2UDWYIDyqNVVkUbYr6CK+o2eUMKgWrqKhj0FrEM8jQQALPFA3MAc8CQSAMA5ZBjgqDQmHIyEAIfkEAAoAAgAsAAAAABAAEAAABWAgII4j85Ao2hRIKgrEUBQJLaSHMe8zgQo6Q8sxS7RIhILhBkgumCTZsXkACBC+0cwF2GoLLoFXREDcDlkAojBICRaFLDCOQtQKjmsQSubtDFU/NXcDBHwkaw1cKQ8MiyEAIfkEAAoAAwAsAAAAABAAEAAABVIgII5kaZ6AIJQCMRTFQKiDQx4GrBfGa4uCnAEhQuRgPwCBtwK+kCNFgjh6QlFYgGO7baJ2CxIioSDpwqNggWCGDVVGphly3BkOpXDrKfNm/4AhACH5BAAKAAQALAAAAAAQABAAAAVgICCOZGmeqEAMRTEQwskYbV0Yx7kYSIzQhtgoBxCKBDQCIOcoLBimRiFhSABYU5gIgW01pLUBYkRItAYAqrlhYiwKjiWAcDMWY8QjsCf4DewiBzQ2N1AmKlgvgCiMjSQhACH5BAAKAAUALAAAAAAQABAAAAVfICCOZGmeqEgUxUAIpkA0AMKyxkEiSZEIsJqhYAg+boUFSTAkiBiNHks3sg1ILAfBiS10gyqCg0UaFBCkwy3RYKiIYMAC+RAxiQgYsJdAjw5DN2gILzEEZgVcKYuMJiEAOwAAAAAAAAAAAA==");
}
.#{$alert-css-prefix}icon-wait-FA6900{
    background-image: url("data:image/gif;base64,R0lGODlhEAAQAPQAAPppAP////psBv3dxvuwev36+P3n2PqAKPubVv3x6Py4iPzCmPp4GvukZvqJOPzTtvzLqAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH+GkNyZWF0ZWQgd2l0aCBhamF4bG9hZC5pbmZvACH5BAAKAAAAIf8LTkVUU0NBUEUyLjADAQAAACwAAAAAEAAQAAAFUCAgjmRpnqUwFGwhKoRgqq2YFMaRGjWA8AbZiIBbjQQ8AmmFUJEQhQGJhaKOrCksgEla+KIkYvC6SJKQOISoNSYdeIk1ayA8ExTyeR3F749CACH5BAAKAAEALAAAAAAQABAAAAVoICCKR9KMaCoaxeCoqEAkRX3AwMHWxQIIjJSAZWgUEgzBwCBAEQpMwIDwY1FHgwJCtOW2UDWYIDyqNVVkUbYr6CK+o2eUMKgWrqKhj0FrEM8jQQALPFA3MAc8CQSAMA5ZBjgqDQmHIyEAIfkEAAoAAgAsAAAAABAAEAAABWAgII4j85Ao2hRIKgrEUBQJLaSHMe8zgQo6Q8sxS7RIhILhBkgumCTZsXkACBC+0cwF2GoLLoFXREDcDlkAojBICRaFLDCOQtQKjmsQSubtDFU/NXcDBHwkaw1cKQ8MiyEAIfkEAAoAAwAsAAAAABAAEAAABVIgII5kaZ6AIJQCMRTFQKiDQx4GrBfGa4uCnAEhQuRgPwCBtwK+kCNFgjh6QlFYgGO7baJ2CxIioSDpwqNggWCGDVVGphly3BkOpXDrKfNm/4AhACH5BAAKAAQALAAAAAAQABAAAAVgICCOZGmeqEAMRTEQwskYbV0Yx7kYSIzQhtgoBxCKBDQCIOcoLBimRiFhSABYU5gIgW01pLUBYkRItAYAqrlhYiwKjiWAcDMWY8QjsCf4DewiBzQ2N1AmKlgvgCiMjSQhACH5BAAKAAUALAAAAAAQABAAAAVfICCOZGmeqEgUxUAIpkA0AMKyxkEiSZEIsJqhYAg+boUFSTAkiBiNHks3sg1ILAfBiS10gyqCg0UaFBCkwy3RYKiIYMAC+RAxiQgYsJdAjw5DN2gILzEEZgVcKYuMJiEAOwAAAAAAAAAAAA==");
}*/

// existing alert re-invoked - animation (base parameters)
.#{$alert-css-prefix}alert-anim{

    @include vendor-prefix(animation-duration, 1s);
    @include vendor-prefix(animation-fill-mode, both);
}

// shake animation
@-webkit-keyframes shake {
    0%, 100% {-webkit-transform: translateX(0);}
    10%, 30%, 50%, 70%, 90% {-webkit-transform: translateX(-10px);}
    20%, 40%, 60%, 80% {-webkit-transform: translateX(10px);}
}

@-moz-keyframes shake {
    0%, 100% {-moz-transform: translateX(0);}
    10%, 30%, 50%, 70%, 90% {-moz-transform: translateX(-10px);}
    20%, 40%, 60%, 80% {-moz-transform: translateX(10px);}
}

@-o-keyframes shake {
    0%, 100% {-o-transform: translateX(0);}
    10%, 30%, 50%, 70%, 90% {-o-transform: translateX(-10px);}
    20%, 40%, 60%, 80% {-o-transform: translateX(10px);}
}

@keyframes shake {
    0%, 100% {transform: translateX(0);}
    10%, 30%, 50%, 70%, 90% {transform: translateX(-10px);}
    20%, 40%, 60%, 80% {transform: translateX(10px);}
}

// shake status alert animation
@-webkit-keyframes shake-status {
    0%, 100% {-webkit-transform: translateX(0);}
    33%{-webkit-transform: translateX(-8px);}
    66% {-webkit-transform: translateX(8px);}
}

@-moz-keyframes shake-status {
    0%, 100% {-moz-transform: translateX(0);}
    33% {-moz-transform: translateX(-8px);}
    66% {-moz-transform: translateX(8px);}
}

@-o-keyframes shake-status {
    0%, 100% {-o-transform: translateX(0);}
    33% {-o-transform: translateX(-8px);}
    66% {-o-transform: translateX(8px);}
}
@keyframes shake-status {
    0%, 100% {transform: translateX(0);}
    33%{transform: translateX(-8px);}
    66% {transform: translateX(8px);}
}

// bounce animation
@-webkit-keyframes bounce {
    0%, 20%, 50%, 80%, 100% {-webkit-transform: translateY(0);}
    40% {-webkit-transform: translateY(-30px);}
    60% {-webkit-transform: translateY(-15px);}
}

@-moz-keyframes bounce {
    0%, 20%, 50%, 80%, 100% {-moz-transform: translateY(0);}
    40% {-moz-transform: translateY(-30px);}
    60% {-moz-transform: translateY(-15px);}
}

@-o-keyframes bounce {
    0%, 20%, 50%, 80%, 100% {-o-transform: translateY(0);}
    40% {-o-transform: translateY(-30px);}
    60% {-o-transform: translateY(-15px);}
}
@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
    40% {transform: translateY(-30px);}
    60% {transform: translateY(-15px);}
}

// pulse animation
@-webkit-keyframes pulse {
    0% { -webkit-transform: scale(1); }
    50% { -webkit-transform: scale(1.03); }
    100% { -webkit-transform: scale(1); }
}
@-moz-keyframes pulse {
    0% { -moz-transform: scale(1); }
    50% { -moz-transform: scale(1.03); }
    100% { -moz-transform: scale(1); }
}
@-o-keyframes pulse {
    0% { -o-transform: scale(1); }
    50% { -o-transform: scale(1.03); }
    100% { -o-transform: scale(1); }
}
@keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.03); }
    100% { transform: scale(1); }
}
