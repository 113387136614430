.accordion {
    margin: 0 0 0 0;
    background: transparent;

    .accordion-item {
        //background: none;

        .accordion-title {
            border: 0;
            border-bottom-width: 1px;
            border-bottom-style: dotted;

            margin: 0 10px;
            font-size: 100%;
            &:focus{
                background-color: transparent;
            }
        }
        /*.accordion-title::before {
            position: absolute;
            top: 50%;
            left: 1rem;            
        }*/

        .accordion-content{
            border: 0;
            background-color: none;
            & > p{
                margin: 0;
            }
        }

        /*&:is-active:not(:first-of-type){
            margin-top: 5px;
        }*.accordion-title::before {
    position: absolute;
    top: 50%;
    right: 1rem;
    margin-top: -0.5rem;
    content: '+';
}*/
    }
}
