//
// icons set definition 
//
$prefix: 'l';

.#{$prefix}icons {
    display: inline-block;
    width: 32px;
    height: 32px;
    // common location: /webroot/icons/ ...
    // set relative path to /theme/{Themename}/theme.css to:
    // ../../../icons/{SetName}/sprites/sprites.svg
    background-image: url(../../../icons/listing/sprites.svg); 
    background-color: transparent;
    background-repeat: no-repeat;
}

//
// icons
//
// NOTE: icon colors must be set in sprites.svg as paths tags fill attribute values 
// ex. <path fill="#000000"...>
.#{$prefix}icons{
    // colored 
    &.ico-search {
        background-position: 0 0;
        width: 30px;
    }       
    &.ico-clear-filter {
        background-position: -445px 0;
        width: 32px;
    }       
}