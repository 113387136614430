//
// Banner
//
.bns{
    
    > div { // single banner
    
        //float: left;
        
        > a {   // link to target url
            //display: block;
            //position: relative;

            > img{  // right banner image 
                    
                /*position: absolute;
                left:0;
                top:0;
                
                &.phantom{
                    position: static;
                    visibility: hidden;
                }
                */
            }
        }
        
        &:not(:last-of-type){           
            //margin: 0 $theme-default-padding*3.6 $theme-default-padding*2 0;
            //margin: 0 0 $theme-default-padding*2 0;
        }
    }
    
    &.floated{
        
        > div {
            float: left;
            
            >a{
                display: inline-block;
            }
        }
    }
    
    &.grid{
        
        >.row{
            
            //padding: 0 $column-gutter*.5;
            
            >.columns{
                //padding: 0 $column-gutter*.25;
            }
        }
        
        [data-size]{
            //margin-bottom: $theme-default-padding*2;
            margin-bottom: map-get($grid-column-gutter, small) *.5;
            text-align: center;
        }
    }
}