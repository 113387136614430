/*
mtsoft Listing.
*/   

// use compass
//@import "../../../libs/compass/css3"; 

@import "listing-icons";

//
// variables (applies to default theme) 
//  
$mt-listing-css-prefix:                     'mtl-' !default;  

// search-box
$mt-listing-search-box-search-on-bg:            yellow !default;  
$mt-listing-search-box-search-no-results-bg:    orange !default; 

// filter link
$mt-listing-filter-link-color:                  #2BA6CB !default;
$mt-listing-filter-active-link-color:           darken($mt-listing-filter-link-color, 20%) !default;

// clear filter/search expression button(icon)
$mt-listing-clear-btn-color:                    #999 !default;
$mt-listing-clear-btn-hover-color:              #000 !default;
$mt-listing-clear-btn-active-color:             #000 !default;

// listing container
.#{$mt-listing-css-prefix}list{

    overflow: hidden; // reuqired for prev/next page changed animation
    position: relative;
    
    & > div{
        float: left;    // to show right position and size lock layer         
        width: 100%; 
    }

    & >div > div, .#{$mt-listing-css-prefix}page {

        //background: #fff;
        // needed for animation        
        //border: 1px solid transparent;
        transition: all 0.3s;

        &.animating{           

            //border: 1px solid #efefef;
            box-shadow: 0 0 12px rgba(0, 0, 0, 0.4);
        }
    }

    // no rows found - listing replacement
    .#{$mt-listing-css-prefix}list-no-rows{

        width: 100%;
        background: #efefef;
        height: 240px;
        text-align: center;
        border-radius: 3px;

        & > span{

            display: inline-block;
            vertical-align: middle;
            height: 100%;
        }
        & > .ico-blocked{

            width: 10rem;
            fill: #DADADA;
            vertical-align: middle;
        }
    }        
}

//
// naviagtion panel with pages numbers
//
.#{$mt-listing-css-prefix}numbers{
    display: inline-block;
}

//
// autoload marker 
//
.#{$mt-listing-css-prefix}autoload{
    min-height: 5em;

    .ico-wait{
        width: 3rem;
        fill: #DADADA;
        vertical-align: middle;
    }
}

//
// link to next auto-load pages set
//
.#{$mt-listing-css-prefix}autoload-next-pages{ 
    display: none;
}
//
// filter links
//
a.#{$mt-listing-css-prefix}flt-link{ 

    color: $mt-listing-filter-link-color;
    
    &:hover{

    }
}
// currently active filter link (applied)
a.#{$mt-listing-css-prefix}flt-link-active{ 

    color: $mt-listing-filter-active-link-color;
}

//
// search box & filter box
//
.list-top-filters .forma .#{$mt-listing-css-prefix}search-box, .list-top-filters .forma .#{$mt-listing-css-prefix}filter-box{ 

    position: relative;
    
    & > div { 
        //&:not(select) > * { 
        //float: left;
        width: 100%;
        padding-right: 80px;
        display: inline-block;
        vertical-align: middle;        
    }

    // search/filter expressiong applied and some rows found (& - filter, input - search)
    &.results-on, input[type="text"].results-on{
        background-color: $mt-listing-search-box-search-on-bg;
    }

    // search/filter returned no results
    &.no-results, input[type="text"].no-results{
        background-color: $mt-listing-search-box-search-no-results-bg;
    }

    input[type="text"]:hover ~ .clear-expr,
    &:hover ~ .clear-expr{

        color: $mt-listing-clear-btn-hover-color;
        fill: $mt-listing-clear-btn-hover-color;    
    }

    // search/filter submit button style
    button{
        width: 66px;
        //height: 46px;
        position: absolute;
        top: 0px;
        right: 0;
        margin: 0;
        //margin-top: -1px;
    }    
}

.#{$mt-listing-css-prefix}select-box.selected{
    //@include form-input-selected;
}

// clear search/filter insde input button
.#{$mt-listing-css-prefix}search-box .clear-expr, 
.#{$mt-listing-css-prefix}filter-box ~ .clear-expr{

    display: none; 
    margin: 0;
    cursor: pointer;
    color: $mt-listing-clear-btn-color;
    fill: $mt-listing-clear-btn-color;    

    &:hover{
        color: $mt-listing-clear-btn-active-color;
        fill: $mt-listing-clear-btn-active-color;    
    }
}
.#{$mt-listing-css-prefix}flt-link > .clear-expr,
.#{$mt-listing-css-prefix}flt-link-active > .clear-expr{
    display: inline-block;
    visibility: hidden;
    position: relative;
    //top: .7rem;
    //margin-left: .1rem;
    //@extend .licons;
    //@extend .ico-clear-filter;
    //@include scale(.6);
    //zoom: 10%
}
.#{$mt-listing-css-prefix}flt-link-active > .clear-expr{
    visibility: visible;    
}
// wait icon
.#{$mt-listing-css-prefix}search-box .ico-wait, .#{$mt-listing-css-prefix}filter-box ~ .ico-wait{
    //margin: 3px 3px 0 0;
    fill: #999;
}





// lazyload images animation
.anim.llAnim {

    @include vendor-prefix(animation-name, llAnim);
    @include vendor-prefix(animation-duration, 1s);
}
@-webkit-keyframes llAnim {
    0% {
    opacity: 0;
    //-webkit-transform: translateY(-10px);
    //transform: translateY(-10px);
    }

  100% {
    opacity: 1;
    //-webkit-transform: translateY(0);
    //transform: translateY(0);
  }
}

@keyframes llAnim {
    0% {
    opacity: 0;
    //-webkit-transform: translateY(-10px);
    //-ms-transform: translateY(-10px);
    //transform: translateY(-10px);
    }

  100% {
    opacity: 1;
    //-webkit-transform: translateY(0);
    //-ms-transform: translateY(0);
    //transform: translateY(0);
  }
}
// change page animation
.anim.page-up {

    @include vendor-prefix(animation-name, page-up);
    @include vendor-prefix(animation-duration, 1s);
}
@-webkit-keyframes page-up {
    0% {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

@keyframes page-up {
    0% {
        -webkit-transform: translateX(100%);
        -ms-transform: translateX(100%);
        transform: translateX(100%);
    }

    100% {
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);        
    }
}
.anim.page-down { 

    @include vendor-prefix(animation-name, page-down);
    @include vendor-prefix(animation-duration, 1s);
}
@-webkit-keyframes page-down {
    0% {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

@keyframes page-down {
    0% {
        -webkit-transform: translateX(-100%);
        -ms-transform: translateX(-100%);
        transform: translateX(-100%);
    }

    100% {
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);        
    }
}
// no prev/next page list animation
.anim.no-page {
    @include vendor-prefix(animation-name, no-page);
    @include vendor-prefix(animation-duration, .2s);
}
@-webkit-keyframes no-page {
    0%, 100% {-webkit-transform: translateX(0);}
    33% {-webkit-transform: translateX(-10px);}
    66%{-webkit-transform: translateX(10px);}
}
@-moz-keyframes no-page {
    0%, 100% {-moz-transform: translateX(0);}
    33%{-moz-transform: translateX(-10px);}
    66% {-moz-transform: translateX(10px);}
}
@-o-keyframes no-page {
    0%, 100% {-o-transform: translateX(0);}
    33% {-o-transform: translateX(-10px);}
    66% {-o-transform: translateX(10px);}
}
@keyframes no-page {
    0%, 100% {transform: translateX(0);}
    33% {transform: translateX(-10px);}
    66% {transform: translateX(10px);}
}
// append chunk of listing as slided down contents
.anim.page-slide-down {
    @include vendor-prefix(animation-name, page-slide-down);
    @include vendor-prefix(animation-duration, 3s);
}
@include keyframes(page-slide-down) {

    from { max-height: 0px; }
    to { max-height: 1000px; }
}