
// Episode view 
.view{
    
    position: relative;
    
    h1{
        line-height: 2rem;
    }
    
    img{
       // border-radius: $theme-default-border-radius-small;
        
        &:hover{
            z-index: 9; 
        }
    }
    
    &.episode, &.show{
        
        .episode-btns{
            
            margin-top: 1rem;
            text-align: center;
            button{
                //float: right;
            }
        }
        .episode-img img{
            
            width:100%;
        }
        .tags-out{
            margin-top: .5rem;
        }
        
        .host-card, .guest-card{
            min-height: 232px;
            //background: darken($theme-color-base, 5%);
            //border-radius: $theme-default-border-radius-small;
            //padding: $theme-default-padding *2;
        }
        .person-desc{
            //font-size: .88rem;
            line-height: 1.2rem;
        }
        .person-view-more{
            float: right;
            margin-right: 1rem;
        }
        
        .cc-view .cc-date, .cc-view .cc-time {}
        .cc-date{

            & + span.cc-time{
                margin-left: 1rem;
            }
        }
        
        .hosts-guests{
            margin-top: 4rem;
        }
    }
    
    .social-share-btns{
        margin: 2rem 0;
    }
    .feed-icon { 
        
        top: 10px;
        right: 10px;
        
        a:hover{
            background: none;
        }
    }
}

// hosts/guest letters links
.list-top-filters{
    
    text-align: center;
    
    a{
        padding: .5rem;
        font-size: 1.5rem;
    }
    
}

// hosts listing
.row-host .box.bg, .row-guest .box.bg{
    padding: 0;
    
    .row-box-name{
        height: 60px;
        padding: .5rem 0;
        text-align: center;
        font-weight: bold;
    }
    
    & img{
        width:100%;
    }
}
.row-episode, .row-host, .row-guest{
    img{
        width: 100%;
    }
}

.view-host{
    
    img{
        //border-radius: $theme-default-border-radius-small; 
        width: 100%;
    }
    
    .social-media-urls{
        
        i.ico{

            //fill: $cc-box-title-color;
            width: 21px;
            height: 21px;
        }
        a{
            font-size: .88rem;
        }
    }
    z-index: 1; // to be above player
}
.view-guest{
    z-index: 1; // to be above player
    
    img{
        //border-radius: $theme-default-border-radius-small; 
        width: 100%;
    }
    
}

// RSS icon
.feed-icon{ 
    position:absolute;
    right:5px;
    top:5px; 

    a{
        display: inline-block;
        width: 40px;
        height: 40px;
        
        &, &:hover{
            
            i.ico{
                
                //fill: #FF9900;
                //color: #FF9900;
            }
            background: none;
        }
    }
}
.cc-view.show{
    
    img{
        width:100%;
    }
}

.host-custom-boxes{
    
    .box{
       min-height: 455px !important;  
       
       img{
           width:100%;
       }
    }
}

.host-testimonials{
    
    li > p > span{
        display:inline-block;
        float:right;
        margin-bottom: 32px;
        margin-right: 24px;
        //text-align: right;
    };
}

// common for RTE contents view
.view-rte{
    
    p{
        margin-bottom: 0 !important;
    }
}


// sponsors
.row-sponsor {
    .box.bg{
        float:left;
        overflow: hidden;
        min-height:400px;
    }
    
    .box.cc-row .left-img{
        padding-right: .937rem;
    }
    
    .box.cc-row .right-desc{
        padding-left: auto;
    }
    
}