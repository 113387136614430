/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
//
// top-bar mega-menus mtsoft extension
//
//$topbar-margin-bottom:              0;
//$global-width:                         
//$topbar-megamenu-bg:                #efefef !default;
//$topbar-megamenu-border:            0 !default;
$topbar-megamenu-min-height:        310px !default;

.megamenus {

    /*margin: {
        top: -$topbar-margin-bottom; // move up to place mega-menu directly below top-bar
    }*/ 
    //max-width: $global-width - $column-gutter;

    .megamenu{

        // comment below to get megamenu nested on page (and pushing page contents down)
        position: absolute;

        // fit on grid
        display: none;
        left: auto;
        float: left;
        z-index: 40;
        //max-width: $global-width; //  - $column-gutter ? 
        box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.4);
        
        @include breakpoint(small) {

            max-width: $global-width - rem-calc(map-get($grid-column-gutter, small));
        }

        @include breakpoint(medium) {

            max-width: $global-width - rem-calc(map-get($grid-column-gutter, medium));
        } 

        
        width: 100%;
        

        // mega-menu contents outline styling (at least background required)
        //& > div{

            //width:100%;
            //min-height: $topbar-megamenu-min-height;
            @extend .top-bar; 
            //background: $topbar-megamenu-bg;
            //border: $topbar-megamenu-border;
            //border-radius: $theme-default-border-radius-small;
            // ...
            //padding: 0;
            /*@include larger-than(small) {
                //@include box-shadow($theme-header-box-shadow);
                box-shadow: $theme-header-box-shadow;
            }*/
        //}
        padding: 0; 
        margin-top: map-get($grid-column-gutter, small);    // distance from "Back" button
        
        @include breakpoint(small) {
            margin-top: 0;
        }

        // if screen width equal or less then whole grid width
        @media only screen and (max-width: $global-width + rem-calc(map-get($grid-column-gutter, medium)) - 1) {

            // correct to fit on grid
            width: auto;
            //left: $column-gutter/2;
            //right: $column-gutter/2;[element=megamenu_channels param1=value1 param2=value2]
            left:  0;
            right: 0;

        }

        a{
            display: inline-block;            
        }
    }
}

//
// foundation top-bar 
//

// re-define top-bar menu items
.top-bar-section .has-megamenu {

    //@extend .has-dropdown;  // show right mark (>) - indicating child drop-down menu
    @extend .is-dropdown-submenu-parent;  // show right mark (>) - indicating child drop-down menu
}
@media only screen and (max-width: $topbar-unstack-breakpoint - 1) { // mobile view

    // don't show right mark (>) - indicating child drop-down menu
    .top-bar-section .has-megamenu > a:after{

        content: "";
        border: none;
    }
    .top-bar-section{

        .m-item-active{
            //color: $theme-box-title-color !important; 
        }
    }
}
// menu item ( which triggers megamenu ) hover class
// ( should be equal to those defined for top-bar top leel menu item )
.top-bar-section {

    li.has-megamenu-hover{

        & > a:not(.button){

            //color: $topbar-link-color-hover;
            //background: $topbar-dropdown-bg;
        }
    }
}
// top-bar fix for foundation 5
/*.top-bar-section ul li > a{
    padding: 11px 1rem 11px 1rem;
}
@media only screen and (min-width: 940px) { 
    .top-bar-section .dropdown li a {
        line-height: normal;
    }
}*/
