// 
// Player widget default skin 
// 
$player-main-bg:               #4E0D5E !default;
$player-controls-color:        #fff !default;

.widget-player.default{

    
    .pub-date{
        display: inline-block;
        font-size: 0.82em;
        font-weight: normal;
        margin-left: .7em;
        padding: 0 6px;
        background: lighten(#0C3F96, 40%);
        color: #fff;
        border-radius: 3px;
    } 
    
    font: 16px "Open Sans", Arial, Verdana;
    background-color: $player-main-bg;
    //border-radius: 5px;
    overflow: hidden;

    // header 
    .header{
        background: $player-main-bg url(../../../player/img/default/banner.jpg) left top repeat-x;
        box-shadow: 0 0 8px 3px rgba(0, 0, 0, 0.45);

        // right side of header
        .listen-now{
            background: lighten($player-main-bg, 15%) url(../../../player/img/default/listen-now.png) center center no-repeat;
            @include box-emboss();
            @include transition(all .5s);

            &:hover{                
                background-color: lighten($player-main-bg, 25%);
            }
        }
    }
    
    
    // host thumbnail image 
    img.th{
        width: 81px;
        height: 102px;
        margin-right: 5px;
        border-radius: 5px;
        border: 0;
        background-color: #E4CBF5;
        
        // if no image - hide 
        &[src=""] {
            visibility: hidden;
        }
    }
    
                                                    
    // episode title
    h1{
        color: #0C3F96;
        //font-weight: bold;
        font-size: 0.88em;
        line-height: 1.4em;
        margin: 0;
        padding: 0;
    }

    
    .list{
        background: lighten($player-main-bg, 15%);
        overflow: hidden;
                
        .scroller {
            margin-top: 5px;            
            overflow: hidden;
            
            ul {        
                display: inline-block;
                padding: 0 5px;
                margin-left: 0;
                
                li {
                    
                    margin-bottom: 5px;
                    border-radius: 3px;

                    // backgorund 
                    background-color: #d6ccf2;
                    $experimental-support-for-svg: true;
                    @include background-image(linear-gradient(top,  #d6ccf2 0%,#ffffff 45%,#ffffff 55%,#c1d0ff 100%));
                    @include filter-gradient(#d6ccf2, #c1d0ff, vertical);                    
                  
                    
                    & > div{ 
                        
                        // epsidoe title
                        h1 {
                           //cursor: pointer; 
                           
                           // episode date
                           b{
                               @extend .pub-date;                               
                           }
                        }
                        
                        // episode description
                        p{
                            color: darken(#0C3F96, 20%); 
                            font-size: 0.76em;
                            margin-bottom: 0;
                            //overflow: hidden;
                            //white-space: nowrap;
                            //text-overflow: ellipsis;   
                        }
                    }
                    
                    // 'play" button
                    a.play{
                        
                        display: inline-block;
                        width: 48px;
                        height: 48px;
                        float: right;
                        margin: 28px 20px 0 0;
                        opacity: 0.2;
                        background: {
                            image:  url(../../../player/img/default/play-alt.png);
                            position: center center;
                            repeat: no-repeat;
                        }
                        @include transition(all .5s);
                        
                        &:focus{
                            opacity: 1;
                        }
                    }
                    
                    &:hover{
                        
                        a.play{
                            opacity: 1;
                        }
                    }
                }
            }
        }
        
        .message{
            background: $player-main-bg;
        }
    }
    
    
    // iScroller
    .scroller, .cover{
        
        position: absolute;
        z-index: 1;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        width: 100%;
        -webkit-transform: translateZ(0);
        -moz-transform: translateZ(0);
        -ms-transform: translateZ(0);
        -o-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-text-size-adjust: none;
        -moz-text-size-adjust: none;
        -ms-text-size-adjust: none;
        -o-text-size-adjust: none;
        text-size-adjust: none;
    }
    
    //
    // custom iScroll scroll-bars
    //
    .iScrollVerticalScrollbar {
        position: absolute;
        z-index: 9999;
        width: 12px;
        bottom: 6px;
        top: 6px;
        right: 6px;
        overflow: hidden;
    }

    .iScrollVerticalScrollbar.iScrollBothScrollbars {
        bottom: 18px;
    }

    .iScrollIndicator {
        position: absolute;
        background: $player-main-bg;
        border-radius: 4px;
        opacity: 0.4;
        //border-width: 1px;
        //border-style: solid;
        //border-color: #EB97B4 #7C2845 #7C2845 #EB97B4;
        //border-radius: 8px;
    }

    .iScrollVerticalScrollbar .iScrollIndicator {
        width: 100%;
        height: 68px;            
    }

    //
    // Episode cover 
    //
    .cover-out{
        
        background-color: lighten($player-main-bg, 15%);
        
        .cover{
            
            div{
                position: relative; 
                margin: 0 5px;
                padding: 5px 20px 5px 100px;
                height:100%;
                border-radius: 3px;
                min-height: 228px;
                //padding-left: 42px;

                // backgorund 
                background-color: #d6ccf2;
                @include filter-gradient(#d6ccf2, #c1d0ff, vertical);
                $experimental-support-for-svg: true;
                @include background-image(linear-gradient(top,  #d6ccf2 0%,#ffffff 45%,#ffffff 55%,#c1d0ff 100%));

                //img, h1, p{
                    //float: left;
                //}
                
                h1{
                    //float: right;
                    //margin-right: 91;                    
                    font-size: 1.1em;
                    span{
                        position: relative;
                        top: 10px;
                    }
                }
                img.th{
                    float: left;
                    margin: 5px 10px 5px 0;                    
                }
                
                p{
                    font-size: .82em;                    
                    &.dt{
                        
                        @extend .pub-date;
                        font-size: .88em;
                        margin-left: 0;
                        margin-bottom: 0;
                    }
                }
                a{
                    font-size: 1em;
                }
                
                a.bck-episodes{
                    
                    position: absolute;;
                    left: 5px;
                    top: 5px;
                    display: inline-block;
                    margin: 5px;
                    width: 32px;
                    height: 32px;
                    padding-left: 42px; 
                    font-size: .66em;
                    color: lighten($player-main-bg, 25%);
                        
                    background: {
                        image:  url(../../../player/img/default/list-ul.png);
                        position: center center;
                        repeat: no-repeat;
                    }
                }
                

                &.cover-episode{}

                &.cover-live{

                    display: none; 

                    div.show-cover{
                        background-color: transparent;
                        background-repeat: no-repeat;                        
                        border-radius: 3px;
                        overflow: hidden;
                        max-width: 508px;
                        max-height: 210px;
                        padding: 0;
                    }
                }
            }
        }
    }
 

    .player{

        box-shadow: 0 0 8px 3px rgba(0, 0, 0, 0.45);


        //
        // media element player custom skin
        // 
        .mejs-container {}

        //
        // controls panel common 
        //
        .mejs-controls {
            background: $player-main-bg;
            height: $player-element-height-px;
        }
        .mejs-button, .mejs-time {
            position: absolute;
            background: $player-main-bg;
        }
        
                    
        //
        // time progress  
        //            
        .mejs-controls {
            
            
            .mejs-time-rail {
                
                position: absolute;
                bottom: 0;
                left: 0;
                padding: 0;
                height: 18px;
                
                .mejs-time-total {
                    //background-color: none;
                    //background: url(../img/mejs/controls-ted.png) repeat-x 0 -52px;
                    background: darken($player-main-bg, 5%);
                    height: 6px;
                    margin:  3px 8px 0 8px;
                }
                .mejs-time-buffering {
                    height: 6px;
                }
                .mejs-time-loaded {
                    //background-color: none;
                    //background: url(../img/mejs/controls-ted.png) repeat-x 0 -52px;
                    background: darken($player-main-bg, 10%);
                    width: 0;
                    height: 6px;
                }
                .mejs-time-current {
                    width: 0;
                    height: 6px;
                    //background-color: none;
                    //background: url(../img/mejs/controls-ted.png) repeat-x 0 -59px;
                    background: white;
                    
                    background-color: rgb(43,194,83);
                    background-image: -webkit-gradient(
                      linear,
                      left bottom,
                      left top,
                      color-stop(0, rgb(43,194,83)),
                      color-stop(1, rgb(84,240,84))
                     );
                    background-image: -webkit-linear-gradient(
                      center bottom,
                      rgb(43,194,83) 37%,
                      rgb(84,240,84) 69%
                     );
                    background-image: -moz-linear-gradient(
                      center bottom,
                      rgb(43,194,83) 37%,
                      rgb(84,240,84) 69%
                     );
                    background-image: -ms-linear-gradient(
                      center bottom,
                      rgb(43,194,83) 37%,
                      rgb(84,240,84) 69%
                     );
                    background-image: -o-linear-gradient(
                      center bottom,
                      rgb(43,194,83) 37%,
                      rgb(84,240,84) 69%
                     );
                    -webkit-box-shadow: 
                      inset 0 2px 9px  rgba(255,255,255,0.3),
                      inset 0 -2px 6px rgba(0,0,0,0.4);
                    -moz-box-shadow: 
                      inset 0 2px 9px  rgba(255,255,255,0.3),
                      inset 0 -2px 6px rgba(0,0,0,0.4);
                    position: relative;
                    overflow: hidden;
                    
                    &:after{
                        
                            content: "";
                            position: absolute;
                            top: 0; left: 0; bottom: 0; right: 0;
                            background-image: 
                               -webkit-gradient(linear, 0 0, 100% 100%, 
                                  color-stop(.25, rgba(255, 255, 255, .2)), 
                                  color-stop(.25, transparent), color-stop(.5, transparent), 
                                  color-stop(.5, rgba(255, 255, 255, .2)), 
                                  color-stop(.75, rgba(255, 255, 255, .2)), 
                                  color-stop(.75, transparent), to(transparent)
                               );
                            background-image: 
                                    -webkit-linear-gradient(
                                      -45deg, 
                                  rgba(255, 255, 255, .2) 25%, 
                                  transparent 25%, 
                                  transparent 50%, 
                                  rgba(255, 255, 255, .2) 50%, 
                                  rgba(255, 255, 255, .2) 75%, 
                                  transparent 75%, 
                                  transparent
                               );
                            background-image: 
                                    -moz-linear-gradient(
                                      -45deg, 
                                  rgba(255, 255, 255, .2) 25%, 
                                  transparent 25%, 
                                  transparent 50%, 
                                  rgba(255, 255, 255, .2) 50%, 
                                  rgba(255, 255, 255, .2) 75%, 
                                  transparent 75%, 
                                  transparent
                               );
                            background-image: 
                                    -ms-linear-gradient(
                                      -45deg, 
                                  rgba(255, 255, 255, .2) 25%, 
                                  transparent 25%, 
                                  transparent 50%, 
                                  rgba(255, 255, 255, .2) 50%, 
                                  rgba(255, 255, 255, .2) 75%, 
                                  transparent 75%, 
                                  transparent
                               );
                            background-image: 
                                    -o-linear-gradient(
                                      -45deg, 
                                  rgba(255, 255, 255, .2) 25%, 
                                  transparent 25%, 
                                  transparent 50%, 
                                  rgba(255, 255, 255, .2) 50%, 
                                  rgba(255, 255, 255, .2) 75%, 
                                  transparent 75%, 
                                  transparent
                               );
                            z-index: 1;
                            -webkit-background-size: 50px 50px;
                            -moz-background-size:    50px 50px;
                            background-size:         50px 50px;
                            overflow: hidden;
                            -webkit-animation: move 2s linear infinite;

                    }
                }
                .mejs-time-handle {
                    display: none;
                    //display: block;
                    //margin: 0;
                    //width: 14px;
                    //height: 21px;
                    //top: -7px;
                    //border: 0;
                    //background: url(../img/mejs/controls-ted.png) no-repeat 0 0;
                }
                .mejs-time-float {
                    display: none;
                }
            }
            
            //
            // play/pause  button
            //
            .mejs-playpause-button {
                top: 10px;
                left: 8px;
                width: 32px;
                height: 32px;
                button {
                    width: 32px;
                    height: 32px;
                    background: {
                        color: $player-main-bg;
                        //image: url(../../../player/img/default/mejs/play.png);
                        image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAA1UlEQVRYhe3UIU+CYRiFYYIzGwwWg5vBaXYzu5klM8kUg8XsphWqGzMQ2MyS2bT6C8huBIJTIsFwGeTboDDh5eMp7/0HzpVOpZLL5ZYIh7jBThTgyV+fuIoAvJnvGReRABjiHgdRgKJX1CMB8IM2zqIARQPcYjcKUNRDNRIA32jhJApQNJR6YImAEfYiABM84jRpfEVAH7Xk4RUAH7jD/trGlwB0cb7W4X8C3tHAdinjCwBfaOK4tOEFgBdclj48A+hMh8e4xtbGxqeAIzxIPZRcLjfTL/nEDdmtLoUGAAAAAElFTkSuQmCC");
                        position: center center;
                        repeat: no-repeat;
                    }
                    margin: 0;
                    padding: 0;
                    border: none;
                }
            }
            .mejs-pause button {
                //background-image: url(../../../player/img/default/mejs/pause.png);
                background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAqUlEQVRYhe3WoRHCYBAFYUREREQEBSCRiBRACUgkEkkHSIpApgQKQCApBoGIQHwImCEiPwMEw3Crd+5WvsEgCF4AGUZv+CPk33hcYIfGjTO2T/wNTne3wR5Fn4C5biYd7jjhLvoErBJHZx3uNOGuIyACIiACIiACIiACfjogNcmqDjc1yZZ9AgocWscuqJEl/NpjwMIRw48DWodLVF6Y2sgxQdn7cfAXXAFiVqQeQiuTewAAAABJRU5ErkJggg==");
            }       
            
            //
            // current/total time 
            //
            .mejs-time {
                
                left: 54px;
                top: 10px;                
                vertical-align: middle;
                
                &, span{
                    height: $player-element-height-px *.5;
                    line-height: $player-element-height-px *.5;
                    padding: 0;
                    font-size: 16px;
                }
                
                span {
                    
                    color: $player-controls-color;                    
                    padding: 0 5px;
                    display: none;
                    
                    &.mejs-currenttime, &.mejs-duration{
                        display: inline-block;
                        padding: 0;
                        font-weight: bold;
                    }
                    &.mejs-currenttime{                        
                    }
                    &.mejs-duration{
                        margin-left: 5px;
                        //color: #DB8BEE;
                        //font-size: 1.5em;
                        &:before{
                            content: "/ ";
                        }
                    }
                }
            }
            
            //
            // volume button 
            //
            .mejs-volume-button {
                top: 8px;
                right: 136px;
                width: 36px;
                height: 32px;
            }
            .mejs-mute button {
                //background: url(../../../player/img/default/mejs/volume2.png) no-repeat left center;
                background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAgCAYAAAB6kdqOAAAB4UlEQVRYhe2XoXLCQBCGEREVEQhEBQKBQEQgKpCViD4AogJRUYFAVOAQfQAEAlFRgahAIBCIisgKREVFBaIPgKiIQER8FdlMN5kEkpAUZsrORNyf3c13c3t7l1LpbP/NAANoA2bKOBO4zBumBth4tgX6CeMMYAm8AkZeMF3AIWhuwlgLcCVmeChIBZgTYyny9P1JAM2sMDfAJg5mF5BM5EUvkZrYPC2ICUx2gSQAmonLWGl1tXRN7XwNDKMeef+VBGYPUFe5NZU+Fm3qC519H0gKEwYitK2BhbjNlVYXbeMLbwUC2cCtGleBb3GtK30tWmvvx7ICAQ2RHMBS+qPoDxHLNigMSOKeRX5S2pVoy4j6mhQNVBb5PaQDrNW4LdqsUCBVHy5wobQN4KixJeH2XwB9nBrQllNZMuBOZN2dj1PUeMeNI4/uOf62HygtsO0/iwCSuAXQUeMKv4dzbGPsFQhUjgAEsJUWPDpE7LD7cPXbfSqgEIyeuD5cR6JN42KjklXxrpyHAK3ERRf4Jd4uDECmAeupBGmBLLwl0xe0qYSlu6CFEjfUbBMDReSJvB9lhTLwaszNAoRXAv4PwuggmFDiFsG2MUkY5/8GrcjrN0glN4F7VF9JGFcGarnCnO0Y9gN6+LedOW/esQAAAABJRU5ErkJggg==") no-repeat left center;
                width: 36px;
                height: 32px;
                margin: 0;
                padding: 0;
            }
            .mejs-unmute button {
                //background: url(../../../player/img/default/mejs/volume3.png) no-repeat -1px center;
                background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAABGElEQVRYhe3WIUsEQRiH8QuHnN1gNBpNahAMGgzGC34Ao4iCUTDc5xCbxQ9jU0xGg8Fw4cKFn2HHu72F1WWPfQfk/jBlGOZ5mPfd2en1VvkPQR/HGOSAb+NZkTEuIuE3mFjMJAJ8iEc16RK8hku81ME7E8Au7n8DLyWAM9zVjA18NoG3EsDVH3tuNYW3FXjPJtBw89YCOMdp7VyXAoremaYxrJT7K+oEhiWJh7RkjIMQgYrEIjxQoPyVzcoRVYIf+BgjlZ6IasLZsZuXI6wJT7BfO6d4THx0JdAouM0qkCTy/YwaCu6ZXyTxAkliHdd4yyJQEjnCUzaBJNFXNO+0wu/+UVoR2cFrSWAUKpAkBoobbjMcvsqy+QYWEEnHPxHlxQAAAABJRU5ErkJggg==") no-repeat -1px center;
                width: 36px;
                height: 32px;
                margin: 0;
                padding: 0;
            }
            
            //
            // volume slider
            //
            .mejs-horizontal-volume-slider{
                position: absolute;
                top: 8px;
                right: 15px;
                width: 110px;
                height: $player-element-height-px;
                
                .mejs-horizontal-volume-total, .mejs-horizontal-volume-current{
                    width: 110px;
                    height: 9px;
                }
                .mejs-horizontal-volume-total{
                    background: darken($player-main-bg, 10%);
                }
                .mejs-horizontal-volume-current{
                    background: $player-controls-color;                    
                }
            }
            // current time tooltip
            .mejs-time-float{
                visibility: hidden; // hide!
            }

            .mejs-time span {
                color: $player-controls-color;
            }            
            .mejs-captions-button {
                display: none;
            }
        }
    }


}

@-webkit-keyframes move {
    0% {
       background-position: 0 0;
    }
    100% {
       background-position: 50px 50px;
    }
}
