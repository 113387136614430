//
// icons set definition 
//
$prefix: 'a';

.#{$prefix}icons {
    display: inline-block;
    width: 32px;
    height: 32px;
    // common location: /webroot/icons/ ...
    // set relative path to /theme/{Themename}/theme.css to:
    // ../../../icons/{SetName}/sprites/sprites.svg
    background-image: url(../../../icons/alerts/sprites.svg); 
    background-color: transparent;
    background-repeat: no-repeat;
}

//
// icons
//
// NOTE: icon colors must be set in sprites.svg as paths tags fill attribute values 
// ex. <path fill="#000000"...>
.#{$prefix}icons{
    // colored 
    &.ico-success {
        background-position: -304px 0;
        width: 32px;
    }
    &.ico-failed {
        background-position: -218px 0;
        width: 32px;
    }
    &.ico-caution {
        background-position: 0 0;
        width: 32px;
    }
    &.ico-help {
        background-position: -132px 0;
        width: 32px;
    }
    &.ico-info {
        background-position: -175px 0;
        width: 32px;
    }
    &.ico-close {
        background-position: -91px 0;
        width: 25px;
    }
    &.ico-remove {
        background-position: 0 0;
        width: 32px;
    }
    &.ico-flag {
        background-position: -390px 0;
        width: 32px;
    }
    &.ico-hourglass {
        background-position: -438px 0;
        width: 32px;
    }
    &.ico-bell {
        background-position: -486px 0;
        width: 32px;
    }
    &.ico-plus {
        background-position: -261px 0;
        width: 27px;
    }
    &.ico-minus {
        background-position: -347px 0;
        width: 27px;
    }
    // white 
    &.ico-success-white {
        background-position: -304px -32px;
        width: 32px;
    }
    &.ico-failed-white {
        background-position: -218px -32px;
        width: 32px;
    }
    &.ico-caution-white {
        background-position: 0 -32px;
        width: 32px;
    }
    &.ico-help-white {
        background-position: -132px -32px;
        width: 32px;
    }
    &.ico-info-white {
        background-position: -175px -32px;
        width: 32px;
    }
    &.ico-close-white {
        background-position: -91px -32px;
        width: 25px;
    }
    &.ico-remove-white {
        background-position: 0 -32px;
        width: 32px;
    }
    &.ico-flag-white {
        background-position: -390px -32px;
        width: 32px;
    }
    &.ico-hourglass-white {
        background-position: -438px -32px;
        width: 32px;
    }
    &.ico-bell-white {
        background-position: -486px -32px;
        width: 32px;
    }
    &.ico-plus-white {
        background-position: -261px -32px;
        width: 27px;
    }
    &.ico-minus-white {
        background-position: -347px 0;
        width: 27px;
    }    
}