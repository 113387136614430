// common 
$theme-btn-bg:      #000 !default;

// facebook login button
button, .button{
    
    &.facebook{
        
        width: 100%;
        background-color: #3A5795;
        
        &, &:hover, &:visited{
            color: #FFF;
            fill: #FFF; 
            text-decoration: none;
        }
        font-size: 1.5rem;
        /*/letter-spacing: +.1rem;*/
        padding-top: .55rem;
        padding-bottom: .55rem;
        i.ico{
            margin-top: -5px;
            width: 24px;
            height: 24px;
        }
        
        &:hover{
            background-color: darken(#3A5795, 15%);
        }
    }
}


// popup LOGIN or SIGNUP
#popLoginOrSignup{
    
    input[type="text"]#SigninUsername {
        
        padding-left: 42px;
        margin-bottom: $form-spacing/3;
    }
    input[type="password"]#SigninPassword, #phantom_SigninPassword {

        padding-left: 42px;
        margin-bottom: 0;
    }
    
    #btnLogin{
        width:100%;
    }       
    
    //.login-actions{

        //width: 100%;
        /*a, label{
            font-size: .82rem;
        }
        label[for="UserRememberMe"]{
            margin-right: 0;
            font-weight: normal;
        }
        input[type="checkbox"]:checked ~ label{
            font-weight: normal;
        }
        .checkbox{
            float: left;
        }
        .forgot-link{
            float: right;
            line-height: 3.2rem;
        }*/
    /*.checkbox.custom{

        > input, >div{
            margin: 0;
            top: 0;
        }
        label[for="UserRememberMe"]{
            margin-top: 0;
        }   
    }*/
    //}
    /*.login-signup{

        p{
            margin: 1.5rem 0 0 0;
            text-align: center;
        }
    } */       
}


// popup SIGN UP
#popSignup{
    
    fieldset{
        
        //padding-bottom: 0;
        
        input[type="text"],  input[type="email"], input[type="password"]{
                
            margin-bottom: $form-spacing/3; 
        }
        #UserSignupPasswordConfirmed{
            margin-bottom: 0;
        }
        .mtf-passmeter{
            
            top: -7px;
        }
     
        // first & last name
        div.floated, div.el{
            padding-right: 0;
            width: 100%; 
        }
        #btnSignupSubmit{
            width:100%;
        }
    }
    
    #btnSignupSubmit{

        //margin-bottom: 1.5rem ;
    }    
}

// popup RESET PASSWORD
#popResetPassword{
    
    input[type="email"]{
                
        margin-bottom: $form-spacing/3; 
    }
    .mtf-actions {
        
        padding-top: 0;
        
        > div{
            display: block;
            width: 100%;
        }
    }
    #ResetPasswordBtnSend{
        width:100%;
    }
}

#popSignup, #popLoginOrSignup, #popResetPassword{
    
    // SIGN IN button    
    .btn-signup-out > div > div,
    .btn-login-out > div > div,
    .btn-reset-out > div > div{
        display: block;
        width:100%; 
    }
    
    .login-signup, .login-signin, .login-reset-password{
        margin-top: $form-spacing * 1.5;
        
        p{
            font-size: .82rem;
            margin: 0;
            color: #666;
            a{
                font-size: 1rem;
                color: $theme-btn-bg;
                
                &:hover{
                    text-decoration: underline;
                }
            }
        }
    }
    
}