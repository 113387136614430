#pprevHosts, #pprevGuests{
    margin: 0;
}
.pprevEndTimeOut{
    display:none;
}
// live show start time
h2.pprevStartTimeOut{ 
    
    margin-bottom: 0;
    
        i.ico{
        top: -4px;
    }
}
// show type label
.show-type{

    float: right;
    display: inline-block;
    vertical-align: middle;

    font-size: .8rem; 
    font-weight: normal;
    border-radius: 2px;
    padding: 0 map-get($grid-column-gutter, small)/4;
    // position
    //margin-left: $theme-default-padding*2;
    margin-top: 0px;
    //letter-spacing: +.2rem;

    &.live{

        //color: $theme-color
        background-color: $theme-bg-color-live;
    }
    &.replay{

        //color: $theme-color
        background-color: $theme-bg-color-tag;
    }
}
// live show progress-bar
#pprevProgress{

    //background: darken($theme-color-base, 15%);
    //border-radius: $theme-default-border-radius-small;
    overflow: hidden; 
    > div{
        width: 0;
        height: 10px;
        //background: $theme-color-link-light;
        //border-radius: $theme-default-border-radius-small;
    }
} 
// banner
#pprevBannerPlayer{

    position: relative;    

    #pprevBanner, #pprevPlayerBtn{
        
        //position: absolute;
        display:inline-block;
        top:0;
        left:0;
        width: 100%; 
        height: 100%;        
    }
    
    
    #pprevPlayerBtn{
            
        position: absolute;
        opacity: .3;
        
        > i.ico{       
            display: block;
            width: 75%;
            height: 75%;
            top: 11%;
            left: 14%;
            //fill: $theme-color-link-light;
            color: #fff; 
        }
        
        &:hover{
            
            background: #000;
            opacity: .5;

            > i.ico{
                //fill: $theme-color-link-light;
                //opacity: 1;
                color: #fff;
            }
        }
    }

    #pprevBanner{
        //max-width: 500px;
        //max-height: 200px; 
        //display: none;

        img{
            width: 100%;
            ///border-top-left-radius: $theme-default-border-radius-small;
            //border-top-right-radius: $theme-default-border-radius-small;
        }            
    }
    
    
    img{
        width: 100%;
        ///border-top-left-radius: $theme-default-border-radius-small;
        //border-top-right-radius: $theme-default-border-radius-small;
    }
    
}
.pprev-bottom{
    padding-top: $theme-distance-small;
}