/*
mtsoft Universal popup.
Shwos various types of popups (popup, dialog, dialog modal, wait modal, form, etc...)
*/   

// use compass
//@import "../../../libs/compass/css3";

//
// variables (applies to default theme)
//  
$mt-popup-css-prefix:           'mtp-' !default;   
$theme-color-base:              #666;

$mt-popup-overlay-bg:           #000 !default;
$mt-popup-overlay-opacity:      0.5 !default;

$mt-popup-default-padding:      20px !default;
$mt-popup-max-width:            640px !default;
$mt-popup-bg:                   #fff !default;
$mt-popup-border:               3px solid rgba(0, 0, 0, 0) !default;
$mt-popup-border-radius:        3px !default;
$mt-popup-box-shadow:           0 0 18px rgba(0, 0, 0, 0.4) !default;
$mt-popup-header-color:         $theme-color-base !default; //#fff
$mt-popup-header-bg:            #FFF !default; //#848484
$mt-popup-header-font-size:     1.18em !default;
$mt-popup-buttons-bg:           #EFEFEF !default;

$mt-popup-progressbar-bg:       #999 !default;
$mt-popup-progressbar-height:   5px !default;
$mt-popup-progressbar-opacity:  0.2 !default;
$mt-popup-bar-bg:               darken($mt-popup-progressbar-bg, 30%) !default;
$mt-popup-bar-opacity:          1 !default;

$mt-popup-close-btn-color:      lighten(#666, 50%) !default;    //#EBEBEB
$mt-popup-close-btn-opacity:    0.6 !default;

// different dialog types title backgrounds
//$mt-popup-icons-set:            aicons !default; // use alerts icons set (_alerts-icons.scss);
//$mt-popup-default-ico:          ico-flag-white !default;
$mt-popup-info-bg:              #3A87AD !default;
//$mt-popup-info-ico:             ico-info-white !default;
$mt-popup-success-bg:           #468847 !default;
//$mt-popup-success-ico:          ico-success-white !default;
$mt-popup-caution-bg:           #FA6900 !default;
//$mt-popup-caution-ico:          ico-caution-white !default;
$mt-popup-failed-bg:            #B94A48 !default;
//$mt-popup-failed-ico:           ico-failed-white !default;
$mt-popup-question-bg:          #FA6900 !default;
//$mt-popup-question-ico:         ico-help-white !default;
$mt-popup-wait-bg:              #FA6900 !default;
//$mt-popup-wait-ico:             ico-hourglass-white !default;

// ajax content in popup
$mt-popup-ajax-loading-color:   #EBEBEB !default;

// forma in popup
$mt-popup-fieldset-padding:     10px !default;

$mt-popup-input-color:          #666 !default;
$mt-popup-input-bg :            #ECECEC !default;
$mt-popup-btn-color:            #fff !default;
$mt-popup-btn-bg:               #000 !default;
$mt-popup-link-color:          #0088cc !default;

// button popup style
@mixin mt-popup-btn() {

    outline: none;
    cursor: pointer;

    &:active:not(:disabled){

        @include box-emboss(.3, .2);
        @include scale( .96 );
    }
    &:hover{

        @include transition(background 0.3s);    
    }
}




//
// Common, base popup styles
//
.#{$mt-popup-css-prefix}popup{

    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    width: 96%;        
    height: auto;
    max-width: $mt-popup-max-width;    
    z-index: 2000;   

    &, p, h1, h2, h3, span{
        color: $theme-color-base !important;
    }
    a, a:link, a:visited, a:active{
        color: $mt-popup-link-color !important;
        text-decoration: none;
    }
    a:hover {
        text-decoration: underline;
    }

    //visibility: hidden;    
    //@include translate(-50%, -50%);
    @include vendor-prefix(backface-visibility, hidden);

    @include larger-than(tiny) {
        top: 36%;
        width: 80%;        
        min-width: 320px;        
    }

    // re-size transition
    &.#{$mt-popup-css-prefix}trans-resize{
        @include transition(all .3s);
    }

    & > .#{$mt-popup-css-prefix}content{

        //float: left;
        width: 100%;        
        height: 100%;   
        min-height: 72px; // minimum popup height (if no contents) 
        //overflow-x: auto;
        overflow: hidden;
        background: $mt-popup-bg;

        .#{$mt-popup-css-prefix}header{

            padding: $mt-popup-default-padding/2 $mt-popup-default-padding/2 $mt-popup-default-padding/2 $mt-popup-default-padding;
            -moz-user-select: none; -webkit-user-select: none; -ms-user-select:none; user-select:none;

            .#{$mt-popup-css-prefix}title{

                float:left;

                & > h1 {

                    margin: 0;

                    & > i, & > span{
                        display: inline-block;
                        vertical-align: middle;
                        padding: auto 0;
                    }
                    // below is required to vertical align title text when no icon present
                    /*& > i:not(.ico){
                        width:0;
                        &:after{
                            content: "\a0";
                        }
                    }*/
                }
            }
        }

        .#{$mt-popup-css-prefix}body{

            padding: $mt-popup-default-padding;            
            //min-height: 90px;

        }
    }

    // top (header) buttons
    .#{$mt-popup-css-prefix}head-buttons{

        float: right;

        //position: relative;        

        // single top (right) button
        .#{$mt-popup-css-prefix}btn{

            //padding: 1px 3px 0 3px;
            padding: 0;

            @include mt-popup-btn();

            // close button
            &.#{$mt-popup-css-prefix}btn-close{

                i {
                    margin: 0;
                    color: $mt-popup-header-color !important;                    
                }
                /*& > i {                    
                    //@extend .aico-remove;
                    position: relative;                        
                    top: -1px;
                }*/
            }
        }

        // view in absolute position and above any content
        &.#{$mt-popup-css-prefix}abs{
            position:absolute;
            right: 2px;
            top: 1px;
            z-index: 2;
        }

    }

    // bottom buttons panel
    .#{$mt-popup-css-prefix}buttons{

        width: 100%;
        padding: $mt-popup-default-padding/1.5;
        padding-bottom: 0;
        -moz-user-select: none; -webkit-user-select: none; -ms-user-select:none; user-select:none;

        & > div{ // make button centered
                 text-align: center;
        }

        button {

            white-space: nowrap;
            text-decoration: none;
            vertical-align: middle;
            outline: none; 

            height: auto;
            line-height: normal; 

            padding: .3em .7em;
            margin: 0 .8em $mt-popup-default-padding/1.5 0;

            @include mt-popup-btn();
        }
    }

    //
    // timeout progress bar
    //
    .#{$mt-popup-css-prefix}progress{

        position: absolute;
        left: 2px;
        top: 2px;
        right: 2px;
        height: $mt-popup-progressbar-height;

        background: $mt-popup-progressbar-bg;
        opacity: $mt-popup-progressbar-opacity;
        border-radius: 1px;

        .#{$mt-popup-css-prefix}bar{

            height: $mt-popup-progressbar-height;
            border-radius: 1px;
            width: 0%;
            height: $mt-popup-progressbar-height;
            background: $mt-popup-bar-bg;
            opacity: $mt-popup-bar-opacity;
        }
    }

    //
    // jQuery UI resizable (handle style)
    //
    .ui-resizable {
        position: relative;
    }
    .ui-resizable-handle {
        position: absolute;
        font-size: 0.1px;
        display: block;
    }
    .ui-resizable-disabled .ui-resizable-handle,
    .ui-resizable-autohide .ui-resizable-handle {
        display: none;
    }
    .ui-resizable-n {
        cursor: n-resize;
        height: 7px;
        width: 100%;
        top: -5px;
        left: 0;
    }
    .ui-resizable-s {
        cursor: s-resize;
        height: 7px;
        width: 100%;
        bottom: -5px;
        left: 0;
    }
    .ui-resizable-e {
        cursor: e-resize;
        width: 7px;
        right: -5px;
        top: 0;
        height: 100%;
    }
    .ui-resizable-w {
        cursor: w-resize;
        width: 7px;
        left: -5px;
        top: 0;
        height: 100%;
    }
    .ui-resizable-se {
        cursor: se-resize;
        width: 12px;
        height: 12px;
        right: 1px;
        bottom: 1px;
    }
    .ui-resizable-sw {
        cursor: sw-resize;
        width: 9px;
        height: 9px;
        left: -5px;
        bottom: -5px;
    }
    .ui-resizable-nw {
        cursor: nw-resize;
        width: 9px;
        height: 9px;
        left: -5px;
        top: -5px;
    }
    .ui-resizable-ne {
        cursor: ne-resize;
        width: 9px;
        height: 9px;
        right: -5px;
        top: -5px;
    }

    //
    // declare available themes
    //
    &.#{$mt-popup-css-prefix}default{

        @extend %theme-default;
    }

}

.#{$mt-popup-css-prefix}popup.#{$mt-popup-css-prefix}forma{

    & > .#{$mt-popup-css-prefix}content{
        //overflow: visible;
    }

    .#{$mt-popup-css-prefix}abs {

        .#{$mt-popup-css-prefix}btn-close{

            color: #D2D2D2;

            &:hover{

                color: darken(#D2D2D2, 10%);
            }
        }
    }

    form, .forma {
        padding-top: $mt-popup-fieldset-padding*4.5;
        margin-bottom: 0;

        fieldset{
            float: left;
            width: 100%;
            margin: $mt-popup-fieldset-padding/2;
            padding-top: 1rem;
            
            &:first-of-type{
                margin-top: 0;
            }
            
            .input-out > div:first-of-type, .input-out > div:first-of-type{
                margin-top: 0;
            }
        }
    }

    .#{$mt-popup-css-prefix}body{

        // fit nicely on popup body 
        padding: 0;
        form, .forma {
            padding-top: 0;
            
            & > fieldset{
                border: 0;
                margin: 0;
                
                & > .row.input-out:first-of-type{
                    border-top: 0; 
                    
                    & > div:first-of-type{
                        //margin-top: 0;     
                    }
                }
                
                input[type="text"], input[type="password"], input[type="email"]{
                    
                    background: $mt-popup-input-bg;
                    color: $mt-popup-header-color;
                    fill: $mt-popup-header-color;
                    
                    &::-webkit-input-placeholder, 
                    &:-moz-placeholder, 
                    &::-moz-placeholder, 
                    &:-ms-input-placeholder {
                
                        color: darken($mt-popup-input-bg, 50%);
                    }
                    
                    
                    &:focus{
                        
                        background: lighten($mt-popup-input-bg, 5%);
                        border-bottom: 2px solid darken($mt-popup-input-bg, 30%);
                    }
                }
                
                .checkbox.custom input[type="checkbox"] ~ label,
                .checkbox.custom input[type="checkbox"]:checked ~ label{
                    color: $mt-popup-header-color;
                }
            }
            .in-prefix, .in-postfix{                
                color: $mt-popup-header-color;
                fill: $mt-popup-header-color;
            }
            .in-prefix{
                position: absolute;
                top: 5px;
                left: 0;
            }
            .mtf-actions{
                margin-top: 0;
                
                button{
                    margin-bottom: .5rem;
                    border-radius: $mt-popup-border-radius;
                    color: $mt-popup-bg;
                    background: $mt-popup-link-color;
                    padding: .75rem 1rem;
                    &, span{
                        color: $mt-popup-btn-color !important; 
                        font-size: 1.5rem;                        
                    }

                    &:hover{
                        background: darken($mt-popup-link-color, 20%);
                    }
                }
            }
        }
    }
    
}

// overlay style
.#{$mt-popup-css-prefix}overlay{

    position: fixed;
    top: 0; 
    left: 0;
    width: 100%;
    height: 100%;    
    z-index: 1000;

    // no animate
    opacity: $mt-popup-overlay-opacity;
    background: $mt-popup-overlay-bg;

    // animate
    visibility: hidden;            
    //opacity: 0;
    //@include transition(all 0.3s);
}







//
// Themes
//

// default
%theme-default{

    border: $mt-popup-border;
    border-radius: $mt-popup-border-radius;
    box-shadow: $mt-popup-box-shadow;

    //@include vendor-prefix(transition, height .3s ease-in-out);

    //
    // header buttons container
    //
    .#{$mt-popup-css-prefix}head-buttons{

        // top header button (including 'close" button)
        .#{$mt-popup-css-prefix}btn{

            //font: 2.4em 'alerts-icons';
            border-radius: $mt-popup-border-radius;

            i.ico, svg{
                fill: $mt-popup-close-btn-color;
                width: 36px;
                height: 36px;
            } 

            &:hover{
                //ackground: darken($mt-popup-header-bg, 1%);
                i.ico, svg{
                    fill: darken($mt-popup-close-btn-color, 20%);
                }
            }
        }

        // close button
        .#{$mt-popup-css-prefix}btn-close{

            //& > i {}
        }

        // for light background
        &.#{$mt-popup-css-prefix}light{

            margin: $mt-popup-default-padding/3;
            color: $mt-popup-close-btn-color;

            .#{$mt-popup-css-prefix}btn{

                opacity: $mt-popup-close-btn-opacity;

                &:hover{
                    opacity: 1;
                    //color: darken($mt-popup-close-btn-color, 10%);
                    //background: none;
                }
            }
        }

    }

    //
    // real content
    //
    & > .#{$mt-popup-css-prefix}content{
          
        float: left;
        border-radius: $mt-popup-border-radius;
        //color: $mt-popup-header-color;

        //
        // popup header
        //
        .#{$mt-popup-css-prefix}header{

            color: $mt-popup-header-color;
            background: $mt-popup-header-bg;
            //font-weight: bold;
            border-top-left-radius: $mt-popup-border-radius;
            border-top-right-radius: $mt-popup-border-radius;                        

            //
            // header title
            //
            .#{$mt-popup-css-prefix}title{

                & > h1 {

                    
                    &, span{
                        color: $mt-popup-header-color !important;
                        font-size: $mt-popup-header-font-size !important;
                        //font-family: $theme-headers-font-family;
                        font-style: normal;
                        font-size: 2rem;
                        line-height: normal;
                    }
                    

                    & > i, & > i > svg{    
                        //@include scale( 1.6 );
                        width: 3rem;
                        height: 3rem;
                        margin-right: .5rem;
                        //fill: $mt-popup-header-color;
                        fill: $mt-popup-header-color;
                        //min-width: 52px;
                        //font-size: 1.8em;
                    }

                    font-style: italic;
                }
            }

            @include larger-than(tiny) {
                font-size: 1.2em;
            }
        }

        //
        // body with content
        //
        .#{$mt-popup-css-prefix}body{}

        //
        // popup buttons panel (bottom)
        //
        .#{$mt-popup-css-prefix}buttons{

            background: $mt-popup-buttons-bg;
            border-bottom-left-radius: $mt-popup-border-radius;
            border-bottom-right-radius: $mt-popup-border-radius;             

            //
            // popup action button
            //
            button {

                color: #666;
                border-radius: $mt-popup-border-radius;
                box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
                //@include background(linear-gradient(top, #FFFFFF, #D0D0D0));

                //border-style: solid;
                //border-width: 1px;
                //border-bottom: 1px solid #D0D0D0;
                
                background: rgb(255,255,255); /* Old browsers */
                /* IE9 SVG, needs conditional override of 'filter' to 'none' */
                background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNlNWU1ZTUiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
                background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(229,229,229,1) 100%); /* FF3.6+ */
                background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,255,255,1)), color-stop(100%,rgba(229,229,229,1))); /* Chrome,Safari4+ */
                background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(229,229,229,1) 100%); /* Chrome10+,Safari5.1+ */
                background: -o-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(229,229,229,1) 100%); /* Opera 11.10+ */
                background: -ms-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(229,229,229,1) 100%); /* IE10+ */
                background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(229,229,229,1) 100%); /* W3C */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#e5e5e5',GradientType=0 ); /* IE6-8 */

                

                border-style: solid;                
                border-width: 1px;                
                border-bottom: 1px solid #D0D0D0;

                text-shadow:1px 1px 0 #FFFFFF;
                font-weight: normal;
            
                // indicate default button
                &.#{$mt-popup-css-prefix}btn-default{

                    padding: .66em 2.8em;
                    font-weight: bold;
                }

                &:hover{
                    color: darken(#666, 10%);                    
                    //@include background(linear-gradient(top, #FFFFFF, #E4E4E4));
                }
                &:focus {

                    //border-width: 1px;
                    border-color:  #999;
                    border-style: dotted;
                    color: #333;
                }
            }
        }
    }

    // default popup icon
    .#{$mt-popup-css-prefix}header{

        & > .#{$mt-popup-css-prefix}title > h1 > i.default{
            //@extend .#{$mt-popup-default-ico};
        }
    }

    //
    // popup dialog types
    //
    &.info{
        .#{$mt-popup-css-prefix}header{
            background: $mt-popup-info-bg;
            & > .#{$mt-popup-css-prefix}title > h1 > i{
                //@extend .#{$mt-popup-icons-set};
                //@extend .#{$mt-popup-info-ico};
            }
        }
    }

    &.success{
        .#{$mt-popup-css-prefix}header{
            background: $mt-popup-success-bg;
            & > .#{$mt-popup-css-prefix}title > h1 > i{
                //@extend .#{$mt-popup-icons-set};
                //@extend .#{$mt-popup-success-ico};
            }
        }
    }
    &.caution{
        .#{$mt-popup-css-prefix}header{
            background: $mt-popup-caution-bg;
            & > .#{$mt-popup-css-prefix}title > h1 > i{
                //@extend .#{$mt-popup-icons-set};
                //@extend .#{$mt-popup-caution-ico};                                
            }
        }
    }
    &.failed{
        .#{$mt-popup-css-prefix}header{
            background: $mt-popup-failed-bg;
            & > .#{$mt-popup-css-prefix}title > h1 > i{
                //@extend .#{$mt-popup-icons-set};
                //@extend .#{$mt-popup-failed-ico};                                
            }
        }
    }
    &.question{
        .#{$mt-popup-css-prefix}header{
            background: $mt-popup-question-bg;
            & > .#{$mt-popup-css-prefix}title > h1 > i{
                //@extend .#{$mt-popup-icons-set};
                //@extend .#{$mt-popup-question-ico};                                
            }
        }
    }
    &.wait{
        .#{$mt-popup-css-prefix}header{
            background: $mt-popup-wait-bg;
            & > .#{$mt-popup-css-prefix}title > h1 > i{
                //@extend .#{$mt-popup-icons-set};
                //@extend .#{$mt-popup-wait-ico};                                
            }
        }
        .#{$mt-popup-css-prefix}body{

            text-align: center;
            .ico-wait{
                //font-size:5.4em;color: #ebebeb;
                fill: #ebebeb;
                width: 6rem;
                height: 6rem;
            }
        }
    }

    // ajax loaded content
    // loading "wait" icon
    .ajax-loading{

        position: relative;
        top: 36%;
        text-align: center;
        padding: $mt-popup-default-padding*2 0;

        color: darken($mt-popup-ajax-loading-color, 0%);

        font-size: 1.5rem;
        font-weight: bolder;
        letter-spacing: +.1rem;

        .ico-wait{
            //font-size:5.4em;color: #ebebeb;
            fill: #ebebeb;
            width: 4rem;
            height: 4rem;
        }
    }
}


//
// Popup animations
//

/* overlay animation */
.anim.overlay-on{

    @include vendor-prefix(animation-name, overlay-on);
    @include vendor-prefix(animation-duration, 0.3s);    
}
@include keyframes(overlay-on) {

    from { opacity: 0; }
    to { opacity: $mt-popup-overlay-opacity; }
}

/* Fade in and scale */
/*
.anim.fade-and-scale{

    @include vendor-prefix(animation-name, fade-and-scale-in);
    @include vendor-prefix(animation-duration, 0.4s);
}
@include keyframes(fade-and-scale-in) {

    from { @include vendor-prefix(transform, scale(0.7)); opacity: 0; }
    to { @include vendor-prefix(transform, scale(1)); opacity: 1; }
}
*/

/* Fade in and scale */
.anim.fade-and-scale {
  animation-name: fade-and-scale-in;
  -webkit-animation-name: fade-and-scale-in;
  -moz-animation-name: fade-and-scale-in;
  -ms-animation-name: fade-and-scale-in;
  -o-animation-name: fade-and-scale-in;
  
  animation-duration: .3s;
  -webkit-animation-duration: .3s;
  -moz-animation-duration: .3s;
  -ms-animation-duration: .3s;
  -o-animation-duration: .3s; 
  
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-timing-function: ease-in-out;
  -ms-animation-timing-function: ease-in-out;
  -o-animation-timing-function: ease-in-out; 
}

@-webkit-keyframes fade-and-scale-in {
  0% {
    transform: scale(.4);
    -webkit-transform: scale(.4);
    -moz-transform: scale(.4);
    -ms-transform: scale(.4);
    -o-transform: scale(.4);
    opacity: 0; }
    
  50% {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    opacity: 1; }
  
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    opacity: 1; } }

@-moz-keyframes fade-and-scale-in {
  0% {
    transform: scale(.4);
    -webkit-transform: scale(.4);
    -moz-transform: scale(.4);
    -ms-transform: scale(.4);
    -o-transform: scale(.4);
    opacity: 0; }
  
  50% {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    opacity: 1; }

  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    opacity: 1; } }

@-ms-keyframes fade-and-scale-in {
  0% {
    transform: scale(.4);
    -webkit-transform: scale(.4);
    -moz-transform: scale(.4);
    -ms-transform: scale(.4);
    -o-transform: scale(.4);
    opacity: 0; }

    50% {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    opacity: 1; }
  
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    opacity: 1; } }

@-o-keyframes fade-and-scale-in {
  0% {
    transform: scale(.4);
    -webkit-transform: scale(.4);
    -moz-transform: scale(.4);
    -ms-transform: scale(.4);
    -o-transform: scale(.4);
    opacity: 0; }
  
  50% {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    opacity: 1; }
  
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    opacity: 1; } }

@keyframes fade-and-scale-in {
  0% {
    transform: scale(.4);
    -webkit-transform: scale(.4);
    -moz-transform: scale(.4);
    -ms-transform: scale(.4);
    -o-transform: scale(.4);
    opacity: 0; }

    
  50% {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    opacity: 1; }
  
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    opacity: 1; } }
