//
// mtsoft svg icons
//

// -----------------------------------------------------------------------------
//
// variables
//


// -----------------------------------------------------------------------------
//
// styles 
//

// base, default svg icon formatting 
// (must be set to set initial size; otherwise base svg will be shown on view) 
/*svg[id^="ico-"] {
    width: 32px;
    height: 32px;
    position:absolute; 
    margin-left: -100%
}*/

i.ico{

    display: inline-block;   
    position: relative;
    margin: 4px;
    vertical-align: middle;
    text-align: center;
    font-size: 0;

    // define default size for any icon
    width: 32px;
    height: 32px; 
    
    //fill: inherit;
    color: inherit;

    // if image icon is smaller than required size - center horizontally and verticalyy (don't re-size') 
    i, img{
        display: inline-block;
        vertical-align: middle;
    }
    // required to center image verticaly 
    i{
        height: 100%;
    }

    svg{
        position: absolute;
        left: 0;
        top: 0;
        // fully fill container (required for FIrefox mainly)
        width: 100%;
        height:100%;
        
        //fill: inherit;       
        &, path, use{ // `use` for svg icon reference (ex. `<use xlink:href="#i-chevron-right"></use>`)
            color: inherit; 
            fill: currentColor;
            //fill: inherit;
        }
    }
}
/*
a .ico {
    
    color: inherit;
    fill: inherit;
    
    svg, path{
        color: inherit;
        fill: inherit;
    }
}
*/
