//
// links to social media accounts 
//
.social-media-urls{
    padding-left:0;
    &, li{
        
        list-style-type: none;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        
        a{
            //text-transform: capitalize;
            margin-left: .32rem;                        
        }
        i.ico{
            width: 21px;
            height: 21px;
        }
    }
}