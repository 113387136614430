/**
* Miniplayer styles 
*/

//
// definition
//
$miniplayer-bg: #000 !default;
$miniplayer-bg-lighter: lighten(#000, 20%) !default;
$miniplayer-color: darken(#fff, 25%) !default;
$miniplayer-color-hover: #fff !default;
$miniplayer-color-action: #b01ca0 !default; // #ff6d00
$miniplayer-radius: 5px !default;

.miniplayer {
    
    min-width: 110px;
    padding: 10px;
    /**
     * Layout
     */
     // top
    .mp-main-out{

        position: relative;
        height: 46px;
        &>* {
            position: absolute;
        }

        .mp-btns-actions{
            
            width: 95px;
        }

        .mp-volume-title-out{            
            
            left: 95px;
            right:0;

            .mp-volume{
                margin-top: 8px;
                float: left;                
                width: 30%;
            }
            .mp-info-title{
                float: left;                
                display: block;
                width: 70%;
            }
        }
    }
    // bottom 
    .mp-track-out {

        position: relative;
        height: 30px;
        & > * {
            position: absolute;
        }

        .mp-info-current,
        .mp-info-total {
            width: 35px;
            font-size: 11px;
            line-height: 32px;
        }
        .mp-info-current {
            left: 0;            
            text-align: left;
        }
        .mp-track {
            left: 35px;
            right: 35px;  
            margin-top: 2px;
        }
        .mp-info-total {
            right: 0;
            text-align: right;            
        }
    }


    /**
     * General styles 
     */
    background: $miniplayer-bg;
    border-radius: $miniplayer-radius;

    /**
     * Buttons
     */
     .mp-btns-actions {
        
        //padding: 10px 0 0 0;

        & > button {

            border-radius: 50%;            
            border: 2px solid $miniplayer-color;
            padding: 0.425rem .5rem;
            margin-bottom: 0;
            background: $miniplayer-bg;

            transition: background-color 0.25s ease-out, color 0.25s ease-out;
            transition: border-color 0.25s ease-out, color 0.25s ease-out;

            i.ico{
                color: $miniplayer-color;
            }        

            &:hover:enabled {

                //border-color: $miniplayer-color-hover;
                border-color: $miniplayer-color-action;
                background-color: $miniplayer-bg;
                i.ico {
                    color: $miniplayer-color-hover;
                }
            }

            // mute button slighty smaller 
            &.mp-btn-mute,
            &.mp-btn-unmute{
                margin-top: 0px; // correct center align
                padding: 0.225rem .3rem;
                i.ico {
                    top: -2px;
                    right: -1px;
                    width: 24px;
                    height: 24px;    
                }
            }

            // active buttons
            &.mp-btn-pause:enabled,
            &.mp-btn-unmute:enabled,
            &.mp-btn-buffering:enabled {
                background: $miniplayer-color;
                i.ico {
                    color: $miniplayer-bg;
                }

                &:hover {
                    background: $miniplayer-color-hover; 
                    border-color: $miniplayer-color-hover;
                }
            }
        }
    }

    /**
     * Title
     */
    .mp-info-title{
        line-height: 43px;
        font-size: 14px;
        //background: $miniplayer-bg-lighter;
        //border-radius: $miniplayer-radius;
    }
    // all text 
    .mp-info-title > div,
    .mp-info-current,
    .mp-info-total{
        color: $miniplayer-color;
    }

    /**
    * Marquee
    */
    div.mp-info-title {
        white-space: no-wrap;
        overflow: hidden;
        &>div {
            white-space: nowrap;
            display: inline;
            width: auto;
        }
    }    
    
    /**
     * Range slider with value highlighted on bar 
     */
    .range-slider {
        display: inline-block;
        width: 100%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        //padding-bottom: 15px;
        &>input {
            opacity: 0;
            width: 100%;
            position: relative;
            z-index: 5;
            //margin-top: 16px; // top distance / height 
            -webkit-appearance: none;
            &::-webkit-slider-thumb {
                -webkit-appearance: none;
                z-index: 100;
                position: relative;
                width: 30px;
                height: 30px;
                -webkit-border-radius: 10px;
            }
        }
        &>span.slider-container {
            display: inline-block;
            min-height: 30px;
            display: inline-block;
            position: absolute;
            //top: 16px; // bottom distance / height 
            top: 0;
            left: -8px;
            right: 36px;
            z-index: 3;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box; // bar
            &>span.bar {
                background-color: $miniplayer-bg-lighter;
                //background-color: $miniplayer-color-hover;                
                display: inline-block;
                position: absolute;
                z-index: 1;
                top: 12px;
                left: 20px;
                right: -15px;
                height: 4px;    // bar height
                overflow: hidden;
                border-radius: $miniplayer-radius;
                &>span {
                    background: $miniplayer-color;
                    display: inline-block;
                    float: left;
                    height: 4px; // bar height
                    width: 0%;
                }
            } // slider button (handle)
            &>span.bar-btn {
                //display: inline-block;
                display:none;               // initially hide bar button 
                position: absolute;
                width: 20px;
                height: 20px;
                &:after {
                    content: "";
                    //border: 2px solid #fff;
                    background-color: $miniplayer-color-hover;
                    border-radius: 20px;
                    width: 20px;
                    height: 20px;
                    display: inline-block;
                    position: absolute;
                    left: 10px;
                    top: 3px;
                    z-index: 3;
                    -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, .8);
                    -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, .8);
                    box-shadow: 0px 0px 10px rgba(0, 0, 0, .8);
                }
            }
        }

        &:hover {
            & > span.slider-container > span.bar > span {
                background-color: $miniplayer-color-action;
                transition: background-color 0.25s ease-out, color 0.25s ease-out;
            }
        }
    }
}