.zapbox{
    
    .tabs{
        padding: 0;
        border: 0;
        
        .tabs-title{
            width: 14.285%;
            text-align: center;
            a{                
                padding: {
                    left: 0;
                    right: 0;
                }
                
                &:hover{
                    text-decoration: none;
                }
            }
            h2{
                margin: 0;
            }
        }        
    }
    
    .tabs-content{
        
        background: transparent;
        border: 0;
        
        .tabs-panel{
            
            padding: {
                left: 0;
                right: 0;
            }
            @include breakpoint(small) {

                padding: {
                    top: $theme-distance-small;
                    boottom: $theme-distance-small;
                }
            }

            @include breakpoint(medium) {

                padding: {
                    top: $theme-distance-small;
                    boottom: $theme-distance-small;
                }
            }
        }
    }
    
    .btn-grid, .btn-list{
        margin-bottom: 0;
    }
    
    // single episdoe card 
    .episode{
        //height: 256px;
        //background-color: #000;  
        //position: relative;
        padding: 0;
        /*   
        @include breakpoint(small) {

            margin-bottom: $theme-distance-small;
        }

        @include breakpoint(medium) {

            margin-bottom: $theme-distance-medium;
        } 
        */
        
        img, .body{
            //clear: left;
            //float:left;    
        }

        img{            
            width:100%;
        }
        
        i.ico{
            
            width: 22px;
        }
        
        a{
            display: block;
            //float: left;            
            //width:100%;
            height:100%;
            &:hover{
                text-decoration: none;
            }
        }
        
        h2, h3{
            //display: inline-block;
            margin-top: 0;
            
            i.ico{
                width: 20px;
                height: 20px;
                margin-top: 1px;
            }
        }

        .body{
            
            padding: .5rem;
            //height: 100%;
            
            .time{
                //position: absolute;
                //top: auto;
                //bottom: 0;
                text-align: center;
                margin-bottom: 0;
                
            }

        }
    }
    
    // list mode
    &.list{
        
        .tabs-content{
            .columns{
                clear: left;
                width:100%;
            }
        }
        
        .episode{
            float:left;
            width:100%;
            
            img{
                width:40%;
            }
            
            a{
                float:left;
                width:100%;
            }
            
            .body{
                float:left;
                clear:none;
                width:60%;
                height:100%;
                
                h2.title{
                    //float:left;
                    display: inline-block;
                    width: 100%;
                    //height:100%;
                    //line-height:100%;
                    vertical-align: middle;
                    margin:0;
                }                
            }
            img, .body{
                float: left;
            }
            
            
            @include breakpoint(small only) {

                img, .body{
                    width: 100%;
                }
                .body, .body h2, .body h3{
                    clear: left;
                    width: 100%;
                }
            }
            
        }
    }
}