
[type='text'], textarea, [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color']
, textarea
,select
,[type='text']:focus, textarea:focus, [type='password']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='week']:focus, [type='email']:focus, [type='number']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='url']:focus, [type='color']:focus
, textarea:focus
, select:focus{
    
    border: 0;
    height:3.2rem;
    box-shadow: none;
}
select>option{
    color:inherit;
}
textarea{
    min-height:3.2rem;    
}