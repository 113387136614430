//
// themes
//
/*
<!-- ARIA properties are detailed on the second line, which are applied for accessibility purposes -->
<div id="qtip-{id}" class="qtip qtip-default qtip-pos-rc" style="z-index: 15001;" tracking="false"
        role="alert" aria-live="polite" aria-atomic="false" aria-describedby="qtip-0-content">

    <!-- content.title must be set for this to be generated -->
    <div class="qtip-titlebar">
         <!-- Only generated when content.button is set to true -->
         <!-- If content.title is unset, then this will be appended to the .qtip element, see below -->
        <a class="qtip-close qtip-icon" title="{content.button}" aria-label="{content.button}" role="button"> 
            <span class="ui-icon ui-icon-close">&cross;</span>
        </a>

        <!-- Houses the tooltip title -->
        <div id="qtip-{id}-title" class="qtip-title">{content.title}</div>
    </div>

    <!-- Only generated when content.button is set to true AND content.title is false -->
    <a class="qtip-close qtip-icon" title="{content.button}" aria-label="{content.button}" role="button"> 
        <span class="ui-icon ui-icon-close">&cross;</span>
    </a>

    <!-- Houses the tooltip content -->
    <div id="qtip-{id}-content" class="qtip-content ui-widget-content" aria-atomic="true">
        {content.text}
    </div>
</div>

*/

// on schedules calendar (this week, upcoming) 
.qtip-schedule{

    &.qtip{ 
        
        //background: darken($theme-color-base, 20%);
        //color: $theme-color-light;     
        background: #000;
        color: #fff;
        min-width: 318px;
        min-height: 239px;
        
        //background: #fff;
        //border-color: darken($theme-color-base, 20%);
        border-color: #000;
        //border: 0;
        
        
        //border-radius: $theme-default-border-radius-small;    
        border-radius: 3px;
        //box-shadow: $theme-box-shadow-default;
        box-shadow: 0px 0px 12px rgba(0,0,0,.8);
        
        
        .qtip-titlebar {
            //Instead of the line below you could use @includeborder-radius($radius, $vertical-radius)
            border-radius: 4px 4px 0 0;
        }
        
        .qtip-close {
            position: absolute;            
            right: 4px;
            top: 4px;
            z-index: 11;
            //cursor: pointer;
            outline: medium none;
            background: #000;
            border: none;
            //color: rgb(125,125,125);
            padding: 4px;
            padding-bottom: 6px;
            padding-top: 5px;
            //border-radius: $theme-default-border-radius-small;
            border-radius: 3px;
            
            color: #efefef;
            
            &:hover{
                text-decoration: none;
                color: #fff;
            }
            //border: 1px solid transparent;
        }
        .ui-icon.ui-icon-close{
            font-size: 20px;
        }
        
        .qtip-tip{
            //background: darken($theme-color-base, 20%);
        }
        
        
        //
        // content         
        //
        .cc-dt{
            display: inline-block;
            margin-left: -5px;
            //padding: $theme-box-padding*.5 0;
            .cc-date, .cc-time{
                
                font-size: .88rem;
                display: inline-block;
                padding-top: 5px;
                //color: $cc-box-color;
                //fill: $cc-box-color;
                color: #fff;
                
                i.ico{
                    width:20px;
                    height:20px;
                    margin-top: -1px;
                    margin-right: -2px;
                    
                }            
            }
            .cc-time{
                margin-left: 9px;
            }
            margin-bottom: 0;
        }
        h1{
            //color: $theme-box-title-color;
            //fill: $theme-box-title-color;
            font-size: 1.3rem;
            line-height: 1.4rem;
            font-weight: bold;
            margin: 0; //$theme-box-padding           
            //padding-bottom: $theme-box-padding*.5;
            color: #fff;
        }
        .cal-desc, .cal-guests{
            max-height: 10rem;
            overflow-y: auto;
            
            >p{
                font-size: .82rem;
                line-height: .96rem;
                margin-bottom: 0;
                //color: $cc-box-color;
                color: #fff;
            }
        }
        .cal-desc{
            >p{
                color: #BDBDBD;
            }
        }
        .cal-guests{
            
            > p{
                //padding-top: $theme-box-padding*.5;
                margin-bottom: 0;
                //color: lighten($cc-box-color, 10%);
                color: #fff;
            }
        }
        
        .btn-more {
            
            text-align: center;
            padding-top: 1rem;
            a.button{
                font-size: .82rem;
                //float:right;
                padding: .5rem 1rem;
                background: #fff;
                color: #000;
            }
        }

    } /* This one is applied by default (formally the "cream" style) */
}



