//
// Custom content list
//

// list header - search box
.cc-search-box{
    
    position: relative;
    
    form{
        position: relative;
        //margin-bottom: 0;
        
        .input.text{
            width: 100%;                      
            padding-right: 62px !important;
            
            > div{
                
                width: 100%;                      
                input{
                    float: right !important;  
                    margin-bottom: 0 !important;                    
                }
            }
        }
        
        button{
            margin: 0;
            position: absolute;
            //padding: .52rem 1.3rem;
            top: 0;
            right:0;
        }
        
    }
}
.fetch-content h1{
    margin: 0 !important;
}



// list single element
.box.cc-row{
    
    position: relative;
    padding: .5rem !important;
    
    h2{
        
        line-height: 1.5rem;
    }
    
    .cc-date .ico{
        top: -3px;
    }

    .cc-date{
        
        & + span.cc-time{
            margin-left: 1rem;
        }
    }
    
    .view-details{
        position: absolute;
        bottom: 0.5rem;
        right: 0.5rem;
        margin-bottom: 0;
    }
    
    .left-img{
        padding-right:0;
    }
    
    .right-desc {
        
        padding-left: 0;
        p{
            margin-top: 0;
        }
    }        
}

.cc-box{
    
    ul{
        padding-left:0;
        
        li{
            list-style: none;
            
            .button.box-more{
                width: 100%;
            }
        }
    }
}
// custom content listing
.list-box-row{
    
    .cc-row{
        img{
            width:100%;
        }
    }
}





//
// Custom content view
//
.box.cc-view{
    
    h1{
        margin: 0;
    }
    
    h2{
        >p:first-of-type{
            margin-top:0;
        }
    }
    
    .bottom-btns{
        text-align: center;
        
        button{
            margin-bottom: 0;
        }
    }
}