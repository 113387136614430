//
// foundation tabs 
//
.tabs {
    background: transparent;
    border: 0;
    padding-left: 0;
    .tabs-title {

        overflow: hidden;
        

        & >a {

            font-size: 100%;
            padding: 1.25rem 1rem;
            &:focus {
                background-color: transparent;
            }
            &:hover {
                text-decoration: none;
            } // default tab sheet
            h3,
            h2 {
                margin: 5px 0;
            } // big tab sheet and some, custom elements        
        }
    }

    &.tabs-right > li{
        float: right;
    }

}

.tabs-content {
    border: 0;
    &>.tabs-panel {
        overflow: hidden;
        & > p {
            margin: 0;
        }

        &>h2,
        &>form {
            padding-top: 15px;
        }

    }
}
.tabs-expanded{
    display: table;
    width:100%;

    .tabs.expanded {
        display: table-row;
        &>li {
            float: none;
            display: table-cell;
            a,
            h2,
            h3 {
                line-height: 100%;
            }
        }
    }
}

// vertical tabs
.tabs-vertical{
    display:table;
    width:100%;
    & > div{

        display:table-row;

        &:first-child{
            display:table-cell;
            width:25%
        }
        &:last-child{
            display:table-cell;
            width:75%
        }
    }

    &.right{

        & > div {

            &:first-child{            
                width:75%
            }
            &:last-child{            
                width:25%
            }
        }
    }
}