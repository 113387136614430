//
// forma
//
form{

    label{
        
        @include breakpoint(large) {
        
            text-align: right;        
        }
    }
}

.mtf-actions{
    
    text-align:center;  // make buttons centered 
    
    @include breakpoint(small) {

        padding: 0;
        padding-top: map-get($grid-column-gutter, small);
    }

    @include breakpoint(medium) {

        padding: 0;
        padding-top: map-get($grid-column-gutter, medium);    
    }
}