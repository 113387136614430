//
// widget player base style 
//
$player-max-width:                      500px !default; //100%
$player-full-min-height:                326px !default;
$player-element-height-px:              55px !default;  // check also /js/mtplayer.js > $.fn.mtPlayer.defaults.mediaElementPlayer.audioHeight (must be equal))
$player-default-border-radius-px:         3px !default;

.widget-player{
    
    > div{
        padding: 0;
    }
    
    position: relative;
    //margin: 7px;
    overflow: visible; // to show full PLAY button 
    @extend .box;
    
    // minmum normal size (with show/episode banner) 
    min-width: 266px;
    //min-height: $player-full-min-height;
    padding-bottom: $player-element-height-px; // place for player 
    margin-bottom:1rem;
    
    //max-width: $player-max-width;
    //height: 100%;
    
    border-radius: $player-default-border-radius-px;
    /*/overflow: hidden;*/
    
    // header
    .header{
        position: relative;
        height: $player-element-height-px;
        
        // right side of header (listen NOW! link)  
        .listen-now{
            display: inline-block;
            position: absolute;
            right: 5px;
            top: 5px;
            width: 128px;
            height: $player-element-height-px - 10px;
            border-radius: $player-default-border-radius-px;
        }
        
        z-index: 10001;
    }
    
    .list, .cover-out, .shares-bg, .shares{
        
        position: absolute;
        top: $player-element-height-px; // + 4px;
        //left: 4px;
        //right: 4px;
        left: 0;
        right: 0;
        bottom: $player-element-height-px;
        overflow: hidden;
        z-index: 1;
        margin-left: 0;
        
        @include transition(all .5s cubic-bezier(0.550, 0.055, 0.675, 0.190));
        //@include transition(all .5s ease-in);
        
        &.no-trans{
            @include transition(none);
        }
    }
    
    //list of episodes
    .list{
        
        display: none;
        
        .scroller {
            
            ul {

                list-style: none;
                padding: 0;
                margin: 0;
                width: 100%;
                text-align: left;

                li {                    
                    position: relative;
                    padding: 5px;
                    //height: 40px;
                    height: 112px;
                    vertical-align: middle;
                    
                    //line-height: 40px;
                    //border-bottom: 1px solid #ccc;
                    //border-top: 1px solid #fff;
                    //background-color: #fafafa;
                    //font-size: 14px;
                    
                    //img, h1, p, a{
                        //float: left;
                    //} 
                    
                    & > div{
                        position: absolute;
                        display: inline-block;
                        top: 10px;
                        left: 96px;                        
                        right: 91px; 
                        bottom: 10px;
                        overflow: hidden;
                        
                        & > span{
                            display: none;
                        }
                    }
                }
            }
        }
        
        .message{
            position: absolute;
            padding: 2 * 5px 4 * 5px;
            color: #fff;
            background: #000;
            opacity: 0.8;
            z-index: 99;
            border-radius: $player-default-border-radius-px;
            right: 4 * 5px;
            
            &.error{
                background: red;
            }            
        }
    }
    
    // episode cover
    .cover-out{
        display: none;
        margin-left: 100%;
        width: 100%;
            
        //
        // epsides cover
        //
        .cover{            
            // display: none;
            //background: red;
            width: 100%;
            //height: 100%;
            padding: 5px 0;
            //margin-left: 100%;
            z-index: 0;
            //@include transform(rotate(-135deg) skew(-10deg, -10deg))
        }
        
        &.shown{
            
            margin-left: 0;
            z-index: 2;  
            
            & ~ .list .scroller{
                opacity: 0.3;
            }

            & ~ .list ul{
                //margin-left: -1000px;
                //right: 0;
                //font-size: 3em;
                //display: none;
                //font-size: 3em;
            }
        }
    }
    
            
    //
    // share episodes screen
    //
    .shares-bg{
                
        right: auto;
        width: 0;
        background: #000; 
        opacity: 0;
        z-index: 3;
        @include transition(opacity .25s ease-out);
        
        &.shown{
            width: 100%;
            right: 0;
            zoom: 1;
            filter: alpha(opacity=80);
            opacity: 0.8;
        }
    }
    .shares{
        display: none;
        z-index: 4;
        text-align: center;
        vertical-align: middle;
        &.shown{
            display: block;
        }

        .c{
            height: 100%;
        }
        .c, .center{
            display: inline-block;
            vertical-align:middle;
        }
        .center{
            h1{
                font-size: 1.6em;
                padding-bottom: .5em;
                color: #fff;
            }
        }
        
        a{
            float: left;
            width: 46px;
            height: 46px;
            border: 0;
            padding: 0;
            margin: 0 20px 10px 0;
            cursor: pointer;
            background:{
                color: transparent;
                position: center center;
                repeat: no-repeat;
            }

            &.close{
                position: absolute;
                top: 15px;
                right: 5px;
                background-image: url(../../../player/img/default/x.png);
            }
            
            &.sh{
                
                border-radius: $player-default-border-radius-px;
                
                &.facebook{
                    background:{
                        color: #3B5999;
                        image: url(../../../player/img/default/facebook.png);
                    }
                }
                &.twitter{
                    background:{
                        color: #00ACED;
                        image: url(../../../player/img/default/twitter.png);
                    }
                }
                &.googleplus{
                    background:{
                        color: #D44332;
                        image: url(../../../player/img/default/googleplus.png);
                    }
                }
                &.email{
                    background:{
                        color: #00668D;
                        image: url(../../../player/img/default/envelope-alt.png);
                    }
                }
                &.embed{
                    background:{
                        color: #00668D;
                        image: url(../../../player/img/default/code.png);
                    }
                }
            }
        }
        
        // embed code container
        .embed-out {
            display: none;
            clear: both;
            float: left;
            
            p{
                font-size: .76em;
                color: #fff;
                margin: 0;
            }
                
            textarea{
                float: left;
                font: 12px arial, tahoma, verdana;                
                color: #666;
                min-width: 310px;
                min-height: 72px;
                border: 1psx solid white;
                background: white;
                opacity: 0.8;
                border-radius: $player-default-border-radius-px;
                margin: 0;
            }
        }
    }
    
    // player 
    .player{
        
        position: absolute;        
        left: 0;
        bottom: 0; 
        width: 100%;
        height: $player-element-height-px;
        z-index: 1;
        
        // additional player buttons
        & > .buttons{
            position: absolute;
            top: 9px;
            right: 200px; 
            z-index: 9;
            
            button{
                float: left;
                display: inline-block;
                
                margin-right: 10px;
                width:32px;
                height:32px;
                border: 0;
                padding: 0;
                
                background:{
                    color: transparent;
                    position: center center;
                    repeat: no-repeat;
                }
                
                cursor: pointer;
                
                // share
                &.share{
                    background-image: url(../../../player/img/default/paperplane.png);
                }
                
                // info
                &.info{
                    background-image: url(../../../player/img/default/info.png);                        
                }
            }
        }
        
        // audio element (shown if mediaelement not initialized)
        text-align: center; // center horizontally 
        audio{
            display:inline-block;
            vertical-align:middle;           
        }
        // center vertically
        & > i{
            
            display:inline-block;
            height:100%;
            vertical-align:middle;
        }
    }
    
    // player currently playing content related cover/banner 
    .cover-current{
        
        position: relative;
        //min-height: $player-full-min-height;
        border-top-left-radius: $player-default-border-radius-px;
        border-top-right-radius: $player-default-border-radius-px;
        overflow: hidden;
        
        .click2play, .new-tab, img.primary, img.secondary{
            position: absolute;
            left: 0;
            top: 0;
            display: none;
        }
        img{
            //display: none; 
            width: 100%;
        }
        
        &.clickable{
            
            cursor: pointer;
            
            .click2play{
                display: none;
                padding: 35px 0;
                width: 100%;
                //height:100%;
                text-align: center;
                background-color: #000;
                //opacity: 0;
                //@include transition(all 1s);
                
                svg{                         
                    fill: $player-controls-color;
                }
            }
            
            &:hover{
                //opacity: .5;
                .click2play{
                    display: block;
                    opacity: .7;
                }
            }
        }
            
        // open player in new tab 
        a.new-tab{
            display: block;
            left: auto;
            right: 10px;
            top: 10px;
            width: 42px;
            height: 42px;
            background: {
                color: #000;
                //image: url(../../../player/img/live/newtab.png);
                image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAsUlEQVRYhe3UwRGCMBCFYUqiGAqwBDtICSnGgizBAjj8XnLIYAyB7GNh9F2zk/exMAzDP1+CONcBWGxz172HA4C7N4Ac4QVgbRAIPR9bN2ArQgLYgpABWhFSQJp7eG7gBjxdAC3lMgAwAfOia6bwOswBlfIpnUcZgPLaX3z+xoM5ABhrT164Lyo2EFvK83lTQIZYLa+g+gDpbNxTbgboyfUAqpwfcHhOAxAkeAPaET+ZNzvB8kJ3imMDAAAAAElFTkSuQmCC");
                repeat: no-repeat;
                position: center center;
            }
            opacity: .5;
            border-radius: $player-default-border-radius-px;
        }
    }
    
    //
    // different sizes 
    //
    &.mini{
        min-height: $player-element-height-px;
    }
}