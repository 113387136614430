/*
 * jQuery FlexSlider v2.0
 * http://www.woothemes.com/flexslider/
 *
 * Copyright 2012 WooThemes
 * Free to use under the GPLv2 license.
 * http://www.gnu.org/licenses/gpl-2.0.html
 *
 * Contributing author: Tyler Smith (@mbmufffin)
 */

/* Browser Resets */

.flex-container a:active, .flexslider a:active, .flex-container a:focus, .flexslider a:focus {
    outline: none;
}

.slides, .flex-control-nav, .flex-direction-nav {
    margin: 0;
    padding: 0;
    list-style: none;
}

/* FlexSlider Necessary Styles
*********************************/

.flexslider {
    margin: 0;
    padding: 0;
    .slides {
        > li {
            display: none;
            -webkit-backface-visibility: hidden;
            position: relative;   // mtsoft fix for caption
        }
        img {
            width: 100%;
            display: block;
            //border-radius: 3px;
        }
    }
}

/* Hide the slides before the JS is loaded. Avoids image jumping */

.flex-pauseplay span {
    text-transform: capitalize;
}

/* Clearfix for the .slides element */

.slides:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
}

html[xmlns] .slides {
    display: block;
}

* html .slides {
    height: 1%;
}

/* No JavaScript Fallback */
/* If you are not using another script, such as Modernizr, make sure you
 * include js that eliminates this class on page load */

.no-js .slides > li:first-child {
    display: block;
}

/* FlexSlider Default Theme
*********************************/

.flexslider {
    margin: 0 0 60px;
    //background: #fff; // mtsoft - no background by default 
    // mtsoft: slider inner border  
    //border: 4px solid #fff;  
    position: relative;
    //border-radius: 3px; 
    /* // mtsoft mod
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    -o-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    */
    zoom: 1;
}

.flex-viewport {
    max-height: 2000px;
    /* below animates viewport on felxslider initialization */
    /*-webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    transition: all 1s ease;*/
}

.loading .flex-viewport {
    max-height: 300px;
}

.flexslider .slides {
    zoom: 1;
}

/*.carousel li {
  margin-right: 5px;
}*/

/* Direction Nav */

/*.flex-direction-nav {
  *height: 0;
  a {
    width: 30px;
    height: 30px;
    margin: -20px 0 0;
    display: block;
    background: url(../img/flexslider/bg_direction_nav.png) no-repeat 0 0;
    position: absolute;
    top: 50%;
    z-index: 10;
    cursor: pointer;
    text-indent: -9999px;
    opacity: 0;*/
/* below animates left/right buttons (moving from outside to inside) */
/*-webkit-transition: all .3s ease;*/
/*}
.flex-next {
  background-position: 100% 0;
  right: -36px;
}
.flex-prev {
  left: -36px;
}
}*/

/* left right nav buttons */
/*.flexslider:hover {
  .flex-next {
    opacity: 0.8;
    right: 3%;
  }
  .flex-prev {
    opacity: 0.8;
    left: 3%;
  }
  .flex-next:hover, .flex-prev:hover {
    opacity: 1;
  }
}*/

.flex-direction-nav .flex-disabled {
    //opacity: 0.3 !important;
    //filter: alpha(opacity = 30);
    cursor: default;
}

/* Control Nav */

.flex-control-nav {
    //display: none;    // mtsoft disabled 
    width: 100%;
    //position: absolute;

    // mtsoft 
    //bottom: 0px; // show above slides 
    //bottom: -40px;    // show below sildes; use .flex margin bottom to 60px be visible
    // mtsoft 
    text-align: center;
    vertical-align: middle;
    li {
        margin: 3px 10px;
        display: inline-block;
        zoom: 1;
        *display: inline;
        //height: 26px;
        //padding: 20px 0; // mtsoft mod            
        @include breakpoint(small) {

            padding: map-get($grid-column-gutter, small) 0;
        }

        @include breakpoint(medium) {

            padding: map-get($grid-column-gutter, medium) 0;    
        }
    }
}

/* Bottom slides "dots" */
.flex-control-paging li a {
    width: 20px;
    height: 20px;
    display: block;  
    //background: rgba(0, 0, 0, 0.5);
    background: #fff;
    cursor: pointer;
    text-indent: -9999px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    //box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    // on slide hover
    &:hover {
        border-radius: 50%; // mtsoft 
        //background: rgba(0, 0, 0, 0.7);
        //background: #efefef;
    }
    // active slide
    &.flex-active {    
        //width: 20px;
        //height: 20px;
        //background: rgba(0, 0, 0, 0.9);
        background: #000;
        cursor: default;
    }
}

.flex-control-thumbs {
    margin: 5px 0 0;
    position: static;
    overflow: hidden;
    li {
        width: 25%;
        float: left;
        margin: 0;
    }
    img {
        width: 100%;
        display: block;
        //opacity: .7;
        cursor: pointer;
        &:hover {
            //opacity: 1;
        }
    }
    .flex-active {
        //: 1;
        cursor: default;
    }
}

@media screen and (max-width: 860px) {
    .flex-direction-nav {
        .flex-prev {
            //opacity: 1;            
            left: 0;
        }
        .flex-next {
            //opacity: 1;
            right: 0;
        }
    }
}






// -----------------------------------------------------------------------------
//
// mtsoft customization
//
$nav-buttons-size-px: 64px;
$pauseplay-enlarge-px: 30px;

.flexslider-out{
    overflow: hidden; // to hide left/right navi buttons when no active
}
.flexslider{
    margin: 0; // don't make bottom space - navigation dots are above slides 

    &.no-border{
        border: 0;
    }
}

// Single image caption
.flex-caption {
    //width: 80%;
    padding: 2% 0;
    margin: 0;
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    //border-radius: 3px;
    background: rgba(0,0,0,0.62);
    //color: #fff;
    text-align: center;
    text-shadow: 0 -1px 0 rgba(0,0,0,0.3);
    //font-size: 0.88em;
    //line-height: 18px;
    /* for small screens */
    max-height: 25%;
    overflow: hidden;
}


// Play / Pause links/buttons custom styles
.flex-pauseplay{ // div

                 // a
                 .flex-play{}
                 .flex-pause{}
}

// brogress bar (time of showing single slide)
.flex-progressbar{

    position: absolute;
    left: 0;
    top: 0;
    height: 5px;
    width: 100%;
    z-index: 10;

    .flex-bar{
        width: 0;
        height: 5px;
        background: #999;
        //opacity: .6;
    }
}

// Common styline Prev/Next, Pause/Play, count Current/Total
.flex-prev, .flex-next, .flex-pauseplay, .flex-counter{

    position: absolute;
    left: 50%;
    top: 50%;
    //opacity: 0.5;

    color: white;
    //background: rgba(0,0,0,0.2);
    background: #000;
    padding: 10px 20px;
    line-height: normal;

    transition: all .25s ease;

    span{
        display: inline-block;
        line-height: $nav-buttons-size-px;
        //opacity: 0.4;
    }
    .flex-counter-current{
        font-weight: bold;
        white-space: nowrap;
    }

    /*i.ico{
        opacity: 0.5;
    }*/
}
// when bottom "dots" navigation - show left/right navi buttons higher
.control-nav .flex-direction-nav .flex-prev, 
.control-nav .flex-direction-nav .flex-prev:hover, 
.control-nav .flex-direction-nav .flex-next, 
.control-nav .flex-direction-nav .flex-next:hover{
    top: 42%;
}

// Prev/Next
.flex-direction-nav{

    left: 0;
    right: 0;

    .flex-prev, .flex-next{

        z-index: 2; // for slide to prevent hide navigation buttons above faded image

        &, &:hover{

            // position in the middle
            top: 50%;
            margin-top: -($nav-buttons-size-px/2);

            font:  {
                size: 2.2em;
                //weight: bold;
            }
            text-decoration: none;



            // make them round
            padding: 0px;
            width: $nav-buttons-size-px;
            height: $nav-buttons-size-px;
            //border-radius: 3px;
            border-radius: 50%;
            background: #000;

            // transitions
            //opacity: .3;

            //
        }

        &:hover{
            //opacity: .8;
        }
        i.ico{
            width: $nav-buttons-size-px;
            height: $nav-buttons-size-px;
        }

    }
    .flex-next {
        left: auto;
        right: 3%;
        margin-right: -38%;
        i.ico{
            top: -3px;
            left: -2px;
            fill: #fff;
            &:hover{
                fill: #fff;
            }
        }
        /*span{
            padding-left: 5px;
        }*/
    }
    .flex-prev {

        right: auto;
        left: 3%;
        margin-left: -38%;

        i.ico{
            top: -3px;
            left: -8px;
            fill: #fff;
            &:hover{
                fill: #fff;
            }
        }        
        /*span{
            padding-left: 2px;
        }*/
    }
}

// Pause / Play buttons
.flex-pauseplay {

    // position in the middle
    top: 50%;
    margin: {
        top: -(($nav-buttons-size-px + $pauseplay-enlarge-px)/2);
        left: -(($nav-buttons-size-px + $pauseplay-enlarge-px)/2);
    }
    width: $nav-buttons-size-px + $pauseplay-enlarge-px;
    height: $nav-buttons-size-px + $pauseplay-enlarge-px;
    border-radius: 3px;

    span{
        line-height: $nav-buttons-size-px;
        font-size: 2.5em;
        margin: {
            top: $pauseplay-enlarge-px/5;
            left: 1px;
        }

    }
}

// Counter current / total
.flex-counter{
    padding: 0px 15px;
    top: -12%;
    left: 10px;
    border-radius: 3px;
}

// on mouse over slider
.flexslider:hover{

    // prev/next buttons
    .flex-prev{
        margin-left: 0;
    }
    .flex-next{
        margin-right: 0;
    }
    // slides counter
    .flex-counter {
        top: 10px;
    }
    // all controls opacity
    .flex-pauseplay, .flex-prev, .flex-next, .flex-counter, .flex-counter *{

        //opacity: 0.5;
    }
    .flex-pauseplay:hover, .flex-next:hover, .flex-prev:hover, .flex-counter:hover {

        //opacity: 1;
        span {
            //opacity: 1;
        }
    }
}



//
// Themes / features sets
//
/*.default{
    .flex-control-paging{
        display: none;
    }
}*/
/*.bottom-navi{
    .flex-control-paging{
        display: block;
    }
}*/
.carousel {
    
    // show prev/next button BELOW items at navigation paging belt
    .flex-direction-nav a, .flex-direction-nav a:hover{
        
        top: auto !important;

        @include breakpoint(small) {

            bottom: 8px;  
        }

        @include breakpoint(medium) {

            bottom: 18px;         //3%;
        }
        
        
        &, i.ico{
            width: 50px;
            height: 50px; 
        }
    }
    
    li {

    /*@include breakpoint(small) {

        margin-right: map-get($grid-column-gutter, small);
    }

    @include breakpoint(medium) {

        margin-right: map-get($grid-column-gutter, medium);
    }*/
    /*
    &:first-of-type{
        margin-left: 0;
    }*/
    /*&:last-of-type{
        margin-right: 0;
    }*/
    //margin-right: 0;
    }
}

//
// Box overrides
//
.box.no-padding{
        
    .flexslider .slides {
        .image {
            
            
            img{
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }
}
/*.box{
        
    .flexslider .slides img{
        border-radius: 0;
    }
}
*/
/*.box:not(.no-padding){
        
    .flexslider:last-child .flex-control-nav li{ // if slider inside box
        //padding-bottom: 0;
    }
}*/
