nav.breadcrumbs{
    
    display: block;
    
    ul{
        
        display: inline-block;
        margin:0;
        width: 100%;
        padding-left: 5px; //$theme-distance-small/2;
        
        //position: relative;
        //margin:0;
        //bottom: -.75rem;
        li{
            float: left;
            list-style: none;
            //height: 40px;
            //line-height: 40px;

            &:first-of-type {
                //padding-left: 5px;
            }

            span{ 
                display: inline-block;
                //position: relative;
                //top: -1px;                
                //padding: 0 .45rem;
            }

            a{
                //line-height: 2.4rem;
                i.ico{
                    width: 18px;
                    height: 18px;
                    top: -3px;
                }
                
                &:hover{
                    //text-decoration: underline;
                }
            }
 
            span, a{
                //font-family: $theme-headers-font-family;
                //font-size: 1.4rem;
                //color: $theme-color-link-light;
                //fill: $theme-color-link-light;
                //padding: 1rem;
                //padding-right: 0;
                display: inline-block;
                line-height: 40px;
                margin-right:.5rem;
            }
        }
    }
}