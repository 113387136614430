// remove left distance on menu items ul

.top-bar {
    
    padding: 0;
    z-index: 999;
    ul{
       padding-left: 0;
       
       li{
            //padding: .5rem 0;
            
        }
    }
    
    a{        
        white-space: nowrap;
        
        &:hover{
            text-decoration: none; 
        }
    }
    
    .menu > li > a{
        padding: 1rem 1.5rem 1rem;
    }
    
    //.menu.vertical.submenu{
        //margin-top:8px; // don't overlap top menu with drop-down sub-menu
    //}
    
    .is-dropdown-submenu{
        border: none; // don't border sub-menus
        box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.4);
    }
    
}
.top-bar-title{ // top-bar title with hamburger icon padding
    
    // make top-bar smaller on small screens
    @include breakpoint(small) {
        //padding: 0;
        
        padding-left: 16px;   // make left side menu icon distance 

        i.ico {
            width: 24px;
            height: 24px;
        }
    }
    
    // hide for medium up
    @include breakpoint(medium) {
        display: none;
    }
}

// prevent top bar FOUC on page load 
//.no-js {
@include breakpoint(small only) {

    .top-bar-body {
        display: none;
    }
}
.top-bar-right{
    margin-right:1rem;  // make distance from right top bar edge
}
//}
.no-ui { // check $.mtsoft.ui.loaded()
    
    .top-bar{   

        @include breakpoint(medium) {
            height: 78px;
            overflow: hidden;
        }
    }
    
    .top-bar-body{
        display: none;
    }
}
// handle situation when top-bar is not on a sticky element
//div:not(.el-sticky) {
/*.box {

    .top-bar{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 999;
    }
}*/