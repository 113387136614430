// 
// Player widget default skin 
// 
$player-main-bg:                #4E0D5E !default;
$player-controls-color:         #fff !default;
$player-element-height-px:      55px;   // must be set on mtplayer.js or Player Helper 
$player-full-min-height:        $player-element-height-px; //249px;
$player-playing-btn-bg:         #900605 !default;
$player-play-btn-size:          68px !default;

.widget-player.live{
    
    //min-height: 0;
    //margin: $column-gutter*.5 0; 
    
    .player{

        //box-shadow: 0 0 8px 3px rgba(0, 0, 0, 0.45);
        height: $player-element-height-px;        
        
                
        .mejs-offscreen{
            
            display: none;
        }
        
        //
        // media element player custom skin
        // 
        .mejs-container {
            
            background: none;
            
            .mejs-mediaelement{
                background: $player-main-bg;
                border-radius: $player-default-border-radius-px;
                
                // when can not paly - download link is shown 
                .me-cannotplay span{
                    padding: 18px;
                }
                .me-cannotplay a{
                    font-weight: normal;
                    
                    &:hover{
                        
                    }
                }
            }
        }

        //
        // controls panel common 
        //
        .mejs-controls {
            background: $player-main-bg;
            height: $player-element-height-px;
            
            border-bottom-left-radius: $player-default-border-radius-px;
            border-bottom-right-radius: $player-default-border-radius-px;
        }
        .mejs-button, .mejs-time {
            position: absolute;
            background: $player-main-bg;
        }
        
                    
        //
        // time progress  
        //            
        .mejs-controls {
            
            
            .mejs-time-rail {
                
                display: none;
            }
            
            //
            // play/pause  button
            //
            .mejs-playpause-button {
                top: -6px;
                left: 24px;
                width: $player-play-btn-size;
                height: $player-play-btn-size;                 
                border-radius: 50%;                
                background-color: darken($player-main-bg, 15%);
                //z-index: 97; // to be above error messages 
                
                button {
                    width: $player-play-btn-size;
                    height: $player-play-btn-size;
                    background: {
                        color: darken($player-main-bg, 15%);
                        //image: url(../../../player/img/live/mejs/play.png);
                        image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAABXUlEQVRoge3XoUtdYRzH4SMMNGxgGFjmEBxo2OCCbSAIpsGCYJkwMJgEwbSwtGQwGWRpwXAxmRZMJmGwYDIJWrYkrGzJIvIYDkeEe3c553rv+XHwff6C74fDe3jfLEuSJEmSxw4v8B5j0Vv6ggu5S3yM3lMJZnU6wqvobaWg1SUAfuELJqM39tQj4P7XWIne+V8lAuAKXzEXvbdDyYDCKTbxLHr3nYoBhQO8i96eZVnfAeS/3C1MNzWgcIzVJgfADb7hbVMDCmf4hOdNDSh8x1JdAQtDCIBr7OJpUwMKe00PaDc5oG3YB9pwDnF9F8ABB/yWX8Ff1jJ+wAH7WKxt+AADTrCO0drHPzDgH3bwJmT4AwMOsRw6vFAx4ByfMRG9+06FgD3MR+/tUCLgB9YwEr21qx4Bf7CNmeiNPeF1l/E/0YreVpr8fQt/sYEn0ZsqwRQ+YDx6S5IkSZI0yi2lf8lwqPgHrAAAAABJRU5ErkJggg==");
                        position: 8px center;
                        repeat: no-repeat;
                    }         
                    border-radius: 50%;
                    margin: 0;
                    padding: 0;
                    border: none;
                    box-shadow: none;
                    
                    @include transition(background-color .5s);
                                    
                    &.pulse{

                        @include vendor-prefix(animation-name, player-pulse);
                        @include vendor-prefix(animation-duration, 1s);
                        @include vendor-prefix(animation-fill-mode, both);
                        @include vendor-prefix(animation-iteration-count, infinite);
                    }
                                    
                    &.playing{
                        background-color: $player-playing-btn-bg;
                    }
                }
            }
            .mejs-pause button {
                background: {
                    //image: url(../../../player/img/live/mejs/pause.png);
                    image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAqCAYAAADFw8lbAAAAz0lEQVRYhe3PIU5DURRF0YqKioo6EhQJCZphNCE4EhoUAoGsZA4dQAUCUckgKjoIRkCCrUAvzBOI/kvFqyHnJM/9nbv+aJRlWTY4zLHGW3vPmBzRTfD0q1tjfirkyuHtKmxD7gbaVW/kGT4HjsF90d4U3R6XPaEXxTFYFu3jH+11oIEGGmiggQYaaKCBBhpooIEGelLoOb6KYw9Fe1t037jqBm0HNwPHPjAtumn75tA2XZHt4Bh3eMUW73jB7Ih2hmVrtu2nFxh3h2ZZ9k/2A1Jlz9Y8pMzDAAAAAElFTkSuQmCC");
                    position: 13px center; 
                }
            }       
            
            //
            // current/total time 
            //
            .mejs-time {
                
                left: 98px; //84px;
                top: 14px;                
                vertical-align: middle;
                
                &, span{
                    height: $player-element-height-px *.5;
                    line-height: $player-element-height-px *.5;
                    padding: 0;
                    font-size: 16px;
                    font-family: arial;
                    @include larger-than(small){
                        font-size: 26px;
                    }
                }
                
                span {
                    
                    color: $player-controls-color;                    
                    padding: 0 5px;
                    display: none;
                    
                    &.mejs-currenttime, &.mejs-duration{
                        display: inline-block;
                        padding: 0;
                        //font-weight: bold;
                        font-weight: normal;
                    }
                    &.mejs-currenttime{                        
                    }
                    &.mejs-duration{
                        display: none;
                        margin-left: 5px;
                        //color: #DB8BEE;
                        //font-size: 1.5em;
                        &:before{
                            content: "/ ";
                        }
                    }
                }
            }
            
            //
            // volume button 
            //
            .mejs-volume-button {
                
                display: none;
                top: 12px;                
                right: 26px;
                
                                
                width: 36px;
                height: 32px;
                
                /*@include larger-than(small){
                    right: 166px;
                }*/
                
                @include breakpoint(medium) {
                    right:166px;
                    display:block;
                }
                
            }
            .mejs-mute button {
                //background: url(../../../player/img/default/mejs/volume2.png) no-repeat left center;
                background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAgCAYAAAB6kdqOAAAB4UlEQVRYhe2XoXLCQBCGEREVEQhEBQKBQEQgKpCViD4AogJRUYFAVOAQfQAEAlFRgahAIBCIisgKREVFBaIPgKiIQER8FdlMN5kEkpAUZsrORNyf3c13c3t7l1LpbP/NAANoA2bKOBO4zBumBth4tgX6CeMMYAm8AkZeMF3AIWhuwlgLcCVmeChIBZgTYyny9P1JAM2sMDfAJg5mF5BM5EUvkZrYPC2ICUx2gSQAmonLWGl1tXRN7XwNDKMeef+VBGYPUFe5NZU+Fm3qC519H0gKEwYitK2BhbjNlVYXbeMLbwUC2cCtGleBb3GtK30tWmvvx7ICAQ2RHMBS+qPoDxHLNigMSOKeRX5S2pVoy4j6mhQNVBb5PaQDrNW4LdqsUCBVHy5wobQN4KixJeH2XwB9nBrQllNZMuBOZN2dj1PUeMeNI4/uOf62HygtsO0/iwCSuAXQUeMKv4dzbGPsFQhUjgAEsJUWPDpE7LD7cPXbfSqgEIyeuD5cR6JN42KjklXxrpyHAK3ERRf4Jd4uDECmAeupBGmBLLwl0xe0qYSlu6CFEjfUbBMDReSJvB9lhTLwaszNAoRXAv4PwuggmFDiFsG2MUkY5/8GrcjrN0glN4F7VF9JGFcGarnCnO0Y9gN6+LedOW/esQAAAABJRU5ErkJggg==") no-repeat left center;
                width: 36px;
                height: 32px;
                margin: 0;
                padding: 0; 
                box-shadow: none;
            }
            .mejs-unmute button {
                //background: url(../../../player/img/default/mejs/volume3.png) no-repeat -1px center;
                background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAABGElEQVRYhe3WIUsEQRiH8QuHnN1gNBpNahAMGgzGC34Ao4iCUTDc5xCbxQ9jU0xGg8Fw4cKFn2HHu72F1WWPfQfk/jBlGOZ5mPfd2en1VvkPQR/HGOSAb+NZkTEuIuE3mFjMJAJ8iEc16RK8hku81ME7E8Au7n8DLyWAM9zVjA18NoG3EsDVH3tuNYW3FXjPJtBw89YCOMdp7VyXAoremaYxrJT7K+oEhiWJh7RkjIMQgYrEIjxQoPyVzcoRVYIf+BgjlZ6IasLZsZuXI6wJT7BfO6d4THx0JdAouM0qkCTy/YwaCu6ZXyTxAkliHdd4yyJQEjnCUzaBJNFXNO+0wu/+UVoR2cFrSWAUKpAkBoobbjMcvsqy+QYWEEnHPxHlxQAAAABJRU5ErkJggg==") no-repeat -1px center;
                width: 36px;
                height: 32px;
                margin: 0;
                padding: 0;
                box-shadow: none;
            }
            
            //
            // volume slider
            //
            .mejs-horizontal-volume-slider{
                display:none;
                position: absolute;
                top: 13px;
                right: 20px;
                width: 50px;
                &:hover{
                    background-color: transparent;
                }
                
                @include larger-than(small){
                    
                    width: 140px; 
                }
                
                @include breakpoint(medium) {
                    display: block;
                }
                
                height: $player-element-height-px;
                
                .mejs-horizontal-volume-total, .mejs-horizontal-volume-current{
                    
                    width: 50px; 
                    height: 9px;
                    
                    @include larger-than(small){
                        //width: 140px; 
                        width: 100%; 
                    }
                }
                .mejs-horizontal-volume-total{
                    background: darken($player-main-bg, 10%);
                }
                .mejs-horizontal-volume-current{
                    background: $player-controls-color;                    
                }
            }
            // current time tooltip
            .mejs-time-float{
                visibility: hidden; // hide!
            }

            .mejs-time span {
                color: $player-controls-color;
            }            
            .mejs-captions-button {
                display: none;
            }
        }
    }
    
    .cover-current{
        
        //min-height: $player-full-min-height;
        text-align: center;
    }
}


// pulse animation
@-webkit-keyframes player-pulse {
    0% { -webkit-transform: scale(.8); }
    50% { -webkit-transform: scale(1); }
    100% { -webkit-transform: scale(.8); }
}
@-moz-keyframes player-pulse {
    0% { -moz-transform: scale(.8); }
    50% { -moz-transform: scale(1); }
    100% { -moz-transform: scale(.8); }
}
@-o-keyframes player-pulse {
    0% { -o-transform: scale(.8); }
    50% { -o-transform: scale(1); }
    100% { -o-transform: scale(.8); }
}
@keyframes player-pulse {
    0% { transform: scale(.8); }
    50% { transform: scale(1); }
    100% { transform: scale(.8); }
}

