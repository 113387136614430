//
// Common animations used
//

// import compass
//@import "../../../libs/compass/css3";


body, .anim, * {
    /*backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    */
}
.anim {
    animation-fill-mode: both;
    transform: translate3d(0, 0, 0);
    perspective: 1000;

    -o-animation-fill-mode: both;
    -o-transform: translate3d(0, 0, 0);
    -o-perspective: 1000;

    -moz-animation-fill-mode: both;
    -moz-transform: translate3d(0, 0, 0);
    -moz-perspective: 1000;

    -webkit-animation-fill-mode: both;
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000;

    // set default animation duration
    @include vendor-prefix(animation-duration, 0.5s);
}



//
// BASE animations set 
//


//
// flash
//
@-webkit-keyframes flash {
    0%, 50%, 100% {opacity: 1;}	
    25%, 75% {opacity: 0;}
}

@-moz-keyframes flash {
    0%, 50%, 100% {opacity: 1;}	
    25%, 75% {opacity: 0;}
}

@-o-keyframes flash {
    0%, 50%, 100% {opacity: 1;}	
    25%, 75% {opacity: 0;}
}

@keyframes flash {
    0%, 50%, 100% {opacity: 1;}	
    25%, 75% {opacity: 0;}
}

.anim.flash {
    -webkit-animation-name: flash;
    -moz-animation-name: flash;
    -o-animation-name: flash;
    animation-name: flash;
    @include vendor-prefix(animation-duration, 0.7s);
}

//
// Shake
//
@-webkit-keyframes shake {
    0%, 100% {-webkit-transform: translateX(0);}
    10%, 30%, 50%, 70%, 90% {-webkit-transform: translateX(-10px);}
    20%, 40%, 60%, 80% {-webkit-transform: translateX(10px);}
}
@-moz-keyframes shake {
    0%, 100% {-moz-transform: translateX(0);}
    10%, 30%, 50%, 70%, 90% {-moz-transform: translateX(-10px);}
    20%, 40%, 60%, 80% {-moz-transform: translateX(10px);}
}
@-o-keyframes shake {
    0%, 100% {-o-transform: translateX(0);}
    10%, 30%, 50%, 70%, 90% {-o-transform: translateX(-10px);}
    20%, 40%, 60%, 80% {-o-transform: translateX(10px);}
}
@keyframes shake {
    0%, 100% {transform: translateX(0);}
    10%, 30%, 50%, 70%, 90% {transform: translateX(-10px);}
    20%, 40%, 60%, 80% {transform: translateX(10px);}
}
.anim.shake {
    -webkit-animation-name: shake;
    -moz-animation-name: shake;
    -o-animation-name: shake;
    animation-name: shake;
    @include vendor-prefix(animation-duration, 1s);
}

//
// Bounce
//
@-webkit-keyframes bounce {
    0%, 20%, 50%, 80%, 100% {-webkit-transform: translateY(0);}
    40% {-webkit-transform: translateY(-30px);}
    60% {-webkit-transform: translateY(-15px);}
}
@-moz-keyframes bounce {
    0%, 20%, 50%, 80%, 100% {-moz-transform: translateY(0);}
    40% {-moz-transform: translateY(-30px);}
    60% {-moz-transform: translateY(-15px);}
}
@-o-keyframes bounce {
    0%, 20%, 50%, 80%, 100% {-o-transform: translateY(0);}
    40% {-o-transform: translateY(-30px);}
    60% {-o-transform: translateY(-15px);}
}
@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
    40% {transform: translateY(-30px);}
    60% {transform: translateY(-15px);}
}
.anim.bounce {
    -webkit-animation-name: bounce;
    -moz-animation-name: bounce;
    -o-animation-name: bounce;
    animation-name: bounce;
    @include vendor-prefix(animation-duration, 0.3s);
}