$color_1: #fff;
$font_family_1: "Helvetica Neue",Helvetica,Arial,sans-serif;
$background_color_1: #0a88ff;
$background_color_2: #006ed6;
$background_color_3: #306199;
$background_color_4: #244872;
$background_color_5: #32506d;
$background_color_6: #22364a;
$background_color_7: #007bb6;
$background_color_8: #005983;
$background_color_9: #26c4f1;
$background_color_10: #0eaad6;
$background_color_11: #e93f2e;
$background_color_12: #ce2616;
$background_color_13: #df1c31;
$background_color_14: #b21627;
$background_color_15: #8bbbe3;
$background_color_16: #62a3d9;
$background_color_17: #b81621;
$background_color_18: #8a1119;
$background_color_19: #ed4054;
$background_color_20: #e4162d;
$background_color_21: #444;
$background_color_22: #2b2b2b;
$background_color_23: #125688;
$background_color_24: #0c3a5b;
$background_color_25: #0b79e5;
$background_color_26: #095fb4;
$background_color_27: #4d71a9;
$background_color_28: #3d5a86;
$background_color_29: #f60;
$background_color_30: #cc5200;
$background_color_31: #43d854;
$background_color_32: #28c039;
$background_color_33: #8d98a2;
$background_color_34: #717f8b;
$background_color_35: #ccc;
$background_color_36: transparent;

.rrssb-buttons {
    display: inline-block;  // mtsoft
    box-sizing: border-box;
    font-family: $font_family_1;
    //font-size: 12px;
    //height: 36px;
    height: 48px;
    margin: 0;
    padding: 0;
    width: 100%;
    &:after {
        clear: both;
        content: ' ';
        display: table;
    }
    &:before {
        content: ' ';
        display: table;
    }
    li {
        box-sizing: border-box;
        float: left;
        height: 100%;
        //line-height: 13px;
        list-style: none;
        margin: 0;
        padding: 0 2px;
        a {
            background-color: $background_color_35;
            border-radius: 2px;
            box-sizing: border-box;
            display: block;
            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;
            font-weight: 700;
            height: 100%;
            padding: 11px 7px 12px 27px;
            position: relative;
            text-align: center;
            text-decoration: none;
            //text-transform: uppercase; // mtsoft 
            -webkit-transition: background-color .2s ease-in-out;
            transition: background-color .2s ease-in-out;
            width: 100%;
            .rrssb-icon {
                display: block;
                left: 10px;
                padding-top: 9px;
                position: absolute;
                top: 0;
                width: 10%;
                svg {
                    height: 28px;
                    width: 28px;
                    path {
                        fill: #fff;
                    }
                }
            }
            .rrssb-text {
                color: $color_1;
                padding: 0 8px; // mtsoft 
                font-size: .9rem; // mtsoft
                //letter-spacing: +.1rem; // mtsoft 
            }
            &:active {
                box-shadow: inset 1px 3px 15px 0 rgba(22,0,0,.25);
            }
        }
    }
    li.rrssb-email {
        a {
            background-color: $background_color_1;
            &:hover {
                background-color: $background_color_2;
            }
        }
    }
    li.rrssb-facebook {
        a {
            background-color: $background_color_3;
            &:hover {
                background-color: $background_color_4;
            }
        }
    }
    li.rrssb-tumblr {
        a {
            background-color: $background_color_5;
            &:hover {
                background-color: $background_color_6;
            }
        }
    }
    li.rrssb-linkedin {
        a {
            background-color: $background_color_7;
            &:hover {
                background-color: $background_color_8;
            }
        }
    }
    li.rrssb-twitter {
        a {
            background-color: $background_color_9;
            &:hover {
                background-color: $background_color_10;
            }
        }
    }
    li.rrssb-googleplus {
        a {
            background-color: $background_color_11;
            &:hover {
                background-color: $background_color_12;
            }
        }
    }
    li.rrssb-youtube {
        a {
            background-color: $background_color_13;
            &:hover {
                background-color: $background_color_14;
            }
        }
    }
    li.rrssb-reddit {
        a {
            background-color: $background_color_15;
            &:hover {
                background-color: $background_color_16;
            }
        }
    }
    li.rrssb-pinterest {
        a {
            background-color: $background_color_17;
            &:hover {
                background-color: $background_color_18;
            }
        }
    }
    li.rrssb-pocket {
        a {
            background-color: $background_color_19;
            &:hover {
                background-color: $background_color_20;
            }
        }
    }
    li.rrssb-github {
        a {
            background-color: $background_color_21;
            &:hover {
                background-color: $background_color_22;
            }
        }
    }
    li.rrssb-instagram {
        a {
            background-color: $background_color_23;
            &:hover {
                background-color: $background_color_24;
            }
        }
    }
    li.rrssb-delicious {
        a {
            background-color: $background_color_25;
            &:hover {
                background-color: $background_color_26;
            }
        }
    }
    li.rrssb-vk {
        a {
            background-color: $background_color_27;
            &:hover {
                background-color: $background_color_28;
            }
        }
    }
    li.rrssb-hackernews {
        a {
            background-color: $background_color_29;
            &:hover {
                background-color: $background_color_30;
            }
        }
    }
    li.rrssb-whatsapp {
        a {
            background-color: $background_color_31;
            &:hover {
                background-color: $background_color_32;
            }
        }
    }
    li.rrssb-print {
        a {
            background-color: $background_color_33;
            &:hover {
                background-color: $background_color_34;
            }
            .rrssb-icon {
                svg {
                    path {
                        &:nth-child(2) {
                            fill: none;
                        }
                    }
                }
            }
        }
    }
    li.small {
        a {
            padding: 0;
            .rrssb-icon {
                left: auto;
                margin: 0 auto;
                overflow: hidden;
                position: relative;
                top: auto;
                width: 100%;
            }
            .rrssb-text {
                visibility: hidden;
            }
        }
    }
}
.rrssb-buttons.large-format {
    height: auto;
    li {
        height: auto;
        a {
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            border-radius: .2em;
            padding: 8.5% 0 8.5% 12%;
            .rrssb-icon {
                height: 100%;
                left: 7%;
                padding-top: 0;
                width: 12%;
                svg {
                    height: 100%;
                    position: absolute;
                    top: 0;
                    width: 100%;
                }
            }
            .rrssb-text {
                -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
            }
        }
    }
}
.rrssb-buttons.small-format {
    padding-top: 5px;
    li {
        height: 80%;
        padding: 0 1px;
        a {
            .rrssb-icon {
                height: 100%;
                padding-top: 0;
                svg {
                    height: 48%;
                    position: relative;
                    top: 6px;
                    width: 80%;
                }
            }
        }
    }
}
.rrssb-buttons.tiny-format {
    height: 22px;
    position: relative;
    li {
        padding-right: 7px;
        a {
            background-color: $background_color_36;
            padding: 0;
            .rrssb-icon {
                svg {
                    height: 70%;
                    width: 100%;
                }
            }
            &:active {
                background-color: $background_color_36;
            }
            &:hover {
                background-color: $background_color_36;
            }
        }
    }
    li.rrssb-email {
        a {
            .rrssb-icon {
                svg {
                    path {
                        fill: #0a88ff;
                    }
                }
                &:hover {
                    .rrssb-icon {
                        svg {
                            path {
                                fill: #0054a3;
                            }
                        }
                    }
                }
            }
        }
    }
    li.rrssb-facebook {
        a {
            .rrssb-icon {
                svg {
                    path {
                        fill: #306199;
                    }
                }
                &:hover {
                    .rrssb-icon {
                        svg {
                            path {
                                fill: #18304b;
                            }
                        }
                    }
                }
            }
        }
    }
    li.rrssb-tumblr {
        a {
            .rrssb-icon {
                svg {
                    path {
                        fill: #32506d;
                    }
                }
                &:hover {
                    .rrssb-icon {
                        svg {
                            path {
                                fill: #121d27;
                            }
                        }
                    }
                }
            }
        }
    }
    li.rrssb-linkedin {
        a {
            .rrssb-icon {
                svg {
                    path {
                        fill: #007bb6;
                    }
                }
                &:hover {
                    .rrssb-icon {
                        svg {
                            path {
                                fill: #003650;
                            }
                        }
                    }
                }
            }
        }
    }
    li.rrssb-twitter {
        a {
            .rrssb-icon {
                svg {
                    path {
                        fill: #26c4f1;
                    }
                }
                &:hover {
                    .rrssb-icon {
                        svg {
                            path {
                                fill: #0b84a6;
                            }
                        }
                    }
                }
            }
        }
    }
    li.rrssb-googleplus {
        a {
            .rrssb-icon {
                svg {
                    path {
                        fill: #e93f2e;
                    }
                }
                &:hover {
                    .rrssb-icon {
                        svg {
                            path {
                                fill: #a01e11;
                            }
                        }
                    }
                }
            }
        }
    }
    li.rrssb-youtube {
        a {
            .rrssb-icon {
                svg {
                    path {
                        fill: #df1c31;
                    }
                }
                &:hover {
                    .rrssb-icon {
                        svg {
                            path {
                                fill: #84111d;
                            }
                        }
                    }
                }
            }
        }
    }
    li.rrssb-reddit {
        a {
            .rrssb-icon {
                svg {
                    path {
                        fill: #8bbbe3;
                    }
                }
                &:hover {
                    .rrssb-icon {
                        svg {
                            path {
                                fill: #398bcf;
                            }
                        }
                    }
                }
            }
        }
    }
    li.rrssb-pinterest {
        a {
            .rrssb-icon {
                svg {
                    path {
                        fill: #b81621;
                    }
                }
                &:hover {
                    .rrssb-icon {
                        svg {
                            path {
                                fill: #5d0b11;
                            }
                        }
                    }
                }
            }
        }
    }
    li.rrssb-pocket {
        a {
            .rrssb-icon {
                svg {
                    path {
                        fill: #ed4054;
                    }
                }
                &:hover {
                    .rrssb-icon {
                        svg {
                            path {
                                fill: #b61124;
                            }
                        }
                    }
                }
            }
        }
    }
    li.rrssb-github {
        a {
            .rrssb-icon {
                svg {
                    path {
                        fill: #444;
                    }
                }
                &:hover {
                    .rrssb-icon {
                        svg {
                            path {
                                fill: #111;
                            }
                        }
                    }
                }
            }
        }
    }
    li.rrssb-instagram {
        a {
            .rrssb-icon {
                svg {
                    path {
                        fill: #125688;
                    }
                }
                &:hover {
                    .rrssb-icon {
                        svg {
                            path {
                                fill: #061d2e;
                            }
                        }
                    }
                }
            }
        }
    }
    li.rrssb-delicious {
        a {
            .rrssb-icon {
                svg {
                    path {
                        fill: #0b79e5;
                    }
                }
                &:hover {
                    .rrssb-icon {
                        svg {
                            path {
                                fill: #064684;
                            }
                        }
                    }
                }
            }
        }
    }
    li.rrssb-vk {
        a {
            .rrssb-icon {
                svg {
                    path {
                        fill: #4d71a9;
                    }
                }
                &:hover {
                    .rrssb-icon {
                        svg {
                            path {
                                fill: #2d4263;
                            }
                        }
                    }
                }
            }
        }
    }
    li.rrssb-hackernews {
        a {
            .rrssb-icon {
                svg {
                    path {
                        fill: #f60;
                    }
                }
                &:hover {
                    .rrssb-icon {
                        svg {
                            path {
                                fill: #993d00;
                            }
                        }
                    }
                }
            }
        }
    }
    li.rrssb-whatsapp {
        a {
            .rrssb-icon {
                svg {
                    path {
                        fill: #43d854;
                    }
                }
                &:hover {
                    .rrssb-icon {
                        svg {
                            path {
                                fill: #1f962d;
                            }
                        }
                    }
                }
            }
        }
    }
    li.rrssb-print {
        a {
            .rrssb-icon {
                svg {
                    path {
                        fill: #8d98a2;
                    }
                }
                &:hover {
                    .rrssb-icon {
                        svg {
                            path {
                                fill: #5a656f;
                            }
                        }
                    }
                }
            }
        }
    }
}

