/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 2017-05-02, 11:32:31
    Author     : marek
*/
button, .button, .btn, %button-default{
    
    @extend .button;
    
    font-size: initial;
    //padding: .5em .8em;
    
    i.ico{
        margin:0;
        width:27px; // fit nicley
        height:27px;
        
        &:first-child{
            margin-right: .75rem;
        }
        &:last-child{
            margin-right: 0;
        }
    }
    
    span{
        color: inherit;
    }
    
}
button:hover, .button:hover, .btn:hover, %button-default:hover{
    text-decoration: none !important;
}
