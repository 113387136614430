//
// Theme settings 
//


 
//
// foundation overidde
//
$theme-distance-small: map-get($grid-column-gutter, small);
$theme-distance-medium: map-get($grid-column-gutter, medium);



//
// common settings
//
$theme-bg-color-tag: #999999;
$theme-bg-color-live: #900605; 
