//
// Header
//
header{
        
    @include breakpoint(small) {
        
        margin-bottom: $theme-distance-small;
    }
        
    @include breakpoint(medium) {
        
        margin-bottom: $theme-distance-medium;
    } 
}