//
// common list and prticualr listing 
//
$theme-list-navi-current-color:     red !default;
$theme-list-navi-font-size-normal:   1.2rem;


.mtl-search-box{

    & > div{

        float:left;

        & > div{

            display: block !important;
        }
    }

// listing top filters
.list-top-filters{

    //line-height: $theme-list-navi-font-size-active;
    > a{
        padding: 0 .57rem;
        //padding: .4rem .57rem;
        font-size: $theme-list-navi-font-size-normal;        

        &.mtl-flt-link-active{
            padding: 0 .57rem;
            color: $theme-list-navi-current-color;
            //font-size: $theme-list-navi-font-size-active;
        }        

        //@extend #menu-item-hover;        
    }
    
    //
    // search box
    //
    .forma{
                
            button{
                //float: right;
                
                //top: -3px;
                //margin-left: $theme-default-padding;
                //margin-right: 0;
                //margin-bottom: 0;
                padding: .6rem 1.4rem;
                vertical-align: top;
                //width:100%;
                
                i.ico{
                    margin-left: -4px;
                    margin-top: 2px;
                }
                
                span > span{
                    display: none;
                    
                    @include larger-than(small) {
                        display: block;
                    }
                }
            }
        }

        .input.text{
            width: 100%;            
            div{
                //float: left;
                width: 100%;
                
                input{
                    margin-bottom: 0;
                    width: 100%;                    
                }
            }
        }
        .input.select{
            
            select{
                //margin-bottom: 0;
            }
        }
    }
}

// lisitng bottom navi
.list-navi-bottom{

    text-align: center;
    padding: 2rem 0;
    //margin-top: $column-gutter*3;
    //margin-bottom: $column-gutter*2;

    > div{ 
        display:inline-block; 
        text-align: center;

        >span, >nav, > nav > span{
            float: left;
            //float: none;
            display: block;
            font-size: $theme-list-navi-font-size-normal;
            
            
            a, a:hover{
                //color: $theme-color-link-light !important;
                //background: $theme-btn-bg;
                //border-radius: $button-border-radius;
                //padding: $theme-default-padding*1.1 $theme-default-padding*3;
                @include transition(background .35s);
            }
            a:hover{
                //background: darken($theme-btn-bg, 20%);
            }
        }
        >span, > nav > span{
            
            //padding: $theme-default-padding*2 $theme-default-padding*1.2;
            //@extend #menu-item-hover;
            
            &:hover{
                //color: $theme-color-link-light !important;
                //text-decoration: none;
            }
            
        }
        >span{
            
        }
        > nav{
            //margin: 0 $theme-default-padding*4;
            margin: 0;
            

            > span {
                
                //display: none;
                
                &.current{
                    
                    display: inline-block;
                    //color: $theme-btn-bg !important;
                    //background: $theme-color-link-light;
                    font-weight: bold;
                    //font-size: $theme-list-navi-font-size-active*1.5;
                    //font-size: $theme-list-navi-font-size-active;
                }
            }
            
            @include larger-than(tiny) {
                
                //margin: 0 $theme-default-padding*4;
                
                > span {                
                    display: inline-block;
                    
                    &.current{
                        //font-size: $theme-list-navi-font-size-active*1.5;
                    }
                }
            }

        }
        // pagination links
        a[href], .current{ 
            
            @extend %button-default;
            margin-right: .25rem;
        }
        // prev
        a[rel="prev"]{
            margin-right: 1rem;
        }
        // next
        a[rel="next"]{
            margin-left: 1rem;
            margin-right: 0;
        }
        // current 
        .current{
            position: relative;
            top: -.4rem;
            font-size: 2.8rem;
            padding: .15em 0.35em;                        
        }
    }
}

//
// Common lisitngs styles 
//
.list-box-row {

    //float: left;
    
    .box{

        //position: relative;        
        /*/margin-top: $column-gutter;*/
        //padding-bottom: $theme-default-padding;

        img{

            //border-radius: $theme-default-border-radius-small; 

            &:hover{
                //@include scale(1.1);
                //@include transition(all .25s);
                //opacity: .8;
            }
        }


        // links 
        a{
            //color: darken($theme-color-link-light, 35%);
            //padding: 0;

            &:hover{
                //color: $theme-color-link-light;
                //text-decoration: none;
            }
        }

        // left side image (if any)
        .left-img{
            
            //width: 30% !important;
            //max-width: 132px;
            > div{
                //overflow: hidden;
                //border-radius: $theme-default-border-radius-small;
            }
        }

        // right side or full width description 
        .right-desc{
            //width: 70% !important;
           // position: relative;
            //padding-left: 0;
            //max-height: 245px;
            //overflow: hidden;
            
            // main titile
            h2{
                
               // color: $cc-box-color;
                //font-size: 1.2rem;
                //line-height: 1rem;
                //font-weight: bold;
                //margin-bottom: 5px;
                
                @include larger-than(tiny) {
                    //font-weight: normal;
                    //font-size: 1.7rem;
                    //line-height: 1.6rem;
                }
            }
            // subtitle
            h3{}

            // main description
            .desc-out {
                //position: absolute;
                //bottom: 0;
                //height: 7.2rem;
                //overflow: hidden;
                //vertical-align: top;
                //text-overflow: ellipsis;
                //margin-top: $column-gutter*.5;
                //margin-bottom: $column-gutter;
                //white-space: nowrap;                                

                p.desc{                
                    //display: inline-block;
                    //line-height: 1.2rem;
                }
            }
        }

        // View detials link
        > a.view-details{
            ///position: absolute;
            //bottom: $column-gutter*.5;
            //right: $column-gutter*.5;
            //margin: 0;
        }

        //
        // Episodes archive 
        //
        //.row-episode .box{ 
            
            //position: relative;
            
            .arch-btm-btns{ 

                position: absolute;
                //bottom: $column-gutter*.35; 
                //right: $column-gutter*.35; 
                padding: 0;
                

                a.button{                
                    
                    float: right;
                    position: initial;
                    margin-bottom: 5px;                    
                                        
                    span{
                        display: none;
                    }
                    
                    @include larger-than(small) {
                        display: inline;
                    }
                    
                    //background: none;
                    
                    
                    &:hover{
                        //background: darken($theme-btn-bg, 20%); 
                    }
                }
                a.audio-preview, a.download{
                    //padding-left: $column-gutter;
                    //padding-right: $column-gutter;
                }
            }
        //}
        //@extend #menu-item-hover;         
    }






    /*
    a {
       //float: left;        
        //font-weight: bold;
        //font-size: 0.88rem;
        padding: 0;
        
        img{            
            margin: 0;            
        }
        
        .row-box-name{

            display: block;
            //text-align: center;
            padding: $theme-default-padding*2;
            //padding-top: 0; 
            height: 24px*2 + $theme-default-padding*3;
            //overflow: hidden;
        }
    }*/  




    //
    // Particular data types 
    //
    &.row-guest{

        .box{
            
            a{
                padding: 0;
            }
            img{
                border-radius: 0;
            }
            .row-box-name{
                
                //color: lighten($theme-color-1, 50%);
                //padding: $theme-default-padding;
                height: 55px;
                overflow: hidden;
            }
        }
    }

    &.row-host{

        .box{
            
            a{
                padding: 0;
            }
            img{
                border-radius: 0;
            }
            
            .row-box-name{

                //color: lighten($theme-color-1, 50%);
                //padding: $theme-default-padding;
                height: 55px;
                overflow: hidden;
            }
        }
    }

    &.row-sponsor{
        
        h2{
            span{
                font-size: 1.2rem;
                font-style: italic;
            }
        }
        /*a {
            float: left;
            //padding: $theme-default-padding;
            //max-height: 232px; 
            overflow: hidden;

            img{
                float: left;
                margin: $theme-default-padding;                
                border-radius: $theme-default-border-radius-small;
            }

            .row-box-name{

                color: lighten($theme-color-1, 50%);
                //font-weight: bold;
                //height: 100%;
                //padding-left: $theme-default-padding;
                //overflow: hidden;


                .row-box-desc{
                    //font-size: .76rem;
                    padding-top: $theme-default-padding*2;
                    padding-bottom: $theme-default-padding*2;

                }
            }
        
        }*/
    }

    &.row-episode, &.row-show{

        p.episode-date, p.episode-host, p.episode-guest,
        p.show-time, p.show-host{
            margin: 0;
            padding: 0;
                        
            //color: $cc-box-color;
            //fill: $cc-box-color;
            font-size: 0.88rem;
            line-height: 1.2em;
            
            a:hover{
                text-decoration: underline;
            }
        }
        
        p.episode-date{
            line-height: 1.4em;
            padding-bottom: 10px;
            i.ico{ 
                margin-left: -1px;
            }
        }
        p.episode-host{
            padding-bottom: 2px;
        }
        p.episode-guest{
            padding-bottom: 10px;
        }
        img{
            //float: left;
            //margin: $theme-default-padding;                            
        }
        .left-img{
            width: 30% !important;
        }
        .right-desc{
            
        }
        /*
        .episode-date{
            margin: 0;
            color: lighten($theme-color-1, 50%);
            fill: lighten($theme-color-1, 50%);
            font-size: .88rem;
            
            i.ico{
                top: -1px;
            }
        }
        p.episode-host, p.episode-guest{
            margin: 0;
            padding: 0;
        }
        */
        p.episode-desc, p.show-desc{
            margin-top: .5rem;
            font-size: .88rem;
            line-height: 1.2rem;
            //color: $cc-box-color;
        }
    }
}
