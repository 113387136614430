/**
 * New zapbox
 */
 .zapbox-new{

    .z-hosts, .z-guests, .z-shows{
        display: none;
    }
    #zapbox-hosts,
    #zapbox-guests,
    #zapbox-shows{
        display: none;
    }
 }


//$zapbox-bg-light:       lighten($theme-zapbox-dark, 38%);
$theme-default-padding : 5px !default;
$theme-default-border-radius-small: 2px !default;
$theme-default-border-radius-large: 7px !default;

$theme-color-base: #000 !default;
$theme-box-title-color: #fff !default;
$theme-color-light: #fff !default;
$theme-color-base-light: #dfdfdf !default;
$theme-zapbox-color : darken($theme-color-base, 20%) !default;
$theme-zapbox-title-color : $theme-box-title-color !default;
$theme-zapbox-light : $theme-color-light !default;
$theme-zapbox-sheet-bg : $theme-color-base-light !default; //darken($theme-color-light, 10%);
$theme-zapbox-dark : $theme-color-base !default;
$theme-zapbox-border-radius : $theme-default-border-radius-small !default;



$theme-default-border-radius-small: 3px !default;
$zapbox-bg-light: #fff !default;
$theme-zapbox-border-radius: 3px !default;
$cc-box-width : 235px !default;
$cc-box-height : 235px !default; // 374 
$cc-box-distance : $theme-default-padding !default;
$cc-box-border-radius : $theme-default-border-radius-small !default;
$cc-box-scrollbar-color : darken($theme-color-base, 10%) !default;
$cc-box-scrollbar-height : 16px !default;
$cc-box-scrollbar-width : 16px !default;
$cc-box-bg-color : #efefef !default;
$cc-box-bg-color-hover : transparent !default;
$cc-box-title-color : #000 !default;
$cc-box-font-title-font-size: 1.3rem !default;
$cc-box-font-size : .88rem !default;

/**
* Zapbox
*/

#zapbox {
    //font-family: "OpenSans", helvetica bold, arial;
    max-height: 500px; // prevent 100% height on loading 
    border-radius: $theme-zapbox-border-radius;
    overflow: hidden;
    .iScrollHorizontalScrollbar {
        //bottom: -4px;
    }
    .iScrollVerticalScrollbar {
        //width: 16px;
        width: $cc-box-scrollbar-height; //bottom: 63px;
        top: 2px;
        right: 5px;
    }
    //
    // custom iScroll scroll-bars
    //
    
.iScrollHorizontalScrollbar {
    position: absolute;
    z-index: 9999;
    height: 13px;
    left: 4px;
    right: 4px;
    bottom: 6px;
    overflow: hidden;
}

.iScrollHorizontalScrollbar.iScrollBothScrollbars {
    right: 18px;
}
.iScrollVerticalScrollbar {
    position: absolute;
    z-index: 9999;
    width: 16px;
    bottom: 2px;
    top: 2px;
    right: 2px;
    overflow: hidden;
}

.iScrollVerticalScrollbar.iScrollBothScrollbars {
    bottom: 18px;
}

.iScrollIndicator {
    position: absolute;
    background: #E8D5B4;
    border-width: 0px;
    border-style: solid;
    border-color: #E8D5B4;
    border-radius: 5px;

}

.iScrollHorizontalScrollbar .iScrollIndicator {
    height: 100%;
    //background: $theme-color-light;
    background: $theme-color-base;
}

.iScrollVerticalScrollbar .iScrollIndicator {
    width: 100%;
    height: 64px;
    //background: $theme-color-light;
    background: $theme-color-base;
}

}

// header (tabs) 
#zapbox>dl.tabs {
    //border: 1px solid $theme-zapbox-color;
    //border-bottom: 0;
    //overflow: hidden;
    dd>a,
    .tab-title>a {
        //background: none;
        &:hover {
            //background: darken($theme-zapbox-dark, 10%);
        }
    } // first level tabs (top)
    &>dd {
        float:left;
        text-align: center;
        //text-transform: uppercase;
        //background: $theme-zapbox-dark;
        //background: transparent;
        //width: 16.66%;
        //border-radius: 0;
        margin: 0;
        a {
            float: left;
            display: inline-block;
            position: relative; //margin-left: -1px;
            width: 100%;
            //padding: .5em 0;
            padding: 18px 20px;
            //font-size: .88em; //font-weight: bold;        
            //color: #C8D1EE;
            text-decoration: none;
            border-radius: 0; //text-shadow: 1px 0 2px #444444;
            //border: 1px solid $theme-zapbox-color;
            font-size: 1.5rem;
            line-height: 1.2rem;
        }
        span {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            display: inline-block;
            width: 1px;
            //background: #8C9CCB;
        }
        &:last-of-type {
            span {
                display: none;
            }
        }
        &.active {
            a {
                color: $theme-zapbox-color;
                background: $theme-zapbox-light; // border-bottom-color: $theme-zapbox-light;
                //border-right-color: $theme-zapbox-color;  
                //margin-left: -2px;
                //text-shadow: none;                
            }
        }
    }
}

#zapbox>.tabs-content {
    width: 100%;
    //height: 100%;
    nav {
        padding: 1em .5em .5em .5em;
        ul,
        li {
            float: left;
            margin: 0;
            padding: 0;
            list-style-type: none;
        }
        ul>li {
            >a {
                display: inline-block;
                padding: .4em .8em;
                font-size: .88em; //font-weight: bold;        
                color: $theme-zapbox-color;
                text-decoration: none;
                &.active {
                    color: $theme-color-light;
                    background: $theme-zapbox-color;
                    border-radius: $theme-zapbox-border-radius;
                }
            }
        }
    }
    &>.content {
        min-height: 273px; // iScroller
        //position: absolute;
        z-index: 1;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0); //width: 100%;
        -webkit-transform: translateZ(0);
        -moz-transform: translateZ(0);
        -ms-transform: translateZ(0);
        -o-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-text-size-adjust: none;
        -moz-text-size-adjust: none;
        -ms-text-size-adjust: none;
        -o-text-size-adjust: none;
        text-size-adjust: none;
        .c-out,
        .c-out2 {
            //float: left;            
        }
        .c-out {
            //margin: $theme-default-padding; 
            margin: 0;
            border-radius: $theme-zapbox-border-radius;
        }
        .c-out2 {
            //padding: 8px 8px 4px 8px;
            padding: 0; //border: 1px solid #A96361;
            /*
            background-color: #fffbe6;
            *zoom: 1;
            filter: progid:DXImageTransform.Microsoft.gradient(gradientType=0, startColorstr='#FFFFFBE6', endColorstr='#FFF6C163');
            background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmJlNiIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2Y2YzE2MyIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
            background-size: 100%;
            background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #fffbe6), color-stop(100%, #f6c163));
            background-image: -webkit-linear-gradient(top, #fffbe6 0%, #f6c163 100%);
            background-image: -moz-linear-gradient(top, #fffbe6 0%, #f6c163 100%);
            background-image: -o-linear-gradient(top, #fffbe6 0%, #f6c163 100%);
            background-image: linear-gradient(top, #fffbe6 0%, #f6c163 100%);
            */
            //@include filter-gradient(#fffbe6, #f6c163, vertical);
            //$experimental-support-for-svg: true; 
            //@include background-image(linear-gradient(top,  #fffbe6 0%,#f6c163 100%));
            min-height: 403px;
            .content {
                min-height: 355px;
            }
        }
        &.active {
            background-color: $theme-zapbox-light;
            /**zoom: 1;
            filter: progid:DXImageTransform.Microsoft.gradient(gradientType=0, startColorstr='#FFFFF6CD', endColorstr='#FFED9226');
            background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZjZjZCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2VkOTIyNiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
            background-size: 100%;
            background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, $theme-zapbox-light), color-stop(100%, #ed9226));
            background-image: -webkit-linear-gradient(top, $theme-zapbox-light 0%, #ed9226 100%);
            background-image: -moz-linear-gradient(top, $theme-zapbox-light 0%, #ed9226 100%);
            background-image: -o-linear-gradient(top, $theme-zapbox-light 0%, #ed9226 100%);
            background-image: linear-gradient(top, $theme-zapbox-light 0%, #ed9226 100%);
            border: 1px solid $theme-zapbox-color;*/
            // @include filter-gradient(#fff6cd, #ed9226, vertical);
            //$experimental-support-for-svg: true;
            //@include background-image(linear-gradient(top,  $theme-zapbox-light 0%,#ed9226 100%));
            //border: 1px solid $theme-zapbox-color;
            //border-top: 0;
        }
        &>dl,
        &>nav {
            &,
            ul {
                display: inline-block;
                clear: both;
                float: left;
                width: 100%;
            }
            &.alphabet {
                li {
                    // make letters links full width 
                    width: 3.14%;
                    background: darken(#fff, 5%); //margin-right: $theme-default-padding;
                    margin-right: .7%;
                    border-radius: $theme-zapbox-border-radius;
                    text-align: center;
                    &:hover {
                        background: darken(#fff, 15%);
                    }
                    >a {
                        padding: 0;
                        width: 100%;
                        line-height: 190%;
                    }
                }
            }
        }
    }
}

//
// zapbox live/schedule sub-tabs
//
#zapbox-live,
#zapbox-live-all-channels {
    //margin: 5px 10px 10px 10px;
    dl.tabs {
        //border: 1px solid $theme-zapbox-color;
        //border-bottom: 0;
        //float: none;
        dd {
            // seven week days 
            float:left;
            margin-left: 0;
            width: 14.28%;
            text-align: center;
            text-transform: uppercase;
            a {
                //float: left;
                display: inline-block;
                position: relative; //margin-left: -1px;
                width: 100%;
                padding: .5rem 0;
                font-size: .88rem; //font-weight: bold;    
                
                &, span{
                    color: $theme-zapbox-color;
                }
                text-decoration: none; //text-shadow: 1px 0 2px #444444;
                //border: 1px solid #FFFAE3;
                //border-bottom-color: #F7DCA8;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                font-weight: bold;
                &:hover,
                &:active {
                    background: #efefef;
                }
            }
            &.active {
                a {
                    background: $theme-zapbox-sheet-bg;
                    color: #000; //border: 1px solid #F7DCA8;
                    //border-color: #F7DCA8;
                    //border-bottom-color: $theme-zapbox-sheet-bg;
                }
            }
        }
    }
    .tabs-content {
        float: none;
        background: $theme-zapbox-sheet-bg;
        .content {
            width: 100%;
            height: 100%;
            min-height: 342px; //374px;

            ul,
            li {
                float: left;
                position: relative;
                margin: 0;
                padding: 0;
                list-style-type: none;
                background: $theme-zapbox-sheet-bg;
                a {
                    text-decoration: none;
                }
            }
            &.active {
                //background: white;
                /*
                border: 1px solid #F7DCA8;
                border-top: 0;
                background-color: #cbdbe7;
                *zoom: 1;
                filter: progid:DXImageTransform.Microsoft.gradient(gradientType=0, startColorstr='#FFCBDBE7', endColorstr='#FFF1C472');
                background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2NiZGJlNyIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2YxYzQ3MiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
                background-size: 100%;
                background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #cbdbe7), color-stop(100%, #f1c472));
                background-image: -webkit-linear-gradient(top, #cbdbe7 0%, #f1c472 100%);
                background-image: -moz-linear-gradient(top, #cbdbe7 0%, #f1c472 100%);
                background-image: -o-linear-gradient(top, #cbdbe7 0%, #f1c472 100%);
                background-image: linear-gradient(top, #cbdbe7 0%, #f1c472 100%);
                */
                //@include filter-gradient(#cbdbe7, #f1c472, vertical);
                //$experimental-support-for-svg: true;
                //@include background-image(linear-gradient(top,  #cbdbe7 0%,#f1c472 100%));
                //border-top: 0;
            }
        }
    }
    .content {
        //overflow-x: scroll;
        overflow: hidden; //padding: 8px 0;
        .schedule {
            float: left;
            min-width: 3120px + 0px; // 10px - current show (live))
            height: 363px;
            overflow: hidden; //margin-bottom: 30px;
            ul,
            li {
                float: left;
                display: block;
            }
            ul {
                //display: inline-block;
                //margin-left: 10px;
                //display: inline-block;
                min-width: 3120px + 0px; // 10px - current show (live)) 
                height: 345px;
                margin-left: $theme-default-padding;
                margin-bottom: $theme-default-padding;
                margin: 0;
                li {
                    //&:first-of-type{
                    //margin-left: 10px;
                    //}
                    width: 130px;
                    height: 345px;
                    overflow: hidden;
                    &>div>h4 {
                        display: inline-block;
                        margin: 10px 0 1px 10px;
                        color: $theme-zapbox-color;
                        font-size: .88rem; //font-weight: bold;
                    }
                    /*.cover{
                        img{
                            opacity: .8;
                        }
                    }*/
                    &.live {
                        background: $theme-zapbox-dark;
                        padding-bottom: 11px;
                        h4 {
                            margin-top: 4px;
                            font-size: 1em;
                            font-weight: bold;
                            color: $theme-color-light;
                        }
                        .flip-container {
                            margin-top: -3px;
                        }
                        /*.cover{
                            img{
                                opacity: 1;
                            }
                        }*/
                    }
                }
            }
        }
    }
}

//
// Zapbox all channels LIVE
//
#zapbox-live-all-channels,
#zapbox-channels {
    float: left;
    padding: $theme-default-padding;
    .ch-schedule-date {
        color: $theme-zapbox-dark;
        text-align: center;
        //font-family: arial;
        font-weight: bold;
        font-size: 1.2rem;
        padding: 1rem 2rem; //padding-bottom: 0;
    }
    .ch-schedule {
        float: left;
        width: 100%; //border-bottom 
        background: $zapbox-bg-light; //width: 4000px;
        //padding-right: 1.25rem;
        .content {
            position: relative; // make right side spacing
            margin-right: 1.25rem;
            overflow: hidden; //background: lighten($theme-zapbox-dark, 60%);
        }
        .ch-schedule-img {
            float: left;
            margin: 2.7rem 1.3rem; //margin-top: 2.5rem;
            //margin-right: 0;
            margin-bottom: 0;
        }
    } // channels divider
    .dvd:not(:last-of-type) {
        float: left;
        width: 100%; //margin: $theme-default-padding*.5 0;
        border: $theme-default-padding*.25 solid $theme-zapbox-dark;
    }
}

#zapbox-channels {
    .ch-schedule {
        .ch-schedule-img,
        .txt-content {
            margin: 1.3rem;
        }
        .ch-schedule-img {
            margin-bottom: 0;
        }
        .txt-content {
            margin-left: 0;
            h2,
            p {
                color: $theme-zapbox-dark;
            }
            p {}
        }
    }
    .ch-buttons {
        float: left;
        width: 100%;
        background: $zapbox-bg-light;
        text-align: right;
        button:last-of-type {
            margin-right: 1.3rem;
        }
    }
}

//
// Upcoming shows tab
//
#zapbox>.tabs-content>#zapbox-upcoming.content {
    display:none;
    min-height: 374px;
    .container {
        //overflow-x: scroll;
        overflow: hidden;
        padding: 8px 0;
        .upcoming {
            float: left; //width: 3120px + 0px; // 10px - surrent show (live))
            margin-bottom: 15px;
            ul,
            li {
                float: left;
                margin: 0;
                padding: 0;
                list-style-type: none;
            }
            ul {
                display: inline-block; //margin-left: 10px;
                //display: inline-block;
                li {
                    //&:first-of-type{
                    //margin-left: 10px;
                    //}
                    &>div>h4 {
                        display: inline-block;
                        margin: 15px 0 1px 10px;
                        color: $theme-zapbox-color;
                        font-size: .76rem; //font-weight: bold;
                    }
                    a {
                        text-decoration: none;
                    }
                }
            }
        }
    }
}

//
// cover / cover replacement
//
.cover,
.no-cover,
.default-cover,
.cover-desc {
    position: relative;
    width: 120px;
    margin: 5px;
    height: 300px;
    background: #C7D9EA;
}

// if no cover for host - generate replacement 
.no-cover,
.cover-desc {
    text-align: center; //color: $theme-color-light;
    overflow: hidden;
    /*background-color: #be6ecf;
    *zoom: 1;
    filter: progid:DXImageTransform.Microsoft.gradient(gradientType=0, startColorstr='#FFBE6ECF', endColorstr='#FF490957');
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2JlNmVjZiIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzQ5MDk1NyIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #be6ecf), color-stop(100%, #490957));
    background-image: -webkit-linear-gradient(top, #be6ecf 0%, #490957 100%);
    background-image: -moz-linear-gradient(top, #be6ecf 0%, #490957 100%);
    background-image: -o-linear-gradient(top, #be6ecf 0%, #490957 100%);
    background-image: linear-gradient(top, #be6ecf 0%, #490957 100%);
    */
    //@include theme-box-bg;
    background: darken($theme-zapbox-dark, 20%);
    img {
        margin: 1em 0 0 0;
        border-radius: $theme-zapbox-border-radius; //border: 3px solid darken(#be6ecf, 5%); 
        cursor: pointer; //box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    }
    h4,
    h5,
    p,
    a {
        text-decoration: none;
        color: $theme-color-light;
        display: inline-block;
        line-height: 1rem;
        margin: 0;
        padding: 0;
        font-size: 1rem;
        //color: darken($theme-color-light, 20%) !important;
    }
    h4 {
        //margin: 0;
        //padding: 0 .5em;
        //font-size: 1.4em; 
        //color: $theme-zapbox-title-color;
        //font-style: italic;
        //line-height: .85em;        
        //text-decoration: none;
    } // title
    h5 {
        margin-bottom: $theme-default-padding;
    }
    p, span {
        //margin: .5em;
        //padding: 0;
        //word-break: break-all;        
        font-size: .88rem; //font-weight: bold;
        //color:
        //text-shadow: rgba(0,0,0,.6) -1px -1px 1px;
        //overflow: hidden;
        //text-overflow: ellipsis;
        //display: inline-block;
        //white-space: wrap;
        //overflow: hidden;
        //vertical-align: top;        
        color: darken($theme-color-light, 20%) !important;        
    }
}

//back side of cover (show title, guest and description)
.cover-desc {
    //margin: 0;
    width: 120px;
    height: 300px;
    padding: 5px; //margin-bottom: 10px;
    overflow: hidden;
    text-align: left;
    line-height: 1rem; //h4, h5, p, span{ 
    //display: inline-block;
    //margin: 0;
    //font-weight: bold;
    //font-size: .88em;
    //} 
    span {
        display: inline-block;
    } // replay info 
    span.replay {
        color: darek($theme-color-light, 50%);
        font-size: .78rem;
        margin-bottom: $theme-default-padding; //text-shadow: none;
        //line-height: .88em;
    }
    span.guest {
        color: $theme-color-light;
    } // show title
    h5 {
        //color: #490957;
        //color: #F3D563;
        //font-size: .82em;
        font-weight: bold;
    } // show description
    p {
        //margin-top: .5em;
        //text-overflow: ellipsis;
        //overflow: hidden;
        //font-size: .88em;
        //text-shadow: none;
        //font-style: italic;
        //font-weight: normal;
        overflow: hidden;
    }
}

// flip cover

/* simple */

.flip-container {
    -webkit-perspective: 1000;
    -moz-perspective: 1000;
    -ms-perspective: 1000;
    perspective: 1000;
    -ms-transform: perspective(1000px);
    -moz-transform: perspective(1000px);
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
}


/*
.flip-container:hover .flipper, .flip-container.hover .flipper, #flip-toggle.flip .flipper {
        -webkit-transform: rotateY(180deg);
        -moz-transform: rotateY(180deg);
        -ms-transform: rotateY(180deg);
        transform: rotateY(180deg);
        filter: FlipH;
-ms-filter: "FlipH";
}
*/


/* START: Accommodating for IE */

.flip-container:hover .back,
.flip-container.hover .back {
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    transform: rotateY(0deg);
}

.flip-container:hover .front,
.flip-container.hover .front {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    transform: rotateY(180deg);
}


/* END: Accommodating for IE */

.flip-container,
.front,
.back {
    width: 130px;
    height: 310px;
}

.flipper {
    -webkit-transition: 0.6s;
    -webkit-transform-style: preserve-3d;
    -ms-transition: 0.6s;
    -moz-transition: 0.6s;
    -moz-transform: perspective(1000px);
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transition: 0.6s;
    transform-style: preserve-3d;
    position: relative;
}

.front,
.back {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: 0.6s;
    -webkit-transform-style: preserve-3d;
    -moz-transition: 0.6s;
    -moz-transform-style: preserve-3d;
    -o-transition: 0.6s;
    -o-transform-style: preserve-3d;
    -ms-transition: 0.6s;
    -ms-transform-style: preserve-3d;
    transition: 0.6s;
    transform-style: preserve-3d;
    position: absolute;
    top: 0;
    left: 0;
}

.front {
    -webkit-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    z-index: 2; // replay
    .replay,
    .live,
    .featured {
        position: absolute;
        top: 9px;
        right: 9px;
        padding: 2px 5px;
        border-radius: $theme-zapbox-border-radius;
        color: $theme-color-light; //font-weight: bold;
        background: #000;
        opacity: .4;
        font-size: .55em;
    }
    .live {
        background: red;
    }
    .fatured {
        background: #999;
    }
}

.back {
    -webkit-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
    -o-transform: rotateY(-180deg);
    -ms-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
}

// Zapbox all channels small 'featured' banners 
#zapbox-live-all-channels {
    .cover,
    .no-cover,
    .default-cover,
    .cover-desc {
        height: 150px;
    }
    .flip-container,
    .front,
    .back {
        height: 160px;
    }
    .content .schedule {
        height: 213px;
        ul {
            height: 195px;
            overflow: hidden;
            li {
                height: 195px;
            }
        }
    }
}

// Zapbox - all channels horizontal list
#zapbox-channels {
    .content {
        //overflow-x: scroll;
        overflow: hidden; //padding: 8px 0;
        .all-channels-list {
            float: left; //min-width: 3120px + 0px; // 10px - current show (live))
            min-width: 0px; // 10px - current show (live))
            //height: 363px;
            overflow: hidden; //margin-bottom: 30px;
            ul,
            li {
                float: left;
                display: block;
            }
            ul {
                //display: inline-block;
                //margin-left: 10px;
                //display: inline-block;
                min-width: 0px; // 10px - current show (live))
                height: 321px; //margin-left: $theme-default-padding;
                //margin-bottom: $theme-default-padding;
                margin: 0;
                li {
                    //&:first-of-type{
                    //margin-left: 10px;
                    //}
                    width: 170px;
                    height: 300px;
                    margin-right: $theme-default-padding;
                    overflow: hidden;
                    /*& > div > h4{
                        display: inline-block;
                        margin: 10px 0 1px 10px;
                        color: $theme-zapbox-color;                        
                        font-size: .88em;
                        //font-weight: bold;
                    }*/
                    /*.cover{
                        img{
                            opacity: .8;
                        }
                    }*/
                    .flip-container,
                    .front,
                    .back,
                    .cover-desc {
                        width: 170px;
                        height: 300px;
                    }
                    .cover-desc {
                        margin: 0;
                        p {}
                    }
                }
            }
        }
    }
    .iScrollHorizontalScrollbar {
        bottom: 5px;
    }
}
