.widget{        

    // common functionalities

    
    //
    // scrollable list
    //
    .scroll-h, .scroll-v{
        overflow: hidden;
        
        ul {
            
            padding-left: 0;
            margin: 0;
            white-space: nowrap;
                
            li{
                display: inline-block;                
                
                & > div{
                    // outline for scrollable widget elements (listing)
                    // this is requried - otherwise we can't use grid inside element
                    display: inline-block; 
                }
            }
        }
    }
    .scroll-h{        
        overflow-x: auto;
        
        ul {
            li {
                
                vertical-align: top; // align all items to top 
            }
        }
    }
    .scroll-v{
        
        overflow-y: auto;
        
        ul {
            li {
                // make elements vertical 
                clear: both;
                float: left;
                width: 100%;
            }
        }
    }
    
    

    //
    // menu widgets
    //
    &.w-menu{
        ul{
            padding-left: 0;    // don't keep left distance 
        }
    }

    //
    // widget image
    //
    &.w-image{
        text-align: center; 
    }

    //
    // widget listing 
    //
    &.w-listing, &.w-slider, &.w-carousel{ 

        // grid 
        .list-item{

            overflow: hidden; // otherwise rows with single cell only nad spaces  between cells 

            img{
                width: 100%;
            }

            .list-item-img{

            }
            .list-item-datetime{
                font-size: .88rem;
            }
            .list-item-desc{}

            .list-item-tags{

                span{
                    display: inline-block;
                    padding: .25rem .5rem;
                    margin: 0 5px 5px 0;
                    font-size: .82rem;
                }
            }

            // templates
            .image-left-title, .image-left-title-desc{

                h3{
                    margin-top:0;
                }
                .list-item-img{
                    padding-right: 10px;
                }
            }

        }
    }
    &.w-carousel .list-item{

        //display: inline-block;
        width: 100%;    // to fill full LI width

        // padding to get distances between carousel items 
        @include breakpoint(small) {

            padding: 0 $theme-distance-small/6;
        }

        @include breakpoint(medium) {

            padding: 0 $theme-distance-medium/6;
        }
        
        & > .row {
            
            margin: 0;
            
            .columns{
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    //
    // listing scrollable
    //
    &.w-listing{    

        @include breakpoint(small) {

            margin-bottom: $theme-distance-small;
        }

        @include breakpoint(medium) {

            margin-bottom: $theme-distance-medium;
        }

        // common
        .scroll-h ul li .list-item, .scroll-v ul li .list-item{

            //float: left; // to align vertically to top 
            white-space: normal; // this way text is wrapped instead of overflowed visible single line            
        }

        // horizontal scroll
        .scroll-h ul li:not(:last-child) { 
            .list-item{

                // make distance between horizontally placed elements             
                @include breakpoint(small) {

                    margin-right: $theme-distance-small/2;
                }

                @include breakpoint(medium) {

                    margin-right: $theme-distance-medium/2;
                }            
            }
        }
        .scroll-h ul li .list-item{
            margin-bottom: 0;
        }

        // vertical scroll 
        .scroll-v ul li { 

            // make distance between vertically placed items 
            &:not(:last-of-type) {
                @include breakpoint(small) {

                    margin-bottom: $theme-distance-small*2;
                }

                @include breakpoint(medium) {

                    margin-bottom: $theme-distance-medium*2;
                }
            }

            .list-item{

                float: left;
                width: 100%;

                & > div{
                    margin-right: 5px;  // make distance from right vertical scroll-bar 
                }
            }
        }        

    }    
}
