$membership-radius:     $theme-default-border-radius-small !default;
$column-gutter:         10px !default;
//$cc-box-color:          black !default;

// membership card 
.c-membership{
    
    display: block;
    position: relative;
    //background: $theme-color-base; 
    padding: 0;
    border-radius: $membership-radius;
    margin-bottom: $column-gutter *.5;
    
    //@include transition-property(width);
    @include transition-duration(.25s);
     
    &:hover:not([disabled]){

        //background-color: lighten(#EDEDED, 3%);
        //background-color: lighten($booking-selected-color, 62%);
  
        //background: darken($theme-color-base, 10%);
        @include scale(1.015);
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    }
    
    input[type="radio"]{
        
        display:none;
    }
    
    & > label{
        display: block;
        position: relative;
        //float:left;
        //background: none;         
        border: none;
        text-align: left;
        width: 100%;
        cursor: pointer;
        margin: 0;
        padding: 0;
        border-radius: $membership-radius;
        border: 3px solid transparent;

        >div{
            
            position: relative;
            margin: 0;
            padding: 1rem 1rem 1rem 3.2rem; //1.2rem
            
            // radio mark 
            //background-color: #FFF;
            fill: #fff;
            background-image: url("data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDMyIDMyIj4KPGc+CjwvZz4KCTxwYXRoIGQ9Ik0xNiAwYy04LjgzNyAwLTE2IDcuMTYzLTE2IDE2czcuMTYzIDE2IDE2IDE2IDE2LTcuMTYzIDE2LTE2LTcuMTYzLTE2LTE2LTE2ek0xNiAyOGMtNi42MjcgMC0xMi01LjM3My0xMi0xMnM1LjM3My0xMiAxMi0xMmM2LjYyNyAwIDEyIDUuMzczIDEyIDEyIDAgNi42MjctNS4zNzMgMTItMTIgMTJ6IiBmaWxsPSIjZmZmZmZmIj48L3BhdGg+Cjwvc3ZnPg==");
            background-repeat: no-repeat;
            background-position: 1rem center;
            
            h2{
                margin-bottom: 0;
                color: white;
            }
            
            p{
                //color: $cc-box-color;
                //line-height: 1.2rem;
                margin-bottom: 0;
                color: white;
            }
            
            min-height: 7rem;
        }
        border-radius: $membership-radius;
        
        h1.price{
            /*display: inline-block;
            position: absolute;
            right: $column-gutter;
            top: $column-gutter *.25;
            font-size: 2rem;
            color: $cc-box-color;*/
            font-size:1.6rem;
            color: white;
            text-align: center;
            @include breakpoint(large) {
                text-align: right;
            }
        }
    }
    & > input:checked ~ label{
        
        margin: 0;
        padding: 0;
        border: 3px solid white;
        background: black;
        > div {
            
            //background-color: darken($theme-color-base, 20%);
            //background-color: $booking-selected-color;
            //border-color: $booking-selected-color;
            //background-image: url("data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDMyIDMyIj4KPGc+CjwvZz4KCTxwYXRoIGQ9Ik0xNiAwYy04LjgzNyAwLTE2IDcuMTYzLTE2IDE2czcuMTYzIDE2IDE2IDE2IDE2LTcuMTYzIDE2LTE2LTcuMTYzLTE2LTE2LTE2ek0xNiAyOGMtNi42MjcgMC0xMi01LjM3My0xMi0xMnM1LjM3My0xMiAxMi0xMmM2LjYyNyAwIDEyIDUuMzczIDEyIDEyIDAgNi42MjctNS4zNzMgMTItMTIgMTJ6TTEwIDE2YzAtMy4zMTQgMi42ODYtNiA2LTZzNiAyLjY4NiA2IDZjMCAzLjMxNC0yLjY4NiA2LTYgNi0zLjMxNCAwLTYtMi42ODYtNi02eiIgZmlsbD0iIzdGQkEwMCI+PC9wYXRoPgo8L3N2Zz4=");
            background-image: url("data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDMyIDMyIj4KPGc+CjwvZz4KCTxwYXRoIGQ9Ik0xNiAwYy04LjgzNyAwLTE2IDcuMTYzLTE2IDE2czcuMTYzIDE2IDE2IDE2IDE2LTcuMTYzIDE2LTE2LTcuMTYzLTE2LTE2LTE2ek0xNiAyOGMtNi42MjcgMC0xMi01LjM3My0xMi0xMnM1LjM3My0xMiAxMi0xMmM2LjYyNyAwIDEyIDUuMzczIDEyIDEyIDAgNi42MjctNS4zNzMgMTItMTIgMTJ6TTEwIDE2YzAtMy4zMTQgMi42ODYtNiA2LTZzNiAyLjY4NiA2IDZjMCAzLjMxNC0yLjY4NiA2LTYgNi0zLjMxNCAwLTYtMi42ODYtNi02eiIgZmlsbD0iI0ZGRkZGRiI+PC9wYXRoPgo8L3N2Zz4K");
            border-radius: $membership-radius;
        }
        
        h2.price{
            
            //color: #fff;
        }
        border-radius: $membership-radius;
    }
}
.mtf-forma .c-membership input[type="radio"] + label, .forma .c-membership input[type="radio"] + label{
    margin: 0;
}
#MembershipDisplayForm .row-combined > .row > .inp-combined{
    padding-left: 0;
}
.period-switch{

    float: left; 
    margin-top: 20px;
    width: 100%;
    
    & > div > div{
        padding: 0;
        height: auto;
        text-align:center;
    }
    .mtf-buttonset > div{
        float: left;
        margin-right: 10px;
    }
    

    @include breakpoint(large) {
        //float:right;
        text-align: right;
    }

    label,
    input:checked~label {
        text-align: center;
        padding: .25rem .5rem;
        border-radius: 5px;        
        border: 2px solid transparent;
        margin-bottom: 4px;
        max-width: 150px;
    }
    input:checked~label {
        //background: white;
        //color: white;
        //background: black;
        border-width: 2px;
        border-style: solid;
        border-color: white;    // HERE CONSTANT COLOR!!!
    }
}

// on calculated price change show animation 
.price-pulse{

    @include vendor-prefix(animation-duration, 1s);
    @include vendor-prefix(animation-fill-mode, both);
    @include vendor-prefix(animation-name, price-pulse);
    //@include vendor-prefix(animation-iteration-count, infinite);
}



// price-pulse animation
@-webkit-keyframes price-pulse {
    0% { -webkit-transform: scale(1); }
    50% { -webkit-transform: scale(1.1); }
    100% { -webkit-transform: scale(1); }
}
@-moz-keyframes price-pulse {
    0% { -moz-transform: scale(1); }
    50% { -moz-transform: scale(1.1); }
    100% { -moz-transform: scale(1); }
}
@-o-keyframes price-pulse {
    0% { -o-transform: scale(1); }
    50% { -o-transform: scale(1.1); }
    100% { -o-transform: scale(1); }
}
@keyframes price-pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.1); }
    100% { transform: scale(1); }
}





//
// current member bonus items 
//
//$zapbox-bg-light:       lighten($theme-zapbox-dark, 38%) !default;
$bonus-item-img-radius : $theme-default-border-radius-small !default;
//
// Bonus items styles 
//
.cc-box.bi-out {
    padding-top: $column-gutter*3;
}
 
.bonus-item { 
    
    &:not(:first-of-type) {
        //border-top: 2px solid $theme-color-base;
    }
    padding-top: $column-gutter*2;
    margin-bottom: 8rem; // cover / main image 
    .bi-img {
        position:relative;
        padding: 0;
        &>img {
            border-radius: $bonus-item-img-radius;
            //border: 4px solid $theme-color-base;
            width:100%;
        }
    }
    .bi-category {
        margin: $column-gutter *.25 0;
        padding: .5rem 1rem;
        //background: $theme-color-base;
        background: black;
        //color: darken($theme-color-light, 20%);
        //color: white;
        font-weight: bold;
        //font-size: .72rem;
        text-align: center;
        border-radius: $bonus-item-img-radius;
        font-family: arial, verdana, tahoma;
    } // name/title 
    h2 {
        //color: lighten($cc-box-title-color, 25%);
    }
    &.link {

        .url{
            float: left;
            width: 100%;
            border-width: 2px;
            border-style: solid;
            padding: 5px;
            p{
                padding: 0 !important;
            }
        }
    }
    &.code {}
    &.file {}
    .bi-files a{
        line-height: 2.6rem;
        border-width: 2px;
        border-style: solid;
        margin-bottom: 5px;
        border-radius: $membership-radius;
    }
    .bi-files,
    .bi-urls,
    .bi-code {
        >div {
            &:hover {
                //background: #000;
                text-decoration: none;
            }
            //background: #1D1D1D;
            border-radius: $bonus-item-img-radius * 2;
            margin-bottom: $column-gutter *.5;
            p.c-code,
            a,
            .ico {
                float: left;
                //color: #fff;
                //fill: #fff;
            }
            p.c-code{
                border-width: 2px;
                border-style: solid;
            }
            p.c-code,
            a {
                float: left;
                width: 100%;
                font-size: 1.2rem;
                padding: $column-gutter *.5;
                &:hover {
                    //background: #000;
                    text-decoration: none;
                }                
                word-wrap: break-word; 
            } // coupon / code 
            p.c-code {
                margin: 0; //font-weight: bold;
                border-radius: $bonus-item-img-radius * 2;
                padding: $column-gutter *.25 $column-gutter *.5;
                letter-spacing: +.2rem;
                font-size: 1.6rem;
                text-align: center;
            } // url description
            .url-desc {
                //color: #fff;
                //fill: #fff;
                margin: 0;
                padding: 0;
                //font-size: .88rem;
                //line-height: 1rem;
                //color: #999;
                padding: 0 0 $column-gutter *.5 $column-gutter * 2.9;
            }
        }
    }
}

// value ribbon 
.ribbon-wrapper {
    width: 158px;
    height: 158px;
    overflow: hidden;
    position: absolute; //top: -2px;
    //top: 38px;
    //right: -2px;
    //left: -12px;
    //left: 2px;
    top: 0;
    left: 0;
}

.ribbon {
    //font: bold 15px Sans-Serif;
    color: #fff;
    fill: #fff;
    text-align: center; //text-shadow: rgba(255,255,255,0.5) 0px 1px 0px;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    position: relative;
    padding: 4px 0;
    left: -35px;
    top: 25px;
    width: 158px;
    background-color: #C43427;
    font-size: .86rem;
    text-align: center; //opacity: .8;
    /*background-image: -webkit-gradient(linear, left top, left bottom, from(#BFDC7A), to(#8EBF45)); 
    background-image: -webkit-linear-gradient(top, #BFDC7A, #8EBF45); 
    background-image:    -moz-linear-gradient(top, #BFDC7A, #8EBF45); 
    background-image:     -ms-linear-gradient(top, #BFDC7A, #8EBF45); 
    background-image:      -o-linear-gradient(top, #BFDC7A, #8EBF45); 
    */
    //color: #6a6340;
    -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
}

.ribbon:before,
.ribbon:after {
    content: "";
    border-left: 0px solid transparent;
    border-right: 0px solid transparent;
    position: absolute;
    bottom: -3px;
}

.ribbon:before {
    left: 0;
}

.ribbon:after {
    right: 0;
}
