//
// mtsoft ui wait icons/spinners
//

// -----------------------------------------------------------------------------
//
// variables
//

// common
$wait-default-speed:               2s !default;
$wait-default-direction:           'clockwise' !default;

.ico-wait svg, .icon-wait svg{
    //@include vendor-prefix(animation-name, el-rotate);
    @include element-rotate($wait-default-speed);
}

@if ($wait-default-direction == 'clockwise') { // clockwise direction

    @-o-keyframes el-rotate {
        from {-o-transform:rotate(360deg);}
        to {-o-transform:rotate(0deg);}
    }
    @-moz-keyframes el-rotate {
        from {-moz-transform:rotate(360deg);}
        to {-moz-transform:rotate(0deg);}
    }
    @-ms-keyframes el-rotate {
        from {-ms-transform:rotate(360deg);}
        to {-ms-transform:rotate(0deg);}
    }
    @-webkit-keyframes el-rotate {
        from {-webkit-transform:rotate(360deg);}
        to {-webkit-transform:rotate(0deg);}
    }
    @keyframes el-rotate {
        from { transform:rotate(360deg);}
        to { transform:rotate(0deg);}
    }
} else {

    @-o-keyframes el-rotate {
        from {-o-transform:rotate(0deg);}
        to {-o-transform:rotate(360deg);}
    }
    @-moz-keyframes el-rotate {
        from {-moz-transform:rotate(0deg);}
        to {-moz-transform:rotate(360deg);}
    }
    @-ms-keyframes el-rotate {
        from {-ms-transform:rotate(0deg);}
        to {-ms-transform:rotate(360deg);}
    }
    @-webkit-keyframes el-rotate {
        from {-webkit-transform:rotate(0deg);}
        to {-webkit-transform:rotate(360deg);}
    }
    @keyframes el-rotate {
        from { transform:rotate(0deg);}
        to { transform:rotate(360deg);}
    }

}