//colors
$color_golden_dream_approx: #f1d031;
$color_milan_approx: #ffffa3;
$color_fuscous_gray_approx: #555;
$color_sweet_corn_approx: #ffef93;
$color_celeste_approx: #ccc;
$seashell: #f1f1f1;
$color_tapa_approx: #777;
$color_silver_chalice_approx: #aaa;
$color_woodsmoke_approx: #111;
$black_15: rgba(0, 0, 0, 0.35);
$color_mine_shaft_approx: #333;
$white: white;
$color_gravel_approx: #4a4a4a;
$black: #000000;
$color_log_cabin_approx: #222;

//fonts
$font_0: Tahoma;
$font_1: sans-serif;
$font_2: arial;

//@extend-elements
//original selectors
//.qtip-titlebar .ui-icon, .qtip-icon .ui-icon
%extend_1 {
    display: block;
    text-indent: -1000em;
    direction: ltr;
}


.qtip {
    position: absolute;
    left: -28000px;
    top: -28000px;
    display: none;
    max-width: 280px;
    min-width: 50px;
    font-size: 10.5px;
    line-height: 12px;
    direction: ltr;
    //Instead of the line below you could use @includebox-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    box-shadow: none;
    padding: 0;
    &:not(.ie9haxors) div {
        &.qtip-content {
            filter: none;
        }
        &.qtip-titlebar {
            filter: none;
        }
    }
}
.qtip-content {
    position: relative;
    padding: 5px 9px;
    overflow: hidden;
    text-align: left;
    word-wrap: break-word;
}
.qtip-titlebar {
    position: relative;
    padding: 5px 35px 5px 10px;
    overflow: hidden;
    border-width: 0 0 1px;
    font-weight: bold;
    .qtip-close {
        right: 4px;
        top: 50%;
        margin-top: -9px;
    }
    .ui-icon {
        @extend %extend_1;
    }
    + .qtip-content {
        border-top-width: 0 !important;
    }
}
.qtip-close {
    position: absolute;
    right: -9px;
    top: -9px;
    z-index: 11;
    cursor: pointer;
    outline: medium none;
    border: 1px solid transparent;
}
.qtip-icon {
    //Instead of the line below you could use @includeborder-radius($radius, $vertical-radius)
    border-radius: 3px;
    text-decoration: none;
    .ui-icon {
        @extend %extend_1;
        //Instead of the line below you could use @includeborder-radius($radius, $vertical-radius)
        border-radius: 3px;
        text-decoration: none;
        width: 18px;
        height: 14px;
        line-height: 14px;
        text-align: center;
        text-indent: 0;
        font: normal bold 10px/13px $font_0, $font_1;
        color: inherit;
        background: transparent none no-repeat -100em -100em;
    }
}
.qtip-focus {
}
.qtip-hover {
}
.qtip-default {
    border: 1px solid $color_golden_dream_approx;
    background-color: $color_milan_approx;
    color: $color_fuscous_gray_approx;
    .qtip-titlebar {
        background-color: $color_sweet_corn_approx;
        .qtip-close {
            border-color: $color_silver_chalice_approx;
            color: $color_woodsmoke_approx;
        }
    }
    .qtip-icon {
        border-color: $color_celeste_approx;
        background: $seashell;
        color: $color_tapa_approx;
    }
}

.qtip-youtube {
    //Instead of the line below you could use @includeborder-radius($radius, $vertical-radius)
    border-radius: 2px;
    //Instead of the line below you could use @includebox-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    box-shadow: 0 0 3px $color_mine_shaft_approx;
    color: $white;
    border: 0 solid transparent;
    background: $color_gravel_approx;
    .qtip-titlebar {
        background-color: $color_gravel_approx;
        background-color: rgba(0,0,0,0);
        .ui-state-hover {
            border-color: $color_mine_shaft_approx;
        }
    }
    .qtip-content {
        padding: .75em;
        font: 12px $font_2, $font_1;
        filter: progid:dximagetransform.microsoft.gradient(gradienttype=0,startcolorstr=$color_gravel_approx,endcolorstr=$black);
    }
    .qtip-icon {
        border-color: $color_log_cabin_approx;
    }
}
* html .qtip-titlebar .qtip-close {
    top: 16px;
}

//
//  tip pointers (arrows) 
//


.qtip .qtip-tip{
    margin: 0 auto;
    overflow: hidden;
    z-index: 10;

}

/* Opera bug #357 - Incorrect tip position
https://github.com/Craga89/qTip2/issues/367 */
x:-o-prefocus, .qtip .qtip-tip{
    visibility: hidden;
}

.qtip .qtip-tip,
.qtip .qtip-tip .qtip-vml,
.qtip .qtip-tip canvas{
    position: absolute;

    color: #123456;
    background: transparent;
    border: 0 dashed transparent;
}

.qtip .qtip-tip canvas{ top: 0; left: 0; }

.qtip .qtip-tip .qtip-vml{
    behavior: url(#default#VML);
    display: inline-block;
    visibility: visible;
}




//
// themes
//
/*
<!-- ARIA properties are detailed on the second line, which are applied for accessibility purposes -->
<div id="qtip-{id}" class="qtip qtip-default qtip-pos-rc" style="z-index: 15001;" tracking="false"
        role="alert" aria-live="polite" aria-atomic="false" aria-describedby="qtip-0-content">

    <!-- content.title must be set for this to be generated -->
    <div class="qtip-titlebar">
         <!-- Only generated when content.button is set to true -->
         <!-- If content.title is unset, then this will be appended to the .qtip element, see below -->
        <a class="qtip-close qtip-icon" title="{content.button}" aria-label="{content.button}" role="button"> 
            <span class="ui-icon ui-icon-close">&cross;</span>
        </a>

        <!-- Houses the tooltip title -->
        <div id="qtip-{id}-title" class="qtip-title">{content.title}</div>
    </div>

    <!-- Only generated when content.button is set to true AND content.title is false -->
    <a class="qtip-close qtip-icon" title="{content.button}" aria-label="{content.button}" role="button"> 
        <span class="ui-icon ui-icon-close">&cross;</span>
    </a>

    <!-- Houses the tooltip content -->
    <div id="qtip-{id}-content" class="qtip-content ui-widget-content" aria-atomic="true">
        {content.text}
    </div>
</div>

*/

