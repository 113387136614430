// mtsoft custom mixins 

//
// Apply styles only if screen width larger than defined break-points (equal to foundation break points)) 
//
@mixin larger-than($media) {
    
    @if $media == small {
        @media only screen and (min-width: map-get($breakpoints, small)) { @content }
    }
    @else if $media == medium {
        @media only screen and (min-width: map-get($breakpoints, medium)) { @content }
    }
    @else if $media == large { 
        @media only screen and (min-width: map-get($breakpoints, large)) { @content }
    }
    @else if $media == xlarge {
        @media only screen and (min-width: map-get($breakpoints, xlarge)) { @content }
    }    
    @else if $media == xxlarge {
        @media only screen and (min-width: map-get($breakpoints, xxlarge)) { @content }
    }    
    @else {
        @media only screen and (min-width: $media) { @content }
    }
}

@mixin vendor-prefix($name, $argument...) {

    #{$name}: $argument;
    -webkit-#{$name}: $argument;
    -moz-#{$name}: $argument;
    -ms-#{$name}: $argument;
    -o-#{$name}: $argument;    
}

@mixin element-rotate($duration: .5s, $origin: 50% 50%, $repeat: infinite) {

    display: inline-block;
    @include vendor-prefix(transform-origin, $origin); 
    @include vendor-prefix(animation-name, el-rotate);
    @include vendor-prefix(animation-duration, $duration);
    @include vendor-prefix(animation-iteration-count, $repeat);
    @include vendor-prefix(animation-timing-function, linear);
    //animation: {animation_name} {duration} {timig-function} {delay} {iteration-count} {direction};
}


@function black($opacity){
    @return rgba(0,0,0,$opacity)
}
@function white($opacity){
    @return rgba(255,255,255,$opacity)
}

// Emboss
@mixin box-emboss($opacity1: .2, $opacity2: .1){
    box-shadow: white($opacity1) 1px 1px 0, inset black($opacity2) 1px 1px 0;
}
// Bevel
@mixin box-bevel($opacity1: .2, $opacity2: .1){
    box-shadow: inset black($opacity2) -1px -1px 0, white($opacity1) -1px -1px 0;
}

// Add arrow to element 
@mixin add-arrow($color:#000, $position: 'left', $size: 5px, $offset: 50%, $border: 0px, $border-color: $color) {

    //position: relative; // element must be relative!

    &:after, &:before{

        position: absolute;
        content: " ";
        width: 0;
        height: 0;
        border: solid transparent;
        pointer-events: none;

        @if ($position=='top') { bottom: 100%; }
        @if ($position=='bottom') { top: 100%; }
        @if ($position=='left') { right: 100%; }
        @if ($position=='right') { left:100%; }
    }

    // construct border
    &:after{
        border-color: rgba($color, 0);
        border-width: $size;

        @if ($position=='left') { border-right-color: $color; top: $offset; margin-top: -$size; }
        @if ($position=='top') { border-bottom-color: $color; left: $offset; margin-left: -$size; }
        @if ($position=='right') { border-left-color: $color; top: $offset; margin-top: -$size; }
        @if ($position=='bottom') { border-top-color: $color; left: $offset; margin-left: -$size; }
    }

    &:before{
        border-color: rgba($border-color, 0);
        border-width: $size+$border+1;

        @if ($position=='left') { border-right-color: $border-color; top: $offset; margin-top: - $size - $border - 1;}
        @if ($position=='top') { border-bottom-color: $border-color; left: $offset; margin-left: - $size - $border - 1;}
        @if ($position=='right') { border-left-color: $border-color; top: $offset; margin-top: - $size - $border - 1;}
        @if ($position=='bottom') { border-top-color: $border-color; left: $offset; margin-left: - $size - $border - 1;}
    }
}

@mixin keyframes($name) {

    @-webkit-keyframes #{$name} {
        @content;
    }
    @-moz-keyframes #{$name} {
        @content;
    }
    @-ms-keyframes #{$name} {
        @content;
    }
    @-o-keyframes #{$name} {
        @content;
    }
    @keyframes #{$name} {
        @content;
    }
}