#serchbox_global{
    
    opacity: .8;
    
    form, .input.text, input{
        
        //float: left;
        width: 100%;
        max-width: none !important;
        margin: 0 !important;
    }
    input{
        border-radius: 0;
        text-align: center;
        font-size: 2rem;
    }
    
}