/*!
 * FullCalendar v1.6.4 Stylesheet modified by mtsoft 2014
 */
// use compass
//@import "../../libs/compass/css3"; 


//
// variables (applies to default theme)
//  
$mt-calendar-css-prefix:                    'mtc-' !default;  
$mt-calendar-default-space:                 10px !default;
$theme-default-padding:                     20px;
$mt-calendar-bg:                            #FFF !default;
//$mt-calendar-day-agenda-color:              darken($theme-color-base, 20%) !default;
//$mt-calendar-day-agenda-bg:                 $theme-top-belt-color !default;

// calendar cells 
.fc-day, .fc-widget-content{
    background: $mt-calendar-bg;
}
// calendar header title (day/days range/month) 
.fc-header .fc-header-title{
    
    h2{
        font-size: 1.5rem;
    }
}

// day header 
.fc th.fc-day-header, .fc th.fc-widget-header{
    font-weight: normal;
    padding: .5rem 0;
    //color: $mt-calendar-day-agenda-color;
    //background: $mt-calendar-day-agenda-bg;
    font-size: .88rem;
}
// agenda hour 
.fc-agenda-axis{
    //color: $mt-calendar-day-agenda-color;
    //background: $mt-calendar-day-agenda-bg;
}

/* event text if backgound is light */
/*.fc-event-dark-text{
    color: #474747;
}*/
.fc-event{
    //margin-bottom: 4px;
    
    //color: lighten($cc-box-color, 15%);
    
    .fc-event-time{
        white-space: nowrap;
        font-weight: normal;
        
    }
    
    &.no-edit{
        
        cursor: default; 
    }
}
.fc-event-vert .fc-event-time{
    font-weight: bold;
    font-size: 14px;
}


.#{$mt-calendar-css-prefix}panels{


    .#{$mt-calendar-css-prefix}panel{

        .f-left{
            float: left;
            margin-right: $mt-calendar-default-space;
        }
        .f-right{
            float: right;
            margin-left: $mt-calendar-default-space;
        }
    }

}

.fc-event{
    cursor: pointer;    
}
.fc-widget-content{}
.fc-event{
    font-size: .82rem;
    line-height: .96rem;
}

//
// Show/Epsidoes types
//
.cal-show, .cal-episode{
    //background: darken($theme-color-base, 30%);
    //border-color: darken($theme-color-base, 30%);
    background: #FFA949;
    border-color: darken(#FFA949, 10%);
    color: #000;
    cursor: pointer !important;
}
.cal-replay{
    //background: darken($theme-color-base, 10%);
    //border-color: darken($theme-color-base, 10%);
    cursor: pointer !important;
    background: beige;
    border-color: darken(beige, 10%);
    color: #000;
}

//
// agenda legend
//
.legend-out{
    
    //color: $theme-color-light;
    margin-top: .35rem;
    
    > *{
        
        display:inline-block;
        //line-height: 2.4rem;
        //border-radius: $theme-default-border-radius-small;        
        border-radius: 3px;
        margin-right: $theme-default-padding;
        line-height: 2rem;
        margin-bottom: $theme-default-padding;
        
        @include larger-than(small) {
            
            float: right;            
            margin-left: $theme-default-padding;
            margin-bottom: 0;
        }
    }
    .cal-show, .cal-replay{
        
        //width: 132px;
        height: 2rem;
        padding: 0 1rem;
        font-size: .82rem;
    }
    
}
.fc-event-time, .fc-event-title{
    color: #000;
}

//
// agenda tooltip (qtip))
//
.qtip-content{
    padding: 0;
}