/**
* Gloabl Search results
*/
$column-gutter:         $theme-distance-small;
.search-results {
    
    & > div > h2{
        //color: $theme-box-title-color;
        //fill: $theme-box-title-color;
        text-align: center;
    }
    
    .sr-item{

        //border-radius: $theme-default-border-radius-small;
        //background: lighten($theme-btn-bg, 5%);
        //box-shadow: $theme-box-shadow-default;
        padding: $column-gutter;
        margin-bottom: $column-gutter/2;
        overflow: hidden;

        h2{
            font-size: 1.3rem;
            line-height: normal;
            margin: 0;
        }

        .cc-date{
            //display: inline-block;
            //float: right;
            margin-top: $column-gutter;
            @include larger-than(tiny) {
                margin-top: 0;
            }
        }

        .vd-out{
            
            margin-top: 1rem;
            text-align: center;

            a.button.view-details{            
                //margin: 0 auto; 
                //float: right;
                margin: $column-gutter/2 0 0 0 !important;
                
                @include larger-than(small) {
                    margin: 0 !important;
                }
            }
        }
        p.episode-desc, p.show-desc{
            
            //color: $theme-box-link-color !important;
            margin: 0 !important; 
            padding-top: $column-gutter/2;

            img{
                float:left;
                margin: 0 $column-gutter/2 $column-gutter/2 0;
                //border-radius: $theme-default-border-radius-small;
                width: auto;
            }
        } 
    }
    
    .cc-row{
        min-height: auto !important;
        max-height: auto !important;
    }

    .mtl-btn-more{

        display: inline-block;
        width: 100%;
        text-align: center;        
        & > a{

            @extend .button;
        }
        
        margin-bottom: 5rem;        
    }
    .view-details{
        
        margin-top: $column-gutter/2 !important;
        span{
            display: none;
            @include larger-than(small) {
                display: inline-block;
            }
        }        
    }
    
    .button{
        font-size: .88rem !important;
    }
}