//
// CMS TAGs 
//
//$cms-tag-bg:            #999 !default;
//$cms-tag-color:         #fff !default;
//$cms-tag-radius:        0 !default;
$cms-tag-dist:          5px !default;
$cms-tag-padding:       2px 10px !default;


.tags{
    
    display: inline-block;
    padding: $cms-tag-dist 0 0 0;
    
    > *{
        float: left;
        display: inline-block;
        margin: 0 $cms-tag-dist $cms-tag-dist 0;          
        
        &.empty{
            margin: 0;
            padding:0;
        }
    }
    > label{
        
        //padding: $cms-tag-padding;
        padding: .5rem 1rem;
        padding-left: 0;
    }
    > div{
        
        //color: $cms-tag-color;
        //background: $cms-tag-bg;
        //border-radius: $cms-tag-radius; 
        
        font-size: .88rem;
        //font-style: italic;
        
        a, a:hover, a:visited, a:active{
            
            display: inline-block;
            padding: .5rem 1rem;
            //padding: $cms-tag-padding;
            //color: $cms-tag-color !important;
            text-decoration: none !important;
        }
        
        &:hover{
            //background: darken($cms-tag-bg, 10%) !important;
        }
    }
}